import React,{useState,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import Discl from '../images/disclaimer.webp';
import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";

const Disclaimer = () => {

  const [seoData, setSeoData] = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  useEffect(() => {
    getSeoData();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.disclaimerSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"disclaimer"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
    <div className="App">
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
        <div className="disclaimer">
          <Container fluid className="back_width">
           <div className="discl_bg">
            <Row>
              <Col md={12}>
                  <h1>Disclaimer</h1>
              </Col>
              <Col md={1} sm={2}> 
                  <img src={Discl} />
              </Col>
              <Col> 
                <p> The information contained in this website is for general information purposes only. While Rachna 
                sagar Pvt. Ltd. makes the effort to keep the information up-to-date and correct, we make no representations
                or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability 
                or availability with respect to the information, products, services, or related graphics contained in this 
                website for any purpose. Any reliance you place on such information is strictly at your own risk. Rachna 
                Sagar Pvt.Ltd. takes no responsibility for, and will not be liable for, the website being temporarily 
                unavailable due to technical issues beyond our control. </p>
                <p>  <i style={{fontSize: "20px"}} class="fa fa-hand-o-right" aria-hidden="true"></i>
                &nbsp; Rachna sagar will not be responsible for the price of books, ebooks, interactive ebooks and CDs for any technical misprints in website. If any 
                discripancies are found then our team can ask you to pay the real price of the products before delivering the same to you.
                </p>
              </Col>
            </Row>
            <br/><br/>
            </div>
          </Container>
        </div>
      <Footer/>
    </div>
  );
};
export default Disclaimer;



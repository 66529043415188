import React from "react";

function ImgPopup({closePopup, children}){
  return (
    <div className="searchpopup-bg">
      <div className="imgpopup">
            <div className="row">
              <div className="col-md-12">
                <button onClick={closePopup} className="img-close"> ✕ </button>
                {children}
              </div>
            </div>
      </div>
    </div>
  )
}
export default ImgPopup;
import { Container, Row, Col } from "react-bootstrap";
import React, {useEffect, useState, useContext } from 'react';

import studentleft from '../images/parent.png';
import studentright from '../images/student-left.png';
import { Link } from "react-router-dom";
import Popup from "../../Popup";
import { Contaxt } from "../../../Store";
import {apiBaseURL,apiURL} from '../../../constant';
import Loader from "../../../Loader";

const Bannerpoup = () => {
const [showPopupStudent, setPopupStudent]=useState({status:false, data:[]});
const [showPopupTeacher, setPopupTeacher]=useState({status:false, data:[]});
const [showPopupBooksaller, setPopupBooksaller]=useState({status:false, data:[]});
const {centermenu}=useContext(Contaxt);

// const [studentParents,setStudentParents]=useState([]);
// const [teacherSchools,setTeacherSchools]=useState([]);
// const [bookSellers,setBookSellers]=useState([]);

function getSduentParents() {
    
  fetch(apiBaseURL+apiURL.studentparents,{
    method:'get',
    headers:{
      'rskey' : 'rspl'
    }
  } )
    .then( response => response.json() )
    .then( (result) => {
      console.log( result);
      if( result.status==='1' ){
        // setStudentParents( result.student_parents );  
        setPopupStudent((prev)=>{
          return {...prev, data:result.student_parents}
        })
      }else{
        // setStudentParents([]);
      }
      //setLoading(false);
    } )
    .catch( (error)=>{
      // setStudentParents([]);
      //setLoading(false);
    } );

}

function getTeacherSchools() {
    
  fetch(apiBaseURL+apiURL.teacherschools,{
    method:'get',
    headers:{
      'rskey' : 'rspl'
    }
  } )
    .then( response => response.json() )
    .then( (result) => {
      console.log( result);
      if( result.status==='1' ){
        setPopupTeacher( (prev)=>{

          return {...prev, data:result.teacher_schools}
        } );  

        // alert(result.teacher_schools);

      }else{
        setPopupTeacher((prev)=>{

          return {...prev, data:[]}
        });
      }
      //setLoading(false);
    } )
    .catch( (error)=>{
      setPopupTeacher((prev)=>{

        return {...prev, data:[]}
      });
      //setLoading(false);
    } );

}

function getBookSellers() {
    
  fetch(apiBaseURL+apiURL.booksellers,{
    method:'get',
    headers:{
      'rskey' : 'rspl'
    }
  } )
    .then( response => response.json() )
    .then( (result) => {
      console.log( result);
      if( result.status==='1' ){
        // setBookSellers( result.booksellers );  
        setPopupBooksaller((prev)=>{

          return {...prev, data:result.booksellers}
        }); 

      }else{
        setPopupBooksaller((prev)=>{

          return {...prev, data:[]}
        }); 
      }
      //setLoading(false);
    } )
    .catch( (error)=>{
      setPopupBooksaller((prev)=>{

        return {...prev, data:[]}
      }); 
    } );

}

function setMenu(id){

  //alert("Aniket");
  if(id==1){

    setPopupStudent((prev)=>{

      return {...prev, status:true}
    }); 
    if(showPopupStudent.data.length==0){
      getSduentParents();
    }
  } else if(id==2){
    setPopupTeacher((prev)=>{

      return {...prev, status:true}
    }); 
    if(showPopupTeacher.data.length==0){
      getTeacherSchools();
    }
  } else if(id==3){
    setPopupBooksaller((prev)=>{

      return {...prev, status:true}
    }); 
    if(showPopupBooksaller.data.length==0){
      getBookSellers();
    }
  }

}

  return(
    <div className="App">
        <div className="student-parents">         
            <Row>
              <Col xs={3}>
                <div className="stu-par" data-aos="fade-right"> <img src={studentleft}/> </div>
              </Col>
              <Col>
                <div className="btn_center">
                {centermenu.map( (item)=>{   
                  
                  return(        
                    <span key={item.centermenuId}>    
                  <button onClick={()=>{setMenu(item.centermenuId)}}  type="button" className="student-parents-btn">  {item.Title} </button>                   
                    </span> 
                  );
                } )}
                </div>
              </Col>
              <Col xs={3}>
                <div className="stu-par text-end" data-aos="fade-left"><img src={studentright}/> </div>
              </Col>
            </Row>          
        </div>

        {/* student Popup */}
        {showPopupStudent.status &&
          <Popup title="Students / Parents" closePopup={()=>{setPopupStudent((prev)=>({...prev, status:false}))}}>

            {showPopupStudent.data.length==0?
              <div style={{position:'relative', height:'250px'}}>
                <Loader width='100px' height='100px' backgroundColor="transparent"/>
              </div> :
              <>
              {showPopupStudent.data.map( (item)=>{   
                  let spPath = "";
                  if(item.parameter=="p_book"){
                    spPath = item.url;
                  }else{
                    spPath = item.url+'?bookType='+item.parameter; 
                  }
                  return(        
                    
                    <div className="col-md-4 col-sm-6 col-6" key={item.id}>
                      <div className="index-popup-btn">
                        {item.urlType=='internal'?
                        <Link to={spPath} >{item.title}</Link>:
                          <a href={item.url} target="_blank" > {item.title}</a>
                        }
                        {/* {item.urlType=='internal'?
                        <Link to={item.url+'?bookType='+item.parameter} >{item.title}</Link>:
                          <a href={item.url} target="_blank" > {item.title}</a>
                        } */}
                      </div>
                    </div>
                    
                  );
                } )
                }
              </>
           } 

            {/* <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
              <Link to="/book">Books</Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="#">e-Learning CD</a> 
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="#">e-Books</a> 
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="#">Interactive e-Books</a> 
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="https://gowebrachnasagar.com/" target="_blank">Test Generator</a> 
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="https://gowebrachnasagar.com/" target="_blank">Web Support</a> 
              </div>
            </div> */}


          </Popup>
        }
        {/* student Popup */}

        {/* Teachers Popup */}
          {showPopupTeacher.status &&
          <Popup title="Teachers / Schools" closePopup={()=>{setPopupTeacher((prev)=>({...prev, status:false}))}}>

            {showPopupTeacher.data.length==0?
              <div style={{position:'relative', height:'250px'}}>
                <Loader width='100px' height='100px' backgroundColor="transparent"/>
              </div> :
              <>
              {showPopupTeacher.data.map( (item)=>{  
                
                  let tsPath = "";
                  if(item.parameter=="p_book"){
                    tsPath = item.url;
                  }else{
                    tsPath = item.url+'?bookType='+item.parameter; 
                  }
                  return(        
                    
                    <div className="col-md-4 col-sm-6 col-6" key={item.id}>
                      <div className="index-popup-btn">
                      {/* <Link to="/book">{item.title}</Link> */}
                      {/* {item.urlType=='internal'?
                        <Link to={item.url}>{item.title}</Link>:
                        <a href={item.url} target="_blank" onClick={()=>{console.log("lkdsfs")}}> {item.title}</a>
                      } */}

                      {item.urlType=='internal'?
                        <Link to={tsPath}>{item.title}</Link>:
                        <a href={item.url} target="_blank" onClick={()=>{console.log("lkdsfs")}}> {item.title}</a>
                      }

                      </div>
                    </div>
                    
                  );
                } )
                }
              </>
            } 
            {/* <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <Link to="/book">Books</Link>
              </div>
            </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">e-Books</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">Interactive Books</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="https://gowebrachnasagar.com/" target="_blank">Test Generator</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">Cd</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="https://gowebrachnasagar.com/" target="_blank">Web Support</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">TRM</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">Worksheets</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">Table Calendars</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">Charts</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">Games</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">Manipulative</a> 
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="index-popup-btn">
                  <a href="#">Story Book of Alphabets</a> 
                </div>
              </div> */}
          </Popup>

          }
          {/* Teachers Popup */}

        {/* booksaller Popup */}
        {showPopupBooksaller.status &&
         <Popup title="Bookseller" closePopup={()=>{setPopupBooksaller((prev)=>({...prev, status:false}))}}>
             {showPopupBooksaller.data.length==0?
              <div style={{position:'relative', height:'250px'}}>
                <Loader width='100px' height='100px' backgroundColor="transparent"/>
              </div> :
              <>
              {showPopupBooksaller.data.map( (item)=>{   
                  return(        
                    
                    <div className="col-md-4 col-sm-6 col-6" key={item.id}>
                      <div className="index-popup-btn">
                      {/* <Link to="/book">{item.title}</Link> */}
                      {item.urlType=='internal'?
                        <Link to={item.url}>{item.title}</Link>:
                        <a href={item.url} target="_blank" onClick={()=>{console.log("lkdsfs")}}> {item.title}</a>
                        }
                      </div>
                    </div>
                    
                  );
                } )
                }
              </>
            } 

            {/* <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
              <Link to="/book">Catloug</Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="#">Order form</a> 
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="#">Stockiest Login</a> 
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="#">Terms & Conditions</a> 
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <div className="index-popup-btn">
                <a href="#"> Bookstore Locator </a> 
              </div>
            </div> */}
          </Popup>
          }
        {/* booksaller Popup */}

    </div>
    
  );
};
export default Bannerpoup;



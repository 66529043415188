import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel'

import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import { Link, useParams, useLocation } from 'react-router-dom';
import ImgPopup from './ImgPopup';

import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import Loader from '../../../Loader';
import Helmet from 'react-helmet';

const NewsEventsDetali = () => {
  const [imgPopup, setImgPopup] = useState({
    status: false,
    img: "",
  })
  const params = useParams();
  const [eventDetail, setEventDetail] = useState({
    status: "loading",
    newsEvent: {},
    images: [],
  });
  const [seoData, setSeoData] = useState({
    title: "",
    description: "",
    keywords: "",
    isDone: false,
  })


  const location = useLocation();
  const [recentEvent, setResectEvent] = useState(location.state ? location.state.slice(0, 5) : [])

  useEffect(() => {
    getEventDetail();
    console.log(location)
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, [params.eventId]);

  function getEventDetail() {
    console.log(params)
    let query = '?event_id=' + params.eventId;
    fetch(apiBaseURL + apiURL.newsEventDetail + query, {
      method: 'GET',
      headers: {
        'rskey': apiKey,
      },
    })
      .then(response => response.json())
      .then((result) => {
        console.log(result)
        if (result.status == "1") {
          setEventDetail({
            status: "",
            newsEvent: result.news_events,
            images: result.images,
          })
          setSeoData({
            title: result.news_events.metaTitle,
            description: result.news_events.metaDesc,
            keywords: result.news_events.metaKeyword,
            isDone: true,
          })
        } else {

        }
      })
      .catch((error) => {
        console.log(error)

      })

  }
  function openImgPopup(img) {
    setImgPopup({
      status: true,
      img: img,
    })
  }
  function closeImgPopup() {
    setImgPopup({
      status: false,
      img: "",
    })
  }
  return (
    <>
      {seoData.isDone &&
        <Helmet>
          <title>{seoData.title} </title>
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />
        </Helmet>
      }
      <Header />
      <Navigation />
      <div className='news-back'>
        <div className='back_width container-fluid'>
          <h1 className="invi-authors"> News & Events </h1>
          <div className='news'>
            <Row>
              <Col md={12} data-aos="zoom-in">
                <p className='border-section'> We at Rachna Sagar organise time to time events like Workshops/Seminars for teachers as well as students, Book Fair, Hindi Divas, Seedhi Baat with Author, Get-together with teachers of the EFC (Enchante French Club) members, Annual Sales Meet etc. News and Events are provided to keep yourself updated with book launch, events with authors and other events around Rachna Sagar world! </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className='back_width container-fluid'>
        <Row>
          <Col md={12}>
            <nav aria-label="breadcrumb mt-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/newsEvent">News and Events</Link> </li>
                <li className="breadcrumb-item active" aria-current="page">{eventDetail.newsEvent.eventTitle}</li>
              </ol>
            </nav>
          </Col>
          <Col md={12}>
            <p className='news-detail-con'>
              <div className="NEDate"> <b dangerouslySetInnerHTML={{ __html: eventDetail.newsEvent.eventTitle }} /> </div>
              <div> {eventDetail.newsEvent.location} </div>
              <div>  Date - {eventDetail.newsEvent.eventStartDate} To {eventDetail.newsEvent.eventEndDate} </div>
              <span dangerouslySetInnerHTML={{ __html: eventDetail.newsEvent.eventDesc }} />
              {/* Rachna Sagar Pvt Ltd. organised an interactive workshop on the topic of&nbsp;<strong>NEP 2020 &amp; CBSE SAFAL on 
     26-02-2022 by Mrs. Shradha Anand.</strong>&nbsp;The main objective of this Workshop was to create awareness about 
     the newly launched&nbsp;<strong>National Education Policy-2020&nbsp;</strong>amongst Principals and teachers and 
     to familiarize them with the skills to help them understand its nuances, curriculum changes, shift in the pedagogical
     skills and its implementation for 360 degree. In the session, teachers were told about SDG4, keywords and principles
     of NEP, ECCE and 21st century skills. They will modify their classroom at per the requirements of 21st Century skills
     in formative assessments.&nbsp;Various activities and presentation were provided to reach the concept with 
     clarity.&nbsp;&nbsp;It was a successful and fruitful session. */}
            </p>
          </Col>
        </Row>
        <hr />
        {/* news box */}
        <Row>
          {eventDetail.images.map((img) => {

            return (
              <Col md={3} sm={6} xs={6} style={{ marginBottom: "8px" }}>
                <div className='news-img-section'>
                  <img onClick={() => { openImgPopup(img.image) }} className='img-thumbnail' src={img.image} />
                </div>
              </Col>
            )
          })}

        </Row>
        {/* news box */}
        {/* news box */}
        {/* <Row>
    <Col md={3} sm={6} xs={6}>
      <div className='news-img-section'>
        <img className='img-thumbnail' src={newsimg} />
      </div>
    </Col>
    <Col md={3} sm={6} xs={6}>
      <div className='news-img-section'>
      <img className='img-thumbnail' src={primarylevel} />
      </div>
    </Col>
    <Col md={3} sm={6} xs={6}>
      <div className='news-img-section'>
      <img className='img-thumbnail' src={series} />
      </div>
    </Col>
    <Col md={3} sm={6} xs={6}>
      <div className='news-img-section'>
      <img className='img-thumbnail' src={news4} />
      </div>
    </Col>
  </Row> */}
        {/* news box */}
        <hr />

        {/* news-detail slider */}
        <div className='news-banner'>
          <Row>
            <Col md={12}>
              <h3> Recent Events </h3>
              <Carousel fade>
                {recentEvent.map((item) => {
                  return (
                    <Carousel.Item>
                      <Link to={"/newsEventsDetail/" + item.eventID}> <img className='fix-wh' src={item.image} /> </Link>
                      <Carousel.Caption>
                        <h4 className='blur-bg'> <Link to={"/newsEventsDetail/" + item.eventID}> {item.eventTitle} </Link> </h4>
                      </Carousel.Caption>
                    </Carousel.Item>
                  )
                })}
                {/* <Carousel.Item>
            <Link to="/NewsEventsDetali"> <img className='fix-wh' src={newsslider1}/> </Link>
          <Carousel.Caption>
          <h4 className='blur-bg'> <Link to="/NewsEventsDetali"> NEP 2020 & CBSE SAFAL </Link> </h4>
          </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
          <Link to="/NewsEventsDetali"> <img className='fix-wh' src={newsslider2}/> </Link>
          <Carousel.Caption>
          <h4 className='blur-bg'>  <Link to="/NewsEventsDetali">Classroom Assessment at Primary Level </Link>  </h4>
          </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
          <Link to="/NewsEventsDetali"> <img className='fix-wh' src={newsslider3}/> </Link>
          <Carousel.Caption>
          <h4 className='blur-bg'> <Link to="/NewsEventsDetali">   Workshop on “Essence Series”—Based on Multiple Intelligence Skills </Link> </h4>
          </Carousel.Caption>
          </Carousel.Item> */}
              </Carousel>
            </Col>
          </Row>
        </div>
        {/* news-detail slider  */}
      </div>
      <Footer />

      {/* image popup show */}
      {imgPopup.status &&
        <ImgPopup closePopup={() => { closeImgPopup() }}>
          <img className='img-thumbnail' src={imgPopup.img} style={{ maxHeight: "95vh", maxWidth: '95vw' }} />
        </ImgPopup>
      }

      {/* image popup show */}
    </>
  );
};
export default NewsEventsDetali;
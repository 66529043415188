import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";



const TermCondition = () => {
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  useEffect(() => { 
    getSeoData();
    AOS.init({
    offset: 300,
    duration:1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.termsConditionSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"termsconditions"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
  <>
  {seoData.isDone &&
    <Helmet>
        <title>{seoData.title} </title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.ogTags?.og_title} />
        <meta property="og:description" content={seoData.ogTags?.og_description} />
        <meta property="og:url" content={seoData.ogTags?.og_url} />
        <meta property="og:image" content={seoData.ogTags?.og_image_url} />
        <meta name="twitter:site" content="@rachnasagargrp"/> 
        <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
        <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
        <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
    </Helmet>
  }
  <Header/>
  <Navigation/>
  <div className='branch-bg'>
    <Container>
        <h1 className="invi-authors"> Terms & Conditions </h1>
        <p className="term-hedi" data-aos="fade-right"> The following terms and conditions will be deemed to have been accepted by the User on usage of the website <Link to="/"> www.rachnasagar.in </Link> You are requested to read them carefully before you use the services of this site. </p>
    </Container>
  </div>
    <Container>
      <div className='condition-box'>
        <Row>
          <Col md={12}>
            <p> 1. The term User shall refer to the user who is browsing the Site. The term <b>Rachna Sagar</b> shall refer to <b>Rachna Sagar Pvt. Ltd.</b> and/or its affiliates/subsidiary companies. The term Site refers to <Link to="/"> www.rachnasagar.in </Link> </p>
            <p> 2. By using the Site, you agree to follow and be bound by the following terms and conditions concerning your use of the Site. <strong>Rachna Sagar</strong> may revise the Terms of Use at any time without notice to you. </p>
            <p> 3. All content present on this site is the exclusive property of <strong>Rachna Sagar</strong> . The software, text, images, graphics, video and audio used on this site belong to Rachna Sagar . No material from this site 
            may be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form whatsoever without prior written permission from <strong>Rachna Sagar</strong> . All rights not expressly granted 
            herein are reserved. Unauthorized use of the materials appearing on this site may violate copyright, trademark and other applicable laws, and could result in criminal and/or civil penalties. </p>
           <p> 4. <strong>Rachna Sagar</strong> does not make any warranties, express or implied, including without limitation, those of merchantability and fitness for a particular purpose, with respect to any information, data, statements or products made available on the Site. However the content on the site has been developed by experienced authors/editors to the best of their knowledge. </p>
           <p> 5. <strong>Rachna Sagar</strong> does not accept any responsibility towards the contents and/or information practices of third party Sites which have links through Rachna Sagar Site. The said links to internal or external Web site locations are only for the purpose of facilitating your visit or clarify your query. </p>
           <p> 6. The Site, and all content, materials, information, software, products and services provided on the Site, are provided on an "as is" and "as available" basis. However, the content has been developed by experienced educationists and authors to the best of their knowledge. </p>
           <p> 7. <strong>Rachna Sagar</strong> shall have no responsibility for any damage to a User's computer system or loss of data that result from the download of any content, materials, and information from the Site. </p>
           <p> 8. The User agrees to indemnify, defend and hold <strong>Rachna Sagar</strong> harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site. </p>
           <p> 9. The information contained in the Site has been obtained from sources believed to be reliable.  </p>
           <p> 10. The User's right to privacy is of paramount importance to Rachna Sagar . Any information provided by the User will not be shared with any third party. <strong>Rachna Sagar</strong> reserves the right to use the information to provide the User a more personalized online experience. </p>
           <p> 11. The Site provides links to Web sites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Site. You agree that <strong>Rachna Sagar</strong> is not responsible for the availability of, and content provided on, third party Web sites. The User is requested to peruse the policies posted by other Web sites regarding privacy and other topics before use. </p>
           <p> 12. <strong>Rachna Sagar</strong> reserves the right in its sole discretion to review, improve, modify or discontinue, temporarily or permanently, the Site or any content or information on the Site with or without notice to User. User agrees that <strong>Rachna Sagar</strong> shall not be liable to User or any third party for any modification or discontinuance of the Site. </p>
           <p> 13. <strong>Ebook &amp; Interactive-Ebook:</strong> Read this ebook &amp; interactive ebook only on our <span className='blue'> Android App ( Rachna Sagar DigiText ) </span> Available on Google play store. Android Supporting (Mobile &amp; Tab) version 4.1 and up. Your purchase is valid for one year from date of purchasing. You can read the e-book in only one registered Android device by registering your e-mail. </p>
           <p> 14.<b> Free Bag:  </b> 
            <p> (i) . Valid only if total price of books is upto INR 1999. It does not include shipping charges. </p> 
            <p> (ii) . Valid Up To 15% discount options. It is not valid on more than 15% discount options.</p> 
           </p>
           <p> 15. <strong>Ebook &amp; Interactive-Ebook:</strong> We are offering of 25% discount on ebook and 20% discount on Interactive ebook. </p>
          </Col>
        </Row>
      </div>
    </Container>
  <Footer/>
  
  </>
  );
};
export default TermCondition;
import React , {useState, useContext, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Coman/Header";
import Footer from "../Coman/Footer";
import {Contaxt} from '../../../Store'
import Loader from "../../../Loader";
const GoogleRedirect = () => {

  const {checkUserLogin, userData} = useContext(Contaxt);
  const params = useParams();
  let navigate = useNavigate();
  useEffect(()=>{
    if(userData.loginStatus){
        navigate(`/`);
      
    } else {
        console.log(params.token);
        checkUserLogin(params.token)
        .then(res=>{
            console.log(res, "sdfjskdfjl")
            navigate(`/`);
        });
        console.log("sdfkjsdlf dsf")
    }
    
  })

 
   
  

  
  

  return(
    <div className="App">
      <Header/>
      {/* <Navigation/> */}
      <div className="branch-bg">
        
        <Container>
            <div className="form-wrap" style={{position:"relative"}}>
                <Loader width="80px" height="80px"/>
            </div>
        </Container>
      </div>
      <Footer/>
    </div>
  );
};
export default GoogleRedirect;



import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse'

import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../Loader';
import Error from '../Error/Error';
import { Contaxt } from '../../../Store';
import { apiURL, apiBaseURL, apiKey } from '../../../constant';


const BuyNow = () => {
  
  const {userData, resetUser} = useContext(Contaxt);
  const [buyOrderData, setBuyOrderData] = useState({
    "totalPrice":"",
    "discount":"",
    "percentDiscount":"",
    "totalAfterDis":"",
    "subTotal":"",
    "shippingCharge":"",
    "couponDiscount":"",
    "grandTotal":"",
    "couponStatus":0,
    "productId":"",
    "productQuantity":"",
    "productTitle":"",
    "productImg":"",
    "couponAvailable":"0",
    "isLoading":true,
  });
  const [shippingAddress, setShippingAddress] = useState({})
  const[promoCode, setPromoCode]=useState({
    status:false,
    isLoading:false,
    code:""
  });
  const [addNoteData, setAddNoteData] = useState({
    status:false,
    text:'',
    isLoading:false,
    msg:""
  });
  const [error, setError] = useState({status:false, msg:"", fun:undefined, canName:undefined});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => { 

    if(location.state==null){
      navigate('/');
      return;
    } 
    getBuyNowOrderDetail();
    
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  
  function canclePopup(){
    setError({status:false, msg:"", fun:undefined, canName:undefined})
  }
  async function getBuyNowOrderDetail(){

   
    try {
      canclePopup();
      setBuyOrderData((prev)=>{
        return {...prev, isLoading:true};
      })
      const sendData = {
        "bookId": location.state.bookId?.toString(),
        "product_type": location.state.type,
        "action": "buynow"
      }
      const response = await fetch(apiBaseURL+apiURL.buyNow, {
        method:"POST",
        headers:{
          'rskey' : apiKey,
          'utoken': userData.token
        },
        body:JSON.stringify(sendData)
      });
      const result = await response.json();
      console.log(result)
      if(result.status=="1"){
          setBuyOrderData({
            "totalPrice":result.Product_MRP,
            "discount":result.Discount,
            "percentDiscount":result.Product_Discount_Percent,
            "totalAfterDis":result.Product_Price,
            "subTotal":result.Sub_Total,
            "shippingCharge":result.Shipping_Charge,
            "couponDiscount":result.Coupon_Discount,
            "grandTotal":result.Grand_Total,
            "couponStatus":result.Coupon_Status,
            "productId":result.Product_Id,
            "productQuantity":result.Product_Quantity,
            "productTitle":result.Product_Title,
            "productImg":result.Image,
            "productClass":result.Product_Class,
            "productSubject":result.Product_Subject,
            "productISBN":result.Product_ISBN,
            "productTypeTitle":result.Product_Type_Title,
            "couponAvailable":result.Coupon_Code_Publish,
            "isLoading":false,
          })
          setShippingAddress(result.Shipping_Address)
      } else {
        if(result.Message=="Cart Empty"){
            setBuyOrderData((prev)=>{
                return {
                    ...prev,
                    isLoading:false,
                }
            })

        } else if(result.message=="Invalid TOKEN"){
            resetUser()
        } else {
          setBuyOrderData((prev)=>{
            return {...prev, isLoading:false};
          })
          setError((prev)=>{
            return {...prev, status:true, msg:result.message, fun:getBuyNowOrderDetail}
          })
        }
      }
    } catch (error) {

        console.log(error)
        setError((prev)=>{
          return {...prev, status:true, msg:"Network Error!", fun:getBuyNowOrderDetail}
        })
        //show error
    }  
  }
  function togglePromoCode(value){
    setPromoCode((prev)=>{
      return {...prev, status:value}
    })
  }
  function toggleAddNote(value){
    setAddNoteData((prev)=>{
      return {...prev, status:value}
    })
  }
  function setNoteValue(value){
    setAddNoteData((prev)=>{
      return {...prev, text:value}
    })
  }
  function setPromoValue(value){
    setPromoCode((prev)=>{
      return {...prev, code:value}
    })
  }
  async function applyPromoCode(){

    if(promoCode.code==""){
      setError((prev)=>{
        return {...prev, status:true, msg:"Please Enter the Promo Code First.", fun:undefined, canName:"OK"}
      })
      return
    }
    
    try {
      canclePopup();
      setPromoCode((prev)=>{
        return {...prev, isLoading:true}
      })
      const sendData = {
        "bookId": location.state.bookId?.toString(),
        "product_type": location.state.type,
        "action": "applycoupon",
        "coupon":promoCode.code
      }
      const response = await fetch(apiBaseURL+apiURL.buyNowCoupon, {
        method:"POST",
        headers:{
          'rskey' : apiKey,
          'utoken': userData.token
        },
        body:JSON.stringify(sendData)
      });
      const result = await response.json();
      console.log(result)
      setPromoCode((prev)=>{
        return {...prev, isLoading:false}
      })
      if(result.status=="1"){
        setBuyOrderData((prev)=>{

          return {
            ...prev,
            "totalPrice":result.Total_MRP,
            "discount":result.Discount,
            "totalAfterDis":result.Total,
            "subTotal":result.Sub_Total, 
            "shippingCharge":result.Shipping_Charge,
            "couponDiscount":result.Coupon_Discount,
            "couponStatus":result.Coupon_Status,
            "grandTotal":result.Grand_Total,
          }
        })
      } else {
        if(result.message=="Invalid TOKEN"){
            resetUser()
        } else {
        
          
          setError((prev)=>{
            return {...prev, status:true, msg:result.message, fun:undefined, canName:"OK"}
          })
        }
      }
    } catch (error) {

        console.log(error)
        setPromoCode((prev)=>{
          return {...prev, isLoading:false}
        })
        setError((prev)=>{
          return {...prev, status:true, msg:"Network Error!", fun:undefined, canName:"OK"}
        })
        //show error
    }   
     
    
  }
  async function sendAddNote(){

    if(addNoteData.text==""){
      setError((prev)=>{
        return {...prev, status:true, msg:"Please write somthing on add note.", fun:undefined, canName:"OK"}
      })
      return
    }
    try {
      canclePopup();
      setAddNoteData((prev)=>{
        return {...prev, isLoading:true, msg:""}
      })
      const response = await fetch(apiBaseURL+apiURL.buyNowAddNote, {
        method:"POST",
        headers:{
          'rskey' : apiKey,
          'utoken': userData.token
        },
        body:JSON.stringify({"AddNote":addNoteData.text})
      });
      const result = await response.json();
      // console.log(result)
      
      if(result.status=="1"){
        setAddNoteData((prev)=>{
          return {...prev, isLoading:false, msg:"successfully submitted!", text:""}
        })
      } else {
        setAddNoteData((prev)=>{
          return {...prev, isLoading:false,  msg:""}
        })
        if(result.message=="Invalid TOKEN"){
          resetUser()
        } else {
          setError((prev)=>{
            return {...prev, status:true, msg:result.message, fun:undefined, canName:"OK"}
          })

        }
      }
    } catch (error) {

        console.log(error)
        setAddNoteData((prev)=>{
          return {...prev, isLoading:false, msg:""}
        })
        setError((prev)=>{
          return {...prev, status:true, msg:"Network Error!", fun:undefined, canName:"OK"}
        })
        //show error
    }   
     
  }
  return(
    <>
      <Header/>
      <Navigation/>
      <div className='order_boxbg'>
      <Container>
        {buyOrderData.isLoading?
        <div style={{position:'relative', height:'200px'}}>
          <Loader width="80px" height="80px" backgroundColor="transparent"/>
        </div>:
        <Row>
          <Col md={6} sm={6}>
          <div className="order-box" data-aos="zoom-in">
              <Row className='back-ship'>
                <Col>
                <div className='order-hedi'> Shipment Address </div>
                </Col>
                <Col>
                <div className='pen-edit'> <Link to="/Profile/address"> <i className="fa fa-pencil" aria-hidden="true"></i> </Link> </div>
                </Col>
              </Row>
              <div className='address-order'>
                <div> <span className='order-bold'>Name :</span> {shippingAddress.Name}  </div>
                <div> <span className='order-bold'>Address (Area & Street) :</span> {shippingAddress.Address}  </div>
                <div> <span className='order-bold'>City/Town :</span> {shippingAddress.City}  </div>
                <div> <span className='order-bold'>State :</span> {shippingAddress.State}  </div>
                <div> <span className='order-bold'>Country :</span> {shippingAddress.Country}  </div>
                <div> <span className='order-bold'>Pin Code :</span> {shippingAddress.Zip} </div>
                <div> <span className='order-bold'>Mobile</span> {shippingAddress.Mobile} </div>
              </div>
          </div>
          </Col>
          <Col md={6} sm={6}>
          <div className="order-box">
              <Row className='back-ship'>
                <Col>
                <div className='order-hedi'> Buy Now Order Details</div>
                </Col>
              </Row>
              <div className='address-order'>
                {/* <Row>
                    <Col md={10} sm={8} xs={8}>
                    <div> Total MRP </div>
                    </Col>
                    <Col md={2} sm={4} xs={4}>
                    <div className='right-order-text'> {buyOrderData.totalPrice}  </div>
                    </Col>
                    <Col md={10} sm={8} xs={8}>
                    <div> Discount ( - )  </div>
                    </Col>
                    <Col md={2} sm={4} xs={4}>
                    <div className='right-order-text'> {buyOrderData.discount}</div>
                    </Col>
                    <Col md={10} sm={8} xs={8}>
                    <div> Total </div>
                    </Col>
                    <Col md={2} sm={4} xs={4}>
                    <div className='right-order-text'> {buyOrderData.totalAfterDis}</div>
                    </Col>
                    {buyOrderData.couponStatus=="1"&&
                      <>
                        <Col md={10} sm={8} xs={8}>
                        <div> Coupon Discount ( - )  </div>
                        </Col>
                        <Col md={2} sm={4} xs={4}>
                        <div className='right-order-text'> {buyOrderData.couponDiscount}</div>
                        </Col>
                      </>
                    }
                    <Col md={10} sm={8} xs={8}>
                    <div> Sub Total </div>
                    </Col>
                    <Col md={2} sm={4} xs={4}>
                    <div className='right-order-text'> {buyOrderData.subTotal}</div>
                    </Col>
                    <Col md={10} sm={8} xs={8}>
                    <div> Shipping Charges ( + ) </div>
                    </Col>
                    <Col md={2} sm={4} xs={4}>
                    <div className='right-order-text'> {buyOrderData.shippingCharge} </div>
                    </Col>
                    <div className="order-border"></div>
                    <Col md={10} sm={6} xs={8}>
                    <div className='order-bold'> Grand Total </div>
                    </Col>
                    <Col md={2} sm={6} xs={4}>
                    <div className='right-order-text order-bold'> {buyOrderData.grandTotal} </div>
                    </Col> 
                    <Col md={4} sm={12} xs={12}>
                      <button class="shoping_btn" type="button"> Continue </button> 
                    </Col>
                </Row> */}
                 <Row>
                      <Col md={6} sm={6} xs={6} className="right-login mt-3"> 
                        <div className='order-cal'> <b>Total MRP</b> </div>
                        <div className='order-cal'> Discount (-) </div>
                      </Col>
                      <Col md={6} sm={6} xs={6} className="mt-3"> 
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {buyOrderData.totalPrice}</b></span> </div>
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {buyOrderData.discount}</b></span> </div>
                      </Col>
                      <Col md={12}> <div className='discount-border'></div> </Col>
                      <Col md={6} sm={6} xs={6} className="right-login"> 
                        <div className='order-cal'> <b>Total</b> </div>
                        {buyOrderData.couponStatus=="1"&&
                          <div className='order-cal'> Coupon Discount (-) </div>
                        }
                      </Col>
                      <Col md={6} sm={6} xs={6}> 
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp;  {buyOrderData.totalAfterDis}</b></span> </div>
                      {buyOrderData.couponStatus=="1"&&
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {buyOrderData.couponDiscount}</b></span> </div>
                      }
                      </Col>
                      <Col md={12}> <div className='discount-border'></div> </Col>
                      <Col md={6} sm={6} xs={6} className="right-login"> 
                        <div className='order-cal'> <b>Sub Total</b> </div>
                      </Col>
                      <Col md={6} sm={6} xs={6}> 
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {buyOrderData.subTotal}</b></span> </div>
                      </Col>
                      <Col md={12}> <div className='discount-border'></div> </Col>
                      <Col md={6} sm={6} xs={6} className="right-login"> 
                        <div className='order-cal'> Shipping Charges (+) &nbsp;  
                      
                        </div>
                      </Col>
                      <Col md={6} sm={6} xs={6}> 
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp;{buyOrderData.shippingCharge}</b></span> </div>
                      </Col>
                      
                      <Col md={12}> <div className='discount-border'></div> </Col>
                      <Col md={6} sm={6} xs={6} className="right-login"> 
                        <div className='garnd-total'> <b>Grand Total</b> </div>
                      </Col>
                      <Col md={6} sm={6} xs={6}> 
                      <div><span className='cartdot'>:</span>  <span className='garnd-total'> <b>₹ &nbsp; {buyOrderData.grandTotal} </b></span> </div>
                      </Col>
                      <Col md={12}> <div className='discount-border'></div> </Col>
                      <Col md={4} sm={12} xs={12}>
                      <Link to="/Profile/payment?type=buyNow">
                        <button class="shoping_btn" type="button"> Continue </button> 
                      </Link>
                    </Col>
                    </Row>
              </div>
          </div>
          </Col>
        </Row>
      }
        </Container>
        </div>
        {!buyOrderData.isLoading&&
        <Container>
            <Row className='m-3'>
              {buyOrderData.couponAvailable=="1"&&
                <Col md={6}>
                  <div className='mycart-collapse' onClick={()=>{togglePromoCode(!promoCode.status)}} aria-controls="example-collapse-text"> <i className="fa fa-pencil" aria-hidden="true"></i>
                  &nbsp; Enter a promo code</div>
                  <Collapse in={promoCode.status}>
                  <div className="input-group">
                    <input type="text" className="form-control mycart-input" placeholder="Enter a promo code" disabled={buyOrderData.couponStatus=="1"} value={promoCode.code} onChange={(e)=>{if(buyOrderData.couponStatus!="1")setPromoValue(e.target.value)}}/>
                    {promoCode.isLoading?
                      <div style={{position:'relative', height:'44px', width:"90px", borderRadius:"0.25rem", border:"1px solid #ced4da"}}>
                          <Loader width="40px" height="40px" backgroundColor="transparent"/>
                      </div>:
                      <div className="input-group-append"  style={buyOrderData.couponStatus!="1"?{cursor:"pointer"}:{cursor:"no-drop"}} onClick={()=>{if(buyOrderData.couponStatus!="1")applyPromoCode()}}>
                        <span className="input-group-text">Apply</span>
                      </div>
                    }
                  </div>
                  </Collapse>
                </Col>
              }
              <Col md={6} className="mt-3">
                <div className='mycart-collapse left-collapse' onClick={() => toggleAddNote(!addNoteData.status)} aria-controls="example-collapse-text"> <i className="fa fa-sticky-note-o" aria-hidden="true"></i>
                &nbsp; Add a note</div>
                <Collapse in={addNoteData.status}>
                <div className="note-flax">
                <textarea className="form-control" placeholder="Instructions? Special requests? Add them here." rows="2" value={addNoteData.text} onChange={(e)=>{setNoteValue(e.target.value)}}></textarea> 
                <div style={{color:'green'}}>{addNoteData.msg}</div>
                {addNoteData.isLoading?
                  <div style={{position:'relative', margin:'20px 0 6px 0', height:'44px', width:"90px", borderRadius:"0.25rem", border:"1px solid #ced4da"}}>
                      <Loader width="40px" height="40px" backgroundColor="transparent"/>
                  </div>:
                  <button class="forget_btn" type="button" onClick={sendAddNote}> Submit </button>
                }
                </div>
                </Collapse>
              </Col>
            </Row>
          <div className='order-list'>
            <h2> Buy Now Order </h2>
            <Row>
                <Col md={2} sm={2}>
                <img className="img-thumbnail" src={buyOrderData.productImg} />
                </Col>
              {console.log(buyOrderData)}
                <Col md={10} sm={10} className="p-2">
                  <div>{buyOrderData.productTitle}</div>
                  <div>ISBN : {buyOrderData.productISBN}</div>
                  <div>Class : {buyOrderData.productClass}</div>
                  <div>Subject : {buyOrderData.productSubject}</div>
                  <div>Product Type : {buyOrderData.productTypeTitle}</div>
                  <div>Quantity : {buyOrderData.productQuantity}</div>
                  <div> 
                  <span class="booPrice">₹ {buyOrderData.totalAfterDis}</span> 
                  <span className='order-rupess'>₹</span> <span class="linethrow">{buyOrderData.totalPrice}</span> 
                </div>
                <div>You save ₹ {buyOrderData.discount} ({buyOrderData.percentDiscount}% off)</div>
                </Col>

            </Row>
          </div>
        </Container>
        }
      {error.status && 
        <Error msg={error.msg} fun={error.fun} cancle={canclePopup} canName={error.canName}/>
      }
      <Footer/>
    </>
  );
};
export default BuyNow;



import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { Link } from 'react-router-dom';
import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from '../../../Loader';
import Error from '../Error/Error';


const Career = () => {
  const [seoData, setSeoData] = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  const [jobData, setJobData] = useState({
    list:[],
    status:"loading",
    msg:"",
    fun:undefined,
  });
  const [serachFields, setSearchFields] = useState(({
    title:"",
    locations:"",
    experience:"",
    minCtc:"",
    maxCtc:""
  }))
  useEffect(() => { 
    getSeoData();
    getJobData();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.careerSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"career"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }

  async function getJobData(queryParms=""){

    try {
      let response = await  fetch(apiBaseURL+apiURL.careersList+queryParms,{
          method:'GET',
          headers:{
              'rskey':apiKey,
          },
        });
      let result = await response.json();
      console.log(result);
      if(result.status=="1"){
        setJobData((prev)=>{
          return {...prev, list:result.careers, status:""}
        })
      } else {
        console.log(result.message)
        setJobData((prev)=>{
          return {...prev, list:[], status:"error", msg:result.message, fun:()=>{getJobData(queryParms)}}
        })
       
      }

    } catch (error) {
      console.log(error)
      setJobData((prev)=>{
        return {...prev, list:[], status:"error", msg:"network error!", fun:()=>{getJobData(queryParms)}}
      })
    }
  }
  function changeSearchField(value, type){
    setSearchFields((prev)=>{
      return {...prev, [type]:value}
    })
  }

  function searchJobs(){
    let queryParms = `?job_title=${serachFields.title}&location=${serachFields.locations}&experience=${serachFields.experience}&min_ctc=${serachFields.minCtc}&max_ctc=${serachFields.maxCtc}`;
    setJobData((prev)=>{
      return {...prev, status:"loading", msg:"", fun:undefined}
    })
    getJobData(queryParms)
  }
  return(
    <>
      {seoData.isDone &&
        <Helmet>
          <title>{seoData.title} </title>
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />
          <meta property="og:title" content={seoData.ogTags?.og_title} />
          <meta property="og:description" content={seoData.ogTags?.og_description} />
          <meta property="og:url" content={seoData.ogTags?.og_url} />
          <meta property="og:image" content={seoData.ogTags?.og_image_url} />
          <meta name="twitter:site" content="@rachnasagargrp"/> 
          <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
          <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
          <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
      <div className='order_boxbg'>
        <Container>
          <div className='career-border'>
            <Row>
              <Col md={3}>
                <input type="email" className="form-control career-control" placeholder="Job Title" value={serachFields.title} onChange={(e)=>{changeSearchField(e.target.value, 'title')}}/>
              </Col>
              <Col md={2}>
                <input type="email" className="form-control career-control" placeholder="Locations" value={serachFields.locations} onChange={(e)=>{changeSearchField(e.target.value, 'locations')}} />
              </Col>
              <Col md={2}>
                  <select className="form-control career-control form-select" value={serachFields.experience} onChange={(e)=>{changeSearchField(e.target.value, 'experience')}}>
                    <option hidden > Experience </option>
                    <option value='0'> 0 Year </option>
                    <option value='1'> 1 Year </option>
                    <option value='2'> 2 Year </option>
                    <option value='3'> 3 Year </option>
                    <option value='4'> 4 Year </option>
                    <option value='5'> 5 Year </option>
                  </select>
              </Col>
              <Col md={2}>
                  <select className="form-control career-control form-select" value={serachFields.minCtc} onChange={(e)=>{changeSearchField(e.target.value, 'minCtc')}}>
                    <option hidden value=""> Min CTC </option>
                    <option value="1"> 1 Lac </option>
                    <option value="2"> 2 Lac </option>
                    <option value="3"> 3 Lac </option>
                    <option value="4"> 4 Lac </option>
                  </select>
              </Col>
              <Col md={2}>
                  <select className="form-control career-control form-select" value={serachFields.maxCtc} onChange={(e)=>{changeSearchField(e.target.value, 'maxCtc')}}>
                    <option hidden value=""> Max CTC</option>
                    <option value="2"> 2 Lac </option>
                    <option value="3"> 3 Lac </option>
                    <option value="4"> 4 Lac </option>
                    <option value="5"> 5 Lac </option>
                  </select>
              </Col>
              <Col md={1}>
                <button type="button" className="career-btn" onClick={searchJobs}>Search</button>
              </Col>
            </Row>
          </div>         
        </Container>
      </div>
      {jobData.status=="loading"?
      <div style={{position:'relative', height:'80px'}}>
        <Loader width="50px" height="50px" backgroundColor="transparent"/>
      </div>: 
      <Container>
        <h1 className='career-job-hedi'> Current Opening </h1>
          <Row className='car-space'>
            {jobData.list.map((item=>{
              return(<Col md={4} sm={6} style={{marginBottom:"8px"}}>
                <div className="order-box">
                  <Row className='back-ship'>
                      <div className="job-profile-hedi"> {item.title} </div>
                  </Row>
                    <Row className='mt-2'>
                      <Col md={2} xs={2}>
                          <div className="favi-career"> <i className="fa fa-briefcase" aria-hidden="true"></i> </div>
                      </Col>
                       <Col md={10} xs={10}>
                        <div className="car-main-hedi"> <b>Experience</b> : {item.min_exp} - {item.max_exp} Years  </div>
                        </Col>
                      <Col md={2} xs={2}>
                          <div className="favi-career"> <i className="fa fa-inr carfavi" aria-hidden="true"></i> </div>
                      </Col>
                     <Col md={10} xs={10}>
                        <div className="car-main-hedi"> <b>Salary</b> : {item.min_ctc} - {item.max_ctc} Lac P.A.  </div>
                        </Col>
                      <Col md={2} xs={2}>
                          <div className="favi-career"> 
                            <i className="fa fa-location-arrow carfavi" aria-hidden="true"></i> 
                          </div>
                      </Col>
                     <Col md={10} xs={10}>
                        <div className="car-main-hedi"> <b>Location</b> : {item.location} </div>
                        </Col>
                        <Col md={12}>
                        <center> 
                         <Link to={"/careerdetail/"+item.id} > <button className="btn-career" type="button"> View </button> </Link>
                       </center>
                        </Col>
                    </Row>
                </div>
              </Col>)
            }))}
            {/* box---1--- */}
           {/* box---1--- */}
           {/* <Col md={4} sm={6}>
              <div className="order-box">
                <Row className='back-ship'>
                    <div className="job-profile-hedi"> Senior PHP Developer </div>
                </Row>
                  <Row className='mt-2'>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-briefcase" aria-hidden="true"></i> </div>
                    </Col>
                     <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Experience</b> : 2 - 4 Years  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-inr carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Salary</b> : 4 - 5 Lac P.A.  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-location-arrow carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Location</b> : Delhi </div>
                      </Col>
                      <Col md={12}>
                      <center> 
                       <Link to="/careerdetail"> <button className="btn-career" type="button"> View </button> </Link>
                     </center>
                      </Col>
                  </Row>
              </div>
            </Col> */}
            {/* box---1--- */}
            {/* <Col md={4} sm={6}>
              <div className="order-box">
                <Row className='back-ship'>
                    <div className="job-profile-hedi"> DTP & Graphics </div>
                </Row>
                  <Row className='mt-2'>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-briefcase" aria-hidden="true"></i> </div>
                    </Col>
                     <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Experience</b> : 2 - 4 Years  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-inr carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Salary</b> : 4 - 5 Lac P.A.  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-location-arrow carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Location</b> : Delhi </div>
                      </Col>
                      <Col md={12}>
                     <center> 
                       <Link to="/careerdetail"> <button className="btn-career" type="button"> View </button> </Link>
                     </center>
                      </Col>
                  </Row>
              </div>
            </Col>
            <Col md={4} sm={6}>
              <div className="order-box">
                <Row className='back-ship'>
                    <div className="job-profile-hedi"> Computer Operator </div>
                </Row>
                  <Row className='mt-2'>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-briefcase" aria-hidden="true"></i> </div>
                    </Col>
                     <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Experience</b> : 2 - 4 Years  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-inr carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Salary</b> : 4 - 5 Lac P.A.  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-location-arrow carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Location</b> : Delhi </div>
                      </Col>
                      <Col md={12}>
                     <center> 
                       <Link to="/careerdetail"> <button className="btn-career" type="button"> View </button> </Link>
                     </center>
                      </Col>
                  </Row>
              </div>
            </Col> */}
           {/* box---1--- */}
          </Row>
          {/* <Row className='car-space'>
            <Col md={4} sm={6}>
              <div className="order-box">
                <Row className='back-ship'>
                    <div className="job-profile-hedi"> Computer Operator </div>
                </Row>
                  <Row className='mt-2'>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-briefcase" aria-hidden="true"></i> </div>
                    </Col>
                     <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Experience</b> : 2 - 4 Years  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-inr carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Salary</b> : 4 - 5 Lac P.A.  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-location-arrow carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Location</b> : Delhi </div>
                      </Col>
                      <Col md={12}>
                     <center> 
                       <Link to="/careerdetail"> <button className="btn-career" type="button"> View </button> </Link>
                     </center>
                      </Col>
                  </Row>
              </div>
            </Col>
           <Col md={4} sm={6}>
              <div className="order-box">
                <Row className='back-ship'>
                    <div className="job-profile-hedi"> Sales Executive </div>
                </Row>
                  <Row className='mt-2'>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-briefcase" aria-hidden="true"></i> </div>
                    </Col>
                     <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Experience</b> : 2 - 4 Years  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-inr carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Salary</b> : 4 - 5 Lac P.A.  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-location-arrow carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Location</b> : Delhi </div>
                      </Col>
                      <Col md={12}>
                     <center> 
                       <Link to="/careerdetail"> <button className="btn-career" type="button"> View </button> </Link>
                     </center>
                      </Col>
                  </Row>
              </div>
            </Col>
            <Col md={4} sm={6}>
              <div className="order-box">
                <Row className='back-ship'>
                    <div className="job-profile-hedi"> Digital Marketing Executive </div>
                </Row>
                  <Row className='mt-2'>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-briefcase" aria-hidden="true"></i> </div>
                    </Col>
                     <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Experience</b> : 2 - 4 Years  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-inr carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Salary</b> : 4 - 5 Lac P.A.  </div>
                      </Col>
                    <Col md={2} xs={2}>
                        <div className="favi-career"> <i className="fa fa-location-arrow carfavi" aria-hidden="true"></i> </div>
                    </Col>
                   <Col md={10} xs={10}>
                      <div className="car-main-hedi"> <b>Location</b> : Delhi </div>
                      </Col>
                      <Col md={12}>
                     <center> 
                       <Link to="/careerdetail"> <button className="btn-career" type="button"> View </button> </Link>
                     </center>
                      </Col>
                  </Row>
              </div>
            </Col>
          </Row> */}
      </Container>
      }
      <br/>
      <Footer/>
    </>
  );
};
export default Career;



import React, { useState, useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { apiBaseURL, apiURL, } from './../../../constant';
import { Contaxt } from '../../../Store';
import Loader from '../../../Loader';
import { Link } from 'react-router-dom';


function NewRelease() {
    //=========================================
    const { newreleaseItems } = useContext(Contaxt)
    //=======================================

    useEffect(() => {
        if (newreleaseItems.items.length != 0) {
            cusCarouselInit();
        }
    })

    const cusCarouselInit = () => {
        window.$("#newrelease").owlCarousel({
            items: 4,
            itemsDesktop: [1000, 3],
            itemsDesktopSmall: [979, 2],
            itemsTablet: [768, 2],
            pagination: false,
            navigation: true,
            navigationText: ["", ""],
            autoPlay: true
        });
    }

    return (
        <Container>
            <div className="new-release">
                <h1 className="rel-hedi">New Releases</h1>
                <div className="bottom-border-0" data-aos="zoom-in-right" data-aos-duration="800"></div>
                {newreleaseItems.isLoading ?
                    <div style={{ position: 'relative', height: '250px' }}>
                        <Loader width='100px' height='100px' backgroundColor='transparent' />
                    </div> :
                    <div id="newrelease" className="owl_carousel">

                        {newreleaseItems.items.map((item) => {
                            return (
                                <div className="box-item" key={item.id}>
                                    <div className="item">
                                        <div className='overText'>
                                            <Link to={"/newRelease?new-release=" + item.slug}>
                                                <img src={item.image} /> 
                                            </Link>
                                            {/* <Link to={"/newRelease?new-release=" + item.slug}>
                                                <div className="overlay"> 
                                                    <div className="over-text" data-dismiss="modal">Order Now</div>
                                                </div>
                                            </Link> */}
                                <p style={{margin:'12px 0 3px 0'}}> <Link to={"/newRelease?new-release=" + item.slug}> {item.Title} </Link> </p> 
                                <div class="rel">   <Link to={"/newRelease?new-release=" + item.slug}>  <button type="button"> Order Now </button> </Link> </div>
                                        </div>
                                    {/* <a href={item.prUrl}><img src={item.image} alt={item.alt_title} /></a>
                                    <a href={item.prUrl}><p> {item.Title}</p> </a> */}
                                    </div>
                                    
                                </div>
                            );
                        })}
                    </div>
                }
                
            </div>
        </Container>
    );
}
export default NewRelease;

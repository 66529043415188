import React , {useState,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { apiBaseURL, apiURL, apiKey} from "../../../constant";
import Helmet from "react-helmet";

const Feedback = () => {

  const [name,setName]=useState("");
  const [mobile,setMobile]=useState("");
  const [email,setEmail]=useState("");
  const [subject,setSubject]=useState("");
  const [message,setMessage]=useState("");


  const [ername, setErname] = useState("");
  const [ermobile, setErmobile] = useState("");
  const [eremail, setEremail] = useState("");
  const [ersubject, setErsubject] = useState("");
  const [ermessage, setErmessage] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");

  const [lodar, setLodar] = useState("");
  const [seoData, setSeoData] = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  });
  useEffect(() => {
    getSeoData();
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.feedbackSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"feedback"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  function saveFeedback()
  {   
      
      //alert(name);
      let data = {name,mobile,email,subject,message}
      console.warn(data)  

      setErname("");
      setErmobile("");
      setEremail("");
      setErsubject("");
      setErmessage("");
      setLodar("Loading...");

      if( name!=='' && mobile !=='' && email !=='' && subject!=='' && message!=='' ){
          
          fetch(apiBaseURL+apiURL.feedback,{
              method:'POST',
              headers:{
                  'rskey':'rspl'
              },
              body:JSON.stringify( data )
          } )
          .then( (result) => {
              setErrorMsg("");
              
              //=====================================
              result.json().then((data) => {
                    if (data.status == "1") {
                        

                        setName("");
                        setMobile("");
                        setEmail("");
                        setSubject("");
                        setMessage("");
                        setErrorMsg("");

                        setMsg(data.message);
                        console.log(data.message.data);
                        setLodar("");


                        setTimeout(function() {
                            setMsg("");
                        }, 3000);


                     
                    } else {
                        
                      setErrorMsg(data.message);
                        //console.log(data.errors);
                        setLodar("");
                    }
              });
              //====================================

          } ).catch( (err)=>{
              setMsg("");
              setErrorMsg("Failed to fetch");
              alert(err);
              console.log(err);
          } )
      
         
      }else{
          //setErrorMsg("All filed required");     

          if(name==""){
            setErname("Name required");
          }
          if(mobile==""){
            setErmobile("Mobile required");
          }
          if(email==""){
            setEremail("Email Id required");
          }
          if(subject==""){
            setErsubject("Subject required");          
          }
          if(message==""){
            setErmessage("Message required");
          }
              
          setLodar("");
          //alert("dfghfdjk");
      }
      


  }
  return(
    <div className="App">
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
        <div className="feedback">
          <Container>
            <Row>
              <Col>
                <div className="feedback_form">
                <h1 className="login-main">Feedback</h1>
                <form className="sign_up">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi">Name *</label>                      
                      <input type="name" name="name" id="name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control feed-mar" placeholder="Enter name" />
                      { ername!=='' &&    <span className="errMgs">{ername}</span> }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi"> Mobile *</label>
                      <input type="name" name="mobile" id="mobile" value={mobile} onChange={(e)=>setMobile(e.target.value)} className="form-control feed-mar" placeholder="Enter mobile" />
                      { ermobile!=='' &&    <span className="errMgs">{ermobile}</span> }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi">Email *</label>
                      <input type="name" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control feed-mar" placeholder="Enter email" />
                      { eremail!=='' &&    <span className="errMgs">{eremail}</span> }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi">Subject *</label>
                      <input type="name" name="subject" id="subject" value={subject} onChange={(e)=>setSubject(e.target.value)} className="form-control feed-mar" placeholder="Enter Subject" />
                        { ersubject!=='' &&    <span className="errMgs">{ersubject}</span> }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi"> Message * </label>                      
                      <textarea name="message" id="message" value={message} onChange={(e)=>setMessage(e.target.value)} className="form-control feed-mar" rows="2"></textarea>
                       { ermessage!=='' &&    <span className="errMgs">{ermessage}</span> }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <center>
                     <button onClick={saveFeedback} className="forget_btn" type="button"> Submit </button>
                     { errorMsg!=='' &&  
                          <div className="alert alert-danger"> {errorMsg} </div>  }
                      { msg!=='' &&
                          <div className="alert alert-success">{msg}  </div>  }

                      { lodar!=='' &&
                      <div disabled><span className="spinner-grow spinner-grow-sm"></span> Loading... </div> }
                    </center>
                  </div>
                </div>              
              </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      <Footer/>
    </div>
  );
};
export default Feedback;



import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import { Link } from 'react-router-dom';


import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from '../../../Loader';
import Helmet from 'react-helmet';
const NewsEvents = () => {

  const [eventData, setEventData] = useState({
    status:"loading",
    list:[],
    
  })
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    isDone:false,
  })
  useEffect(() => { 
    getNewsLists();
    AOS.init({
    offset: 300,
    duration:1000,
    });
  }, []);

  async function getNewsLists(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.newsEventList,{
          method:'GET',
          headers:{
              'rskey':apiKey,
          },
        });
      let result = await response.json();
      console.log(result);
      if(result.status=="1"){
        setEventData((prev)=>{
          return {...prev, status:"", list:result.news_events}
        });
        // setSeoData({
        //   title:result.Meta_Title,
        //   description:result.Meta_Description,
        //   keywords:result.Meta_Keyword,
        //   isDone:true,
        // })
      } else {
        console.log(result.message)
        setEventData((prev)=>{
          return {...prev, status:"", }
        })
       
      }

    } catch (error) {
      console.log(error)
      setEventData((prev)=>{
        return {...prev, status:""}
      })
    }
  }
  return(
  <>
  <Header/>
  <Navigation/>
  <div className='news-back'>
    <div className='back_width container-fluid'>
      <h1 className="invi-authors"> News & Events </h1>
        <div className='news'>
          <Row>
            <Col md={12} data-aos="zoom-in">
              <p className='border-section'> We at Rachna Sagar organise time to time events like Workshops/Seminars for teachers as well as students, Book Fair, Hindi Divas, Seedhi Baat with Author, Get-together with teachers of the EFC (Enchante French Club) members, Annual Sales Meet etc. News and Events are provided to keep yourself updated with book launch, events with authors and other events around Rachna Sagar world! </p>
            </Col>
          </Row>
        </div>
    </div>
  </div>
  <div className='back_width container-fluid'>
    {/* news box */}
    <div className='news'>
      {eventData.status=="loading"&&
        <div style={{position:'relative', height:'80px'}}>
        <Loader width="50px" height="50px" backgroundColor="transparent"/>
      </div>
      }
      <Row>
      {eventData.list.map((item)=>{

        return(
        <Col md={3} sm={6} style={{marginBottom:"24px"}}>
          <div className='news-box'>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                <Link to={"/newsEventsDetail/"+item.eventID}  state={eventData.list}> <img src={item.image} /> </Link>
                </div>
                <div className="flip-card-back">
                <Link to={"/newsEventsDetail/"+item.eventID}  state={eventData.list}>   <img src={item.image} /> </Link>
                </div>
              </div>
            </div>
            <div className='news-contant-section'>
              <h6>{item.eventTitle} </h6>
              <p> 
                <div> {item.location}</div> 
                <div className="NEDate">{item.eventStartDate} To {item.eventEndDate}</div>
               </p>
              <Link to={"/newsEventsDetail/"+item.eventID}  state={eventData.list}> <div className="news-btn"> Read More.... </div> </Link>
            </div>
          </div>
        </Col>
        )
      })}
        
     
      </Row>
    </div>
   {/* ------------ news box ------------------ */}
   <div className='news'>
    
    </div>
    {/* ----------- news box ------------------ */}
  </div>
  <Footer/>
  </>
  );
};
export default NewsEvents;
import React , {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { Link , useNavigate} from "react-router-dom";


import { apiBaseURL, apiURL, apiKey} from "../../../constant";

const Forget_passward = () => {

  const navigate = useNavigate();

  const [email,setEmail]=useState("");
  const [otp,setOtp]=useState("");



  const [resData, setResData] = useState({
                                          status:"",
                                          msg:"",
                                          type:'request'
                                        })


  //alert("aniket "+apiBaseURL+" --- "+apiKey);

  function forgetPassword(e){
    e.preventDefault();
    if(resData.type=="request"){
      sendOtp_For_forgetPassword();
    } else {
      verifyOtp_For_forgetPassword();
    }
  }

  function verifyOtp_For_forgetPassword(){   
    
    console.log(resData.type);
        

      if( email !=='' ){
        setResData((prev)=>{
          return {...prev, status:"loading"}
        })
        fetch(apiBaseURL+apiURL.verifyOtpForForgotPassword,{
            method:'POST',
            headers:{
              'rskey':apiKey
            },
            body:JSON.stringify( {"email": email,"otp": otp} )
        })
        .then( (result) => {
          result.json().then((data) => {
          if (data.status == "1") {

            console.log(data.data.rt);
            //setEmail("");
            setResData((prev)=>{
              return {...prev, status:"success", msg:data.message, type:"sendOpt"}
            })

            navigate('/resetpassword?t='+data.data.rt);

          } else {
              
            setResData((prev)=>{
              return {...prev, status:"error", msg:data.message}
            })
          }
        });
        }).catch( (err)=>{
            console.log(err);
            setResData((prev)=>{
              return {...prev, status:"error", msg:"Network error!"}
            })
        })
      
      }else{
        setResData((prev)=>{
          return {...prev, status:"error", msg:"Email is required"}
        })  

      }

      


  }

  function sendOtp_For_forgetPassword(){   
    
    console.log(resData.type);
        

      if( email !=='' ){
        setResData((prev)=>{
          return {...prev, status:"loading"}
        })
        fetch(apiBaseURL+apiURL.sendOtpForForgotPassword,{
            method:'POST',
            headers:{
              'rskey':apiKey
            },
            body:JSON.stringify( {"email": email} )
        })
        .then( (result) => {
          result.json().then((data) => {
          if (data.status == "1") {
            //setEmail("");
            setResData((prev)=>{
              return {...prev, status:"success", msg:data.message, type:"sendOpt"}
            })
          } else {
              
            setResData((prev)=>{
              return {...prev, status:"error", msg:data.message}
            })
          }
        });
        }).catch( (err)=>{
            console.log(err);
            setResData((prev)=>{
              return {...prev, status:"error", msg:"Network error!"}
            })
        })
      
      }else{
        setResData((prev)=>{
          return {...prev, status:"error", msg:"Email is required"}
        })  

      }

      


  }


  return(
    <div className="App">
      <Header/>
      <Navigation/>
      <div className="branch-bg">
        <Container>
          <Row>
            <Col>
            <div className="form-wrap">
            <h1 className="login-main"> Forget Password </h1>
              <form id="warp-login">
                <div className="form-group">
                  <label className="login-hedi">Email Id * </label>
                  <input type="email" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} 
                                      className="form-control login-mar" placeholder="Enter Email Id " />
                 
                </div>

                {resData.type=="sendOpt" && 
                    <div className="form-group">
                      <label className="login-hedi">Enter OTP</label>
                      <input type="text" name="otp" id="otp" value={otp} onChange={(e)=>setOtp(e.target.value)} 
                                      className="form-control login-mar" placeholder="Enter Otp " />
                      
                    </div>
                  }

                <div className="col-md-12">
                  <center>                

                  { (resData.status=='error' || resData.status=="success") &&
                    <div className={`alert ${resData.status=='error'?'alert-danger':'alert-success'}`}>{resData.msg}</div> 
                  }
                  { resData.status=='loading' ?
                    <div disabled> <span className="spinner-grow spinner-grow-sm"></span> Loading... </div>:
                    <button className="forget_btn" type="button" onClick={forgetPassword}> Submit </button> 
                  }
                  </center>
                </div>


                  <Row>
                    <Col md={12}> 
                      <div className="google_btn">
                        <Link to="/requestOtp">Request OTP</Link>
                        <Link to="/signup">Create Account</Link>
                      </div>  
                    </Col>
                  </Row>

              </form>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
};
export default Forget_passward;



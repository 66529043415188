import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Collapse from 'react-bootstrap/Collapse';

import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

// import SliderFrom from './SliderFrom';
import Loader from '../../../Loader';
import { apiURL, apiBaseURL, apiKey } from '../../../constant';

const PrimaryFrom = () => {

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(true);
  //=============================
  useEffect(() => { 
      AOS.init({
        offset:300,
        duration:1000,
      });
  }, []);
  //==============================
  //================= // Together Order Form  \\ ===================salesPerson
  const [orderNo,setOrderNo]=useState("");
  const [salesPerson,setSalesPerson]=useState("");
  const [date,setDate] = useState(""); 

  const [partyName,setPartyName] = useState("");
  const [bank,setBank] = useState("");
  const [tpt,setTpt] = useState("");
  const [grNo,setGrNo] = useState("");

  const [grDate,setGrDate] = useState("");
  const [bkgStn,setBkgStn] = useState("");
  const [fwdTptExp,setFwdTptExp] = useState("");
  const [discount,setDiscount] = useState("");


  const [erOrderNo, setErOrderNo] = useState("");
  const [erSalesPerson, setErSalesPerson] = useState("");
  const [erDate,setErDate] = useState("");
  
  
  const [erPartyName,setErPartyName] = useState("");
  const [erBank,setErBank] = useState("");
  const [erTpt,setErTpt] = useState("");
  const [erGrNo,setErGrNo] = useState("");

  const [erGrDate,setErGrDate] = useState("");
  const [erBkgStn,setErBkgStn] = useState("");
  const [erFwdTptExp,setErFwdTptExp] = useState("");
  const [erDiscount,setErDiscount] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");

  const [lodar, setLodar] = useState("");

  function savePrimaryOrderFrm()
  {   
      
      //alert(orderno);
     
      let data = {orderNo,salesPerson,date,partyName,bank,tpt,grNo,grDate,bkgStn,fwdTptExp,discount,bookData:primaryOrderInfo}
      console.warn(data)  

      setErOrderNo("");
      setErSalesPerson("");
      setErDate("");
      setErPartyName("");

      setErBank("");
      setErTpt("");
      setErGrNo("");
      setErGrDate("");

      setErBkgStn("");
      setErFwdTptExp("");
      setErDiscount("");

  

      setLodar("Loading...");
      if( orderNo=='' || salesPerson =='' || date =='' || partyName=='' || bank=='' || tpt=='' || grNo=='' || grDate=='' || bkgStn=='' || fwdTptExp=='' || discount==''){

        if(orderNo==""){  setErOrderNo("Order No required");     }
        if(salesPerson==""){  setErSalesPerson("Sales Person required");   }
        if(date==""){  setErDate("Date required");  }
        if(partyName==""){  setErPartyName("Party Name required");  }

        if(bank==""){   setErBank("Bank required");     }
        if(tpt==""){    setErTpt("tpt required");     }
        if(grNo==""){   setErGrNo("RG No. required");     }
        if(grDate==""){   setErGrDate("GR Date required");     }

        if(bkgStn==""){   setErBkgStn("Bank required");     }
        if(fwdTptExp==""){   setErFwdTptExp("Bank required");     }
        if(discount==""){   setErDiscount("Discount required");     }
            
        setLodar("");

      }else{

        fetch(apiBaseURL+apiURL.primaryorder,{
            method:'POST',
            headers:{
                'rskey':'rspl'
            },
            body:JSON.stringify( data )
        } )
        .then( (result) => {
            setErrorMsg("");
            
            //==============================================
            result.json().then((data) => {
                  if (data.status == "1") {
                      

                      //setOrderNo("");                      
                      //setErrorMsg("");

                      setMsg(data.message);
                      console.log(data.message.data);
                      setLodar("");


                      setTimeout(function() {
                          setMsg("");
                      }, 30009999);


                  
                  } else {
                      
                    setErrorMsg(data.message);
                      //console.log(data.errors);
                      setLodar("");
                  }
            });
            //===========================================

        } ).catch( (err)=>{
            setMsg("");
            setErrorMsg("Failed to fetch");
            alert(err);
            console.log(err);
        } )

      }

  }

  //===================================================
  

  //====================================================
  //  [{quantity:2, code:2323}, {quantity:5, code:52323}]
  // [5, 8]
  const [primaryOrderInfo, setPrimaryOrderInfo] = useState([]);
  
  const manageCheckBox = (e) => {
    // Destructuring
   

    const { value, checked } = e.target;
    // const { codeNo } = primaryOrderInfo;
    
    let quantity = document.getElementById(value).value;

    console.log(`${value} is ${checked} -  ${quantity}`);

    //value = value+"ani";
    
     
    // Case 1 : The user checks the box
    if (checked) {
      setPrimaryOrderInfo((prev)=>{
        let newList = [...prev];
        /*newList.push({name:value, quantity:quantity})*/
        newList.push({bookCodeNo:value, quantity:quantity})
        return newList;
      })
    }
  
    // Case 2  : The user unchecks the box
    else {
      setPrimaryOrderInfo((prev)=>{
        let newList = [...prev];
        //let index = newList.findIndex((item)=>item.name==value);
        let index = newList.findIndex((item)=>item.bookCodeNo==value);
       
        if(index!=-1){
          newList.splice(index, 1);
        }
        return newList;
      })
    }

    
  };

  console.log(primaryOrderInfo);
//========================================
  //================= \\ Together Order Form  // ===================


  return(
  <>
    <Header/>
    <Navigation/>
    <form >
      <div className='branch-bg'>
        <h1 className="invi-authors"> Primary With Order Form </h1>
        <Container>
        
          <div className='order_primary'>
            <Row className='m-1'> 
              <Col md={4}>
                <div className='primary-from'>
                    <label> ORDER NO </label> 
                  {/* <input type="order" className="form-control" placeholder="ORDER NO" /> */}
                  <input type="name" name="orderNo" id="orderNo" value={orderNo} onChange={(e)=>setOrderNo(e.target.value)} className="form-control" placeholder="ORDER NO" />
                  { erOrderNo!=='' &&    <span className="errMgs">{erOrderNo}</span> }
                </div>
              </Col>
              <Col md={4}>
                <div className='primary-from'>
                <label> SALES PERSON </label> 
                  <input type="name" name="salesPerson" id="salesPerson" value={salesPerson} onChange={(e)=>setSalesPerson(e.target.value)} className="form-control" placeholder="SALES PERSON" />
                  { erSalesPerson!=='' &&    <span className="errMgs">{erSalesPerson}</span> }
                </div>
              </Col>
              <Col md={4}>
                <div className='primary-from'>
                <label> DATE </label> 
                  <input type="date" name="date" id="date" value={date} onChange={(e)=>setDate(e.target.value)} className="form-control" placeholder="Date" />
                  { erDate!=='' &&    <span className="errMgs">{erDate}</span> }
                </div>
              </Col>
              <Col md={6}>
                <div className='primary-from'>
                  <label> PARTY NAME </label> 
                  <input type="name" name="partyName" id="partyName" value={partyName} onChange={(e)=>setPartyName(e.target.value)} className="form-control" placeholder="Party Name" />
                  { erPartyName!=='' &&    <span className="errMgs">{erPartyName}</span> }
                </div>
              </Col>
              <Col md={6}>
                <div className='primary-from'>
                <label> BANK </label> 
                <input type="name" name="bank" id="bank" value={bank} onChange={(e)=>setBank(e.target.value)} className="form-control" placeholder="Bank" />
                { erBank!=='' &&    <span className="errMgs">{erBank}</span> }
                </div>
              </Col>
              <Col md={4}>
                <div className='primary-from'>
                <label> TPT </label> 
                <input type="name" name="tpt" id="tpt" value={tpt} onChange={(e)=>setTpt(e.target.value)} className="form-control" placeholder="TPT" />
                { erTpt!=='' &&    <span className="errMgs">{erTpt}</span> }
                </div>
              </Col>
              <Col md={4}> 
                <div className='primary-from'>
                  <label> GR NO </label> 
                  <input type="name" name="grNo" id="grNo" value={grNo} onChange={(e)=>setGrNo(e.target.value)} className="form-control" placeholder="GR NO" />
                  { erGrNo!=='' &&    <span className="errMgs">{erGrNo}</span> }
                </div>
              </Col>
              <Col md={4}> 
                <div className='primary-from'>
                  <label> GR DATE </label> 
                  <input type="date" name="grDate" id="grDate" value={grDate} onChange={(e)=>setGrDate(e.target.value)} className="form-control" placeholder="GR Date" />
                  { erGrDate!=='' &&    <span className="errMgs">{erGrDate}</span> }
                </div>
              </Col>
              <Col md={4}> 
                <div className='primary-from'>
                  <label> BKG STN </label> 
                  <input type="name" name="bkgStn" id="bkgStn" value={bkgStn} onChange={(e)=>setBkgStn(e.target.value)} className="form-control" placeholder="BKG STN" />
                  { erBkgStn!=='' &&    <span className="errMgs">{erBkgStn}</span> }
                </div>
              </Col>
              <Col md={4}> 
                <div className='primary-from'>
                  <label> FWD. | TPT.EXP </label> 
                  <input type="name" name="fwdTptExp" id="fwdTptExp" value={fwdTptExp} onChange={(e)=>setFwdTptExp(e.target.value)} className="form-control" placeholder="FWD. | TPT.EXP" />
                  { erFwdTptExp!=='' &&    <span className="errMgs">{erFwdTptExp}</span> }
                </div>
              </Col>
              <Col md={4}>                                        
                <div className='primary-from'>
                  <label> DISCOUNT </label> 
                  <input type="name" name="discount" id="discount" value={discount} onChange={(e)=>setDiscount(e.target.value)} className="form-control" placeholder="Discount" />
                  { erDiscount!=='' &&    <span className="errMgs">{erDiscount}</span> }
                </div>
              </Col>

              

            </Row>
          </div>
        
        </Container>
      </div>
      <Container fluid className='back_width'>
      <Row>
        <Col md={12}> 
        <div className='together-hedi'> TEXTBOOKS FOR CLASSES (PRE-SCHOOL TO 8) AND LAB MANUALS FOR CLASSES (3 TO 12) </div>          
          <Row>
            <Col md={4}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td className='text-center' colSpan={4}> <b> Early Learning Series - English </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Everything Bud – A </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0790 <input type="hidden" name="codeNo2" id="codeNo2" value="0790" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Click Picture Dictionary </td>
                      <td className='text-center'>
                          <input type="number" name="0790" id="0790" className='cal-quantity' defaultValue="1" min="1" />                       
                      </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0790" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0795 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0795" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Colouring-N-Craft </td>
                      <td className='text-center'>  <input type="number" name="0795" id="0795" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0795" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0822 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0822" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Maukhik Akshar Gyan </td>
                      <td className='text-center'>  <input type="number" name="0822" id="0822" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0822" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0797 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0797" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Mehakte Balgeet </td>
                      <td className='text-center'>  <input type="number" name="0797" id="0797" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0797" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0793 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0793" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Recognise 123 </td>
                      <td className='text-center'>  <input type="number" name="0793" id="0793" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0793" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0791 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0791" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Recognise ABC </td>
                      <td className='text-center'>  <input type="number" name="0791" id="0791" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0791" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0796 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0796" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Tickling Rhymes </td>
                      <td className='text-center'>  <input type="number" name="0796" id="0796" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0796" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0794 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0794" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Write 123 </td>
                      <td className='text-center'>  <input type="number" name="0794" id="0794" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0794" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0792 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0792" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Write ABC </td>
                      <td className='text-center'>  <input type="number" name="0792" id="0792" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0792" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>


                    
                    

                  </tbody>
                </table>
              </div>

              

            
              
            </Col>
            <Col md={4}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Everything Bloom – B1 </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0798 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0798" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Click Picture Dictionary </td>
                      <td className='text-center'><input type="number" name="0798" id="0798" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0798" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0802 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0802" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Colouring-N-Craft </td>
                      <td className='text-center'><input type="number" name="0802" id="0802" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0802" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0801 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0801" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Maukhik Akshar Gyan </td>
                      <td className='text-center'><input type="number" name="0801" id="0801" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0801" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0799 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0799" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mehakte Balgeet</td>
                      <td className='text-center'><input type="number" name="0799" id="0799" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0799" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0803 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0803" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Recognise 123 </td>
                      <td className='text-center'><input type="number" name="0803" id="0803" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0803" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0800 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0800" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Recognise ABC</td>
                      <td className='text-center'><input type="number" name="0800" id="0800" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0800" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0804 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0804" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Tickling Rhymes</td>
                      <td className='text-center'><input type="number" name="0804" id="0804" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0804" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>


            </Col>

            <Col md={4}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Everything Bloom – B2 </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0806 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0806" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Likhit Akshar Gyan </td>
                      <td className='text-center'><input type="number" name="0806" id="0806" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0806" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0807 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0807" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Write 123 </td>
                      <td className='text-center'><input type="number" name="0807" id="0807" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0807" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0805 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0805" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Write Capital ABC </td>
                      <td className='text-center'><input type="number" name="0805" id="0805" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0805" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0809 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0809" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Write Cursive abc</td>
                      <td className='text-center'><input type="number" name="0809" id="0809" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0809" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0808 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0808" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Write Small abc </td>
                      <td className='text-center'><input type="number" name="0808" id="0808" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0808" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>


            </Col>

            


            
          </Row>
        </Col>
      </Row>

    
      
      


      




      <Row>
        <Col md={12}> 
        <Col>
            
                <center>
                  <button onClick={savePrimaryOrderFrm} className="forget_btn" type="button"> Submit </button>
                  { errorMsg!=='' &&  
                      <div className="alert alert-danger"> {errorMsg} </div>  }
                  { msg!=='' &&
                      <div className="alert alert-success">{msg}  </div>  }

                  { lodar!=='' &&
                  <div disabled><span className="spinner-grow spinner-grow-sm"></span> Loading... </div> }

                  
                </center>
              

             </Col>
        </Col>
      </Row>

      </Container>
      </form>

    <Footer/>
  </>
  );
};
export default PrimaryFrom;
import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import Accordion from 'react-bootstrap/Accordion';
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Helmet from 'react-helmet';

const Faqs = () => {
  const [seoData, setSeoData] = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  useEffect(() => { 
    getSeoData();
    AOS.init({
    offset: 300,
    duration:1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.faqSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"faqs"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
  <>
  {seoData.isDone &&
    <Helmet>
        <title>{seoData.title} </title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.ogTags?.og_title} />
        <meta property="og:description" content={seoData.ogTags?.og_description} />
        <meta property="og:url" content={seoData.ogTags?.og_url} />
        <meta property="og:image" content={seoData.ogTags?.og_image_url} />
        <meta name="twitter:site" content="@rachnasagargrp"/> 
        <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
        <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
        <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
    </Helmet>
  }
  <Header/>
  <Navigation/>
  <div className='branch-bg'>
    <Container>
        <h1 className="invi-authors" data-aos="fade-right"> FAQs </h1>
    </Container>
  </div>
    <Container>
      <div className='faqs ' data-aos="fade-up">
        <Row>
          <Col md={12}>
            <Accordion >
              <Accordion.Item eventKey="0" >
                <div className='bor'>
                  <Accordion.Header> <strong> Q. 1. </strong> &nbsp; What are the salient features of ‘Together with’ series? </Accordion.Header>
                  <Accordion.Body> <strong>Ans.</strong> The <b>‘Together With’</b> series has been designed as a practice/reference material. The books cover the entire syllabus prescribed by the CBSE/ICSE Boards as well as the guidelines for Class 8-12 mentioned in the NCERT curriculum. The series covers answers to complete NCERT textbook questions along with Select NCERT Exemplar Problems in addition to questions from the Previous Years’ Boards Examinations, Other State Boards, Kendriya Vidyalayas, NCT, DAV, Higher Order Thinking Skills (HOTS), Value-based Questions, and Important Questions. The books are divided into two parts. Part I of the book contain chapters in the form of Important Points to Remember/Important Definitions and Results, mind maps, diagrams and formulae, Previous Years’ Questions &amp; NCERT Questions based on the weightage prescribed by the relevant boards, Important Questions, Self-evaluation Tests, Let’s Recall, Project Work, etc. Part II of the book contains Latest Sample/Specimen Papers, Practice Papers, Examination Papers. The answers/solutions to the Practice Papers are published at gowebrachnasagar.com. Velcro attached with the book contains ‘Internal Assessment of Practical Work in the subjects where it is required by syllabus and Projects’ Books for classes 9 &amp; 10.  </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <div className='bor'>
                  <Accordion.Header> <strong>Q. 2.</strong> &nbsp; What are the features of Scanner Series?  </Accordion.Header>
                  <Accordion.Body> <strong>Ans.</strong> <b> Scanner Series</b> is a pack of 2 books for forthcoming CBSE Board Examinations. One consists of solutions to previous 7-8 years’ questions chapterwise and the other consists of solutions to  all the NCERT questions chapterwise along with select NCERT Exemplar Problems.
                  This series is based on curriculum of classes 10 and 12. In class 12, we have covered English Core, Mathematics, Physics, Chemistry, Biology, Economics and Business studies. In class 10, we have English Communicative, Hindi (A, B), Mathematics, Science and Social Studies. 
                  </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <div className='bor'>
                  <Accordion.Header> <strong> Q. 3. </strong> &nbsp; Explain important features of  15 + 1 CBSE Sample papers (EAD series). </Accordion.Header>
                  <Accordion.Body> <p><strong>Ans.</strong> <strong>EAD Series:</strong> EAD stands for Easy, Average and Difficult level of CBSE sample papers.
                  The idea behind introducing the concept is to prepare a student from the basic level, i.e. easy to  average level and then to difficult level. It is available for class 10 and 12.</p>
                  <p><b>Features of Class 10 Sample Papers</b></p> 
                  <p>Each level consists of 2 + 2 (Two Solved and Two Unsolved) sample papers. These are followed by 3 Annual papers based on the latest CBSE pattern and 1 sealed  mock paper (Unsolved) appended for final practice.
                  Solutions to unsolved sample papers and mock paper are available at gowebrachnasagar.com. </p> 
                  <p><strong>Features of Class 12 Sample Papers </strong></p>
                  <p>The series comes in a pack of 2 books for each subject. Book 1 consists of level-wise papers comprising of 2 + 2 (Two Solved and Two Unsolved) sample papers. These are followed by 3 Annual papers based on the latest CBSE pattern.
                  Book 2 consists of CBSE’s previous year’s topper’s answer sheet. It reflects the correct way of  writing the answerss. Latest CBSE Examination paper has also been to observe the latest pattern finally followed by 1 sealed one mock paper (Unsolved) for final practice.
                  Solutions to unsolved sample papers and mock paper are available at gowebrachnasagar.com
                  </p>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <div className='bor'>
                  <Accordion.Header> <strong> Q. 4. </strong> &nbsp; What is CBSE Pariksha?  </Accordion.Header>
                  <Accordion.Body> <strong>Ans.</strong> <strong>CBSE Pariksha</strong> is a unique set of three examination papers strictly designed on the CBSE pattern of examination with a sample answer sheet to test the knowledge and understanding of the students before the final Board Examinations. Students are expected to solve one paper and send back to us for evaluation. Toppers will be rewarded by the company.   </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <div className='bor'>
                  <Accordion.Header> <strong>Q. 5.</strong>&nbsp; Who is to be approached in case of any queries related to a particular subject? </Accordion.Header>
                  <Accordion.Body> <strong>Ans.</strong> You can communicate with the company by way of telephone/post/email
                  or through any other means of communication which is convenient to you and certainly your queries will 
                  be taken care of at &nbsp;<a href="mailto:editorial@rachnasagar.in">editorial@rachnasagar.in</a></Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <div className='bor'>
                  <Accordion.Header> <strong>Q. 6.</strong> &nbsp; How to get complementary/specimen copies of RSPL’s books? </Accordion.Header>
                  <Accordion.Body> <strong>Ans.</strong> 
                                  Usually our sales representatives provide specimen copies to teachers/principals at their respective schools/residence/coaching institutes. In case you are not able to get a copy, you are WELCOME to contact us at
                                  &nbsp;<a href="mailto:specimen@rachnasagar.in">specimen@rachnasagar.in</a>. </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <div className='bor'>
                  <Accordion.Header> <strong>Q. 7.</strong> &nbsp; For which boards, do you prepare books? </Accordion.Header>
                  <Accordion.Body> <strong>Ans.</strong> CBSE, ICSE, State Boards and some products for abroad. </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <div className='bor'>
                  <Accordion.Header> <strong>Q. 8.</strong> &nbsp; If we don’t find the book of your publication at our nearest bookshop, what shall we do?  </Accordion.Header>
                  <Accordion.Body> <strong>Ans.</strong> . Call us at our helpline line number 011-43585858 (Monday- Saturday: 10:00 AM to 06:30 PM ,2nd  Saturday Off). Our friendly staff will assist you or you can direct order us and we will send you through courier against advance payment.  </Accordion.Body>
                </div>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <div className='bor'>
                  <Accordion.Header> <strong>Q. 9.</strong> &nbsp; How do we know that your book is being published?  </Accordion.Header>
                  <Accordion.Body> <strong>Ans.</strong> You can visit our website rachnasagar.in  for the catalogue or can call at our “Help Desk”.  </Accordion.Body>
                </div>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </Container>
  <Footer/>
  
  </>
  );
};
export default Faqs;
import React,{useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import Loader from '../../../Loader';
const ShareCv = () => {
  const [resumeData, setResumeData] = useState({
    name:"",
    mobile:"",
    email:"",
    jobTitle:"",
    resume:"",
    msg:"",
    status:"",
    errMsg:""
  })
useEffect(() => {
  AOS.init({
    offset: 300,
    duration: 1000,
  });
}, []);
function changeResumeData(value, type){
  setResumeData((prev)=>{

    return {...prev, [type]:value}
  })
  console.log(value.name)
}

function shareResume(e){
  e.preventDefault();
  if(resumeData.name==""){
    setResumeData((prev)=>{
      return {...prev, status:'error', errMsg:"Please fill the name"}
    })
    return;
  } else if(resumeData.mobile==""){
    setResumeData((prev)=>{
      return {...prev, status:'error', errMsg:"Please fill your mobile number."}
    })
    return;
  } else if(resumeData.email==""){
    setResumeData((prev)=>{
      return {...prev, status:'error', errMsg:"Please fill your email."}
    })
    return;
  }  else if(resumeData.jobTitle==""){
    setResumeData((prev)=>{
      return {...prev, status:'error', errMsg:"Please fill job title ."}
    })
    return;
  } else if(resumeData.resume==""){
    setResumeData((prev)=>{
      return {...prev, status:'error', errMsg:"Please upload your resume."}
    })
    return;
  }
  setResumeData((prev)=>{
    return {...prev, status:'loading', errMsg:""}
  })
  const formData = new FormData();
  formData.append("name", resumeData.name);
  formData.append("email", resumeData.email);
  formData.append("phone", resumeData.mobile);
  formData.append("job_title", resumeData.jobTitle);
  formData.append("message", resumeData.msg);
  formData.append("resume", resumeData.resume);
  fetch(apiBaseURL+apiURL.applyJob,{
    method:'POST',
    headers:{
      'rskey' : apiKey,
    },
    body:formData
  } )
    .then( response => response.json() )
    .then( (result) => {
      console.log(result)
      if(result.status=="1"){
        setResumeData((prev)=>{
          return {
            ...prev, 
            name:"",
            mobile:"",
            email:"",
            jobTitle:"",
            resume:"",
            msg:"",
            status:'success', 
            errMsg:"Successfully submitted!"
          }
        })
        document.getElementById("resumeFile").value = "";
      } else {
        setResumeData((prev)=>{
          return {...prev, status:'error', errMsg:result.message}
        })
      }
    })
    .catch( (error)=>{
      console.log(error)
      setResumeData((prev)=>{
        return {...prev, status:'error', errMsg:"network error! try again."}
      })
    })
   
}
  return(
    <div className="App">
      <Header/>
      <Navigation/>
        <div className="feedback">
          <Container>
            <Row>
              <Col>
                <div className="feedback_form">
                <h1 className="login-main">SHARE YOUR CV</h1>
                <form className="sign_up" onSubmit={shareResume}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi">Full Name *</label>
                      <input type="name" className="form-control feed-mar" id="" placeholder="Enter Name" value={resumeData.name} onChange={(e)=>{changeResumeData(e.target.value, "name")}}/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi"> Mobile *</label>
                      <input type="" className="form-control feed-mar" id="" placeholder="Enter Mobile" value={resumeData.mobile} onChange={(e)=>{changeResumeData(e.target.value, "mobile")}}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi">Email *</label>
                      <input type="" className="form-control feed-mar" id="" placeholder="Enter email" value={resumeData.email} onChange={(e)=>{changeResumeData(e.target.value, "email")}}/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi">Job Title *</label>
                      <input type="" className="form-control feed-mar" id="" placeholder="Job Post Name" value={resumeData.jobTitle} onChange={(e)=>{changeResumeData(e.target.value, "jobTitle")}}/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi">Upload CV *</label>
                      <div> <input type="file" id="resumeFile" accept="application/pdf" className="form-control-file mt-1" onChange={(e)=>{changeResumeData(e.target.files[0], "resume")}}/> </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="login-hedi">Message :</label>
                      <textarea className="form-control feed-mar" rows="3" value={resumeData.msg} onChange={(e)=>{changeResumeData(e.target.value, "msg")}}></textarea>
                    </div>
                  </div>
                  <div className="col-md-12"> 
                  {(resumeData.status=="error" || resumeData.status=="success")&&
                    <div className="review-success" style={resumeData.status=="error"?{backgroundColor:'#f2a899'}:null}>
                        <div className="col-md-10 col-10"> {resumeData.errMsg} </div>
                        {/* <div className="col-md-2 col-2"> <div className="review-empty">✕</div> </div> */}
                    </div>
                  }
                    <div className="right-login">
                    {resumeData.status=="loading"?
                      <div style={{position:'relative', height:'80px'}}>
                        <Loader width="50px" height="50px" backgroundColor="transparent"/>
                      </div>: 
                      <button className="forget_btn" type="submit"> Submit </button>
                    }
                      {/* <button className="forget_btn" type="button"> Cancel </button> */}
                    </div>
                  </div>
                </div>              
              </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      <Footer/>
    </div>
  );
};
export default ShareCv;



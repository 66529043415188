import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import inviauthors from '../images/invi-authors.jpg';
import invistudy from '../images/invi-study.jpg';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Error from '../Error/Error';
import Loader from '../../../Loader';

const Invitation_Author = () => {
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  });
  const [inviData, setInviData] = useState({
    name:"",
    email:"",
    mobile:"",
    landline:"",
    address:"",
    city:"",
    state:"",
    country:"",
    pExpYear:0,
    pExpMonth:0,
    tExpYear:0,
    tExpMonth:0,
    menuScript:"Complete",
    file:undefined
  });
  const [resData, setResData] = useState({
    status:"",
    msg:"",
    fun:undefined
  })

  useEffect(() => { 
    getSeoData();
    AOS.init({
    offset: 300,
    duration:1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.invitionAuthorsSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"invitationtoauthors"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }

  function changeInviData(value, type){
    // console.log(value);

    setInviData((prev)=>{

      return {...prev, [type]:value}
    })
  }

  

  function canclePopup(){
    setResData((prev)=>{
      return {status:'', msg:"", fun:undefined}
    })
  }

  function resetData(){
    setInviData((prev)=>{

      return {
        ...prev,
        name:"",
        email:"",
        mobile:"",
        landline:"",
        address:"",
        city:"",
        state:"",
        country:"",
        pExpYear:0,
        pExpMonth:0,
        tExpYear:0,
        tExpMonth:0,
        menuScript:"Complete",
        file:undefined
      }
    }) 
    document.getElementById("synopsisFile").value = "";
  }
  function sendData(){



    if(inviData.name==""){
      setResData((prev)=>{
        return {...prev, status:'name', msg:"name is required", fun:undefined}
      })
      return;
    } else if(inviData.email==""){
      setResData((prev)=>{
        return {...prev, status:'email', msg:"email is required", fun:undefined}
      })
      return;
    } else if(inviData.mobile==""){
      setResData((prev)=>{
        return {...prev, status:'mobile', msg:"mobile no is required", fun:undefined}
      })
      return;
    } else if(inviData.landline==""){
      setResData((prev)=>{
        return {...prev, status:'landline', msg:"landline no is required", fun:undefined}
      })
      return;
    } else if(inviData.address==""){
      setResData((prev)=>{
        return {...prev, status:'address', msg:"address is required", fun:undefined}
      })
      return;
    } else if(inviData.city==""){
      setResData((prev)=>{
        return {...prev, status:'city', msg:"city name is required", fun:undefined}
      })
      return;
    } else if(inviData.state==""){
      setResData((prev)=>{
        return {...prev, status:'state', msg:"state name is required", fun:undefined}
      })
      return;
    } else if(inviData.country==""){
      setResData((prev)=>{
        return {...prev, status:'country', msg:"country name is required", fun:undefined}
      })
      return;
    } else if(inviData.file==undefined){
      setResData((prev)=>{
        return {...prev, status:'file', msg:"choose a file", fun:undefined}
      })
      return;
    }  

    let formData = new FormData();
    formData.append("name", inviData.name);
    formData.append("email", inviData.email);
    formData.append("phone", inviData.mobile);
    formData.append("landline", inviData.landline);

    formData.append("address", inviData.address.trim());
    formData.append("city", inviData.city);
    formData.append("state", inviData.state);
    formData.append("country", inviData.country);
    formData.append("exp_pub_year", inviData.pExpYear);
    formData.append("exp_pub_month", inviData.pExpMonth);
    formData.append("exp_auth_year", inviData.tExpYear);
    formData.append("exp_auth_month", inviData.tExpYear);
    formData.append("manuscript_status", inviData.menuScript);
    formData.append('file', inviData.file)

    setResData((prev)=>{
      return {...prev, status:"loading"}
    })
    fetch(apiBaseURL+apiURL.invitationAuthor,{
      method:'POST',
      headers:{
        'rskey' : 'rspl',
      },
      body:formData
    })
      .then( response => response.json() )
      .then( (result) => {
        console.log(result)
        if(result.status=="1"){
          setResData((prev)=>{
            return {...prev, status:'success', msg:"Successful!", fun:undefined}
          })
          resetData()
        } else {
          setResData((prev)=>{
            return {...prev, status:'error', msg:result.message, fun:sendData}
          })
        }
      })
      .catch( (error)=>{
         console.log(error)
         setResData((prev)=>{
          return {...prev, status:'error', msg:"network error!", fun:sendData}
        })
      });

     
  }
  return(
  <>
  {seoData.isDone &&
    <Helmet>
        <title>{seoData.title} </title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.ogTags?.og_title} />
        <meta property="og:description" content={seoData.ogTags?.og_description} />
        <meta property="og:url" content={seoData.ogTags?.og_url} />
        <meta property="og:image" content={seoData.ogTags?.og_image_url} />
        <meta name="twitter:site" content="@rachnasagargrp"/> 
        <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
        <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
        <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
    </Helmet>
  }
  <Header/>
  <Navigation/>
  <div className='branch-bg'>
    <Container>
        <h1 className="invi-authors"> Invitation to Authors </h1>
        <div className='box-invi'>
          <Row>
              <Col md={2}>
              <img src ={inviauthors} />
              </Col>
              <Col md={10} data-aos="zoom-in">
              <p> As a part of Rachna Sagar, we can boast of a board-ranging resources including multiple sales channels, targeted marketing efforts, a significant investment in technology, and a commitment to customer service. These resources are crucial in the rapidly changing world of publishing. Just as you have a passion for what you do and a standard of excellence that you strive to reach, we pride ourselves on working with a kind of people who bring a passion for teaching, a love of ideas, a penchant for innovation, and a wide range of experience to their work. </p>
              </Col>
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
              <Col md={12}>
              <p>Rachna Sagar has a rich reputation as the India’s most successful publisher of pre-school and school text books and other educational materials as well.
                Central to our success We value our authors and their contribution in our publications. We have a very special relationship with our authors. If you are interested 
                in working with us, we would like to inform you about   few guidelines to be followed in Rachna Sagar.</p>
              <ol type="square" className='invi-num'>
                <li> A commitment to publishing quality content for courses at school level. </li>
                <li> Unparalleled editorial expertise and resources. </li>
                <li> A commitment to strong, focused marketing of the books that we publish through every viable distribution channel. </li>
              </ol>
              </Col>
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
              <Col md={10}  data-aos="zoom-in">
              <p> 
                Rachna Sagar encourages and welcomes researchers, writers, authors and eminent experts to send their valuable contributions for book publications, available through the site. The material for publishing may preferably be submitted online. However, such materials may also be mailed or sent in a CD format or as hard copy through an ordinary post. For submissions please mail your proposal to <a href="mailto:editorial@rachnasagar.in">editorial@rachnasagar.in</a> <br/><br/>
                Please retain a copy of the proposal and sample chapters that you send us, as we do not return any material that is sent to us. Please do not send us a manuscript if you have already sent it for evaluation elsewhere. 
              </p>
              </Col>
              <Col md={2} >
              <img src ={invistudy} />
              </Col>
          </Row>
        </div>
    </Container>
  </div>
  <Container>
    <div className='invitation-form'>
      <h2 className='invifrom-hedi'>Description (Institute / School Details) :</h2>
        <div className='invi-bg'>
          <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                  <label className="login-hedi">Name *</label>
                  <input type="name" className="form-control feed-mar" id="" placeholder="name"  value={inviData.name} onChange={(e)=>{changeInviData(e.target.value, "name")}} />
                  {resData.status=="name"&&
                    <span className="errMgs">{resData.msg}</span>
                  }
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                  <label className="login-hedi">Email Id *</label>
                  <input type="email" className="form-control feed-mar" id="" placeholder="email" value={inviData.email} onChange={(e)=>{changeInviData(e.target.value, "email")}} />
                  {resData.status=="email"&&
                    <span className="errMgs">{resData.msg}</span>
                  }
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                  <label className="login-hedi">Mobile *</label>
                  <input type="mobile" className="form-control feed-mar" id="" placeholder="mobile" value={inviData.mobile} onChange={(e)=>{changeInviData(e.target.value, "mobile")}} />
                  {resData.status=="mobile"&&
                    <span className="errMgs">{resData.msg}</span>
                  }
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                  <label className="login-hedi">Landline *</label>
                  <input type="" className="form-control feed-mar" id="" placeholder="landline" value={inviData.landline} onChange={(e)=>{changeInviData(e.target.value, "landline")}} />
                  {resData.status=="landline"&&
                    <span className="errMgs">{resData.msg}</span>
                  }
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                  <label className="login-hedi">Address *</label>
                  <textarea className="form-control feed-mar" rows="2" value={inviData.address} onChange={(e)=>{changeInviData(e.target.value, "address")}}></textarea>
                  {resData.status=="address"&&
                    <span className="errMgs">{resData.msg}</span>
                  }
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
                <div className="form-group">
                  <label className="login-hedi">City *</label>
                  <input type="city" className="form-control feed-mar" id="" placeholder="city"  value={inviData.city} onChange={(e)=>{changeInviData(e.target.value, 'city')}}/>
                  {resData.status=="city"&&
                    <span className="errMgs">{resData.msg}</span>
                  }
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                  <label className="login-hedi">State *</label>
                  <input type="state" className="form-control feed-mar" id="" placeholder="state"  value={inviData.state} onChange={(e)=>{changeInviData(e.target.value, 'state')}}/>
                  {resData.status=="state"&&
                    <span className="errMgs">{resData.msg}</span>
                  }
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                  <label className="login-hedi">Country *</label>
                  <input type="country" className="form-control feed-mar" id="" placeholder="country"  value={inviData.country} onChange={(e)=>{changeInviData(e.target.value, 'country')}}/>
                  {resData.status=="country"&&
                    <span className="errMgs">{resData.msg}</span>
                  }
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                      <div><label className='login-hedi'>Experience in Publishing field *</label></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3">
                            <div className='year-center'>Year</div>
                        </div>
                        <div className="col-md-9">
                          <select className="form-control feed-mar form-select" value={inviData.pExpYear} onChange={(e)=>{changeInviData(e.target.value, 'pExpYear')}}>
                            <option> 0</option>
                            <option> 1 </option>
                            <option> 2 </option>
                            <option> 3 </option>
                          </select>
                        </div>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3">
                            <div className='year-center'>Month</div>
                        </div>
                        <div className="col-md-9">
                          <select className="form-control feed-mar form-select" value={inviData.pExpMonth} onChange={(e)=>{changeInviData(e.target.value, 'pExpMonth')}}>
                            <option> 0</option>
                            <option> 1 </option>
                            <option> 2 </option>
                            <option> 3 </option>
                          </select>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                      <div> <label className='login-hedi'>Experience as Teacher / Author *</label> </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3">
                            <div className='year-center'>Year</div>
                        </div>
                        <div className="col-md-9">
                          <select className="form-control feed-mar form-select" value={inviData.tExpYear} onChange={(e)=>{changeInviData(e.target.value, 'tExpYear')}}>
                            <option> 0</option>
                            <option> 1 </option>
                            <option> 2 </option>
                            <option> 3 </option>
                          </select>
                        </div>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3">
                            <div className='year-center'>Month</div>
                        </div>
                        <div className="col-md-9">
                          <select className="form-control feed-mar form-select" value={inviData.tExpMonth} onChange={(e)=>{changeInviData(e.target.value, 'tExpMonth')}}>
                            <option> 0 </option>
                            <option> 1 </option>
                            <option> 2 </option>
                            <option> 3 </option>
                          </select>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
            <div className="form-group">
              <label className="login-hedi">Manuscript Status *</label>
                <div>
                <FormControl>
                  <RadioGroup 
                    row aria-labelledby="demo-row-radio-buttons-group-label" value={inviData.menuScript} name="row-radio-buttons-group" onChange={(e)=>{changeInviData(e.target.value, 'menuScript')}}>
                    <FormControlLabel value="Complete" control={<Radio />} label="Complete" />
                    <FormControlLabel value="Incomplete" control={<Radio />} label="Incomplete" />
                  </RadioGroup>
                </FormControl>
                </div>
            </div>
            </div>
          </div> 
          <div className='row'>
            <div className='col-md-8'>
              <div className='choose-hedi'>
                Upload Synopsis or Write Synopsis ( Choose file Doc or PDF ) *
              </div>
             
            </div>
            <div className='col-md-4'>
              <input id="synopsisFile" type="file" accept="application/pdf,application/msword"  className="form-control-file" onChange={(e)=>{changeInviData(e.target.files[0], 'file')}} />
              {resData.status=="file"&&
                <span className="errMgs">{resData.msg}</span>
              }
            </div>
          </div>
          <div className="col-md-12">
            <center>
              {resData.status=="success" && 
                <div style={{color:'green'}}>{resData.msg}</div>
              }
              {resData.status=="loading"?
                <div style={{position:'relative', height:'80px'}}>
                 <Loader width="50px" height="50px" backgroundColor="transparent"/>
               </div>:  
               <>
                <button className="forget_btn" type="button" onClick={resetData}> Reset </button>
                <button className="forget_btn" type="button" onClick={sendData}> Submit </button>
               </>
              }
             
            </center>
          </div>
        </div>
    </div>
  </Container>
  {resData.status=="error"&&
    <Error cancle={canclePopup} msg={resData.msg} fun={resData.fun}/>
  }
  <Footer/>
  </>
  );
};
export default Invitation_Author;
import React , {useState, useContext, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { apiBaseURL, apiURL, apiKey} from "../../../constant";
import {Contaxt} from '../../../Store'
import Loader from "../../../Loader";

const Login = () => {

  const [email,setEmail]=useState("");
  const [password,setPassword]=useState("");

  const [eremail, setEremail] = useState(""); 
  const [erpassword, setErpassword] = useState("");
  const [loader, setLoader] = useState(false);
  const params = useParams();

  const {userLogin, userData} = useContext(Contaxt);
  const {state} = useLocation();
  let navigate = useNavigate();
  useEffect(()=>{
    if(userData.loginStatus){
      if(params.rediUrl!=undefined){
        let url = atob(params.rediUrl);
        navigate(`/${url}`, {replace:true, state:state})
      } else {
        navigate(`/`, {replace:true});
      }
    
    }
  })
  function checkLogin(e){
    e.preventDefault();
    if(email==''){
      setEremail("Please fill email")
    } else if(password==''){
      setEremail("");
      setErpassword("Please fill password")
    } else {
      userLogin({"email":email, "password":password})
    }
  }

  function googleLogin(){

    setLoader(true)
    fetch(apiBaseURL+apiURL.googleLogin,{
      method:'GET',
      headers:{
          'rskey':apiKey,
      }
    })
    .then( (result) => {
      result.json().then((res) => {
        console.log(res)
        if (res.status == "1") {
          window.location.replace(res.google_login_link);
        } else {
          console.log(res)  
        }
      });

    }).catch( (err)=>{
        console.log(err);
    })
    // window.gapi.load('client:auth2', ()=>{
      
    //   console.log(window.gapi.client.init);
    //   window.gapi.client.init({
    //     // 'apiKey': 'YOUR_API_KEY',
    //     'clientId': '526608979341-1bo5mj70c9nq66gkaf8gc3g566nekpv0.apps.googleusercontent.com',
    //     'discoveryDocs': [],
    //     'uxMode': 'popup',
    //     'scope': 'profile email'
    //   })
    //   .then((res)=>{
    //     console.log(res);
    //   })
    //   .catch((err)=>{
    //     console.log(err);
    //   })
    // });

  }

  
  

  return(
    <div className="App">
      <Header/>
      <Navigation/>
      <div className="branch-bg">
        
        <Container>
          <Row>
            <Col>
              <div className="form-wrap" style={{position:"relative"}}>
              {(userData.isLoading || loader) &&
                  
                  <Loader width="80px" height="80px"/>  
                
              }
              <h1 className="login-main">LOGIN</h1>
             
                {userData.error.status && 
                   <div className="alert alert-danger"> {userData.error.msg} </div> 
                }
                <form id="warp-login" onSubmit={checkLogin}>
                  <div className="form-group">
                    <label className="login-hedi"> Enter Email </label>                    
                    <input type="email" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control login-mar" placeholder="Enter Email Id " />
                    { eremail!=='' &&    <span className="errMgs">{eremail}</span> }
                  </div>
                  <div className="form-group">
                    <label className="login-hedi">Enter Password</label>
                    
                    <input type="password" name="password" id="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control login-mar" placeholder="" />
                    { erpassword!=='' &&    <span className="errMgs">{erpassword}</span> }
                  </div>
                  <div className="login-button">
                    <button  className="login_btn" type="submit"> <i className="fa fa-sign-in" aria-hidden="true"></i> &nbsp;  Login </button> 
                  </div>
                  <div className="form-group">
                    <center>OR</center>
                  </div>
                  <div className="form-group">
                    <center>
                    <Link to="/requestOtp"><button className="otp_btn" type="button">  Request OTP </button> </Link>
                    {/* <button className="otp_btn" type="button" onClick={googleLogin}>  <i class="fa fa-google" aria-hidden="true"></i> &nbsp; Google  </button> */}
                    </center>
                  </div>

                  




                  <Row>
                    <Col md={12}> 
                      <div className="google_btn">
                        <Link to="/forget-passward">Forget Password</Link>
                        <Link to="/signup">Create Account</Link>
                      </div>  
                    </Col>
                  </Row>
                </form>

                {/* { errorMsg!=='' &&  
                    <div className="alert alert-danger"> {errorMsg} </div>  }
                { msg!=='' &&
                    <div className="alert alert-success">{msg}  </div>  }

                { lodar!=='' &&
                <div disabled><span className="spinner-grow spinner-grow-sm"></span> Loading... </div> } */}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
};
export default Login;



import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from "react-bootstrap";

import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import bookshop from "../images/books-store.jpg";
import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import Error from '../Error/Error';
import Loader from '../../../Loader';
import Helmet from 'react-helmet';

const Bookshop = () => {

  const [shopData, setShopData] = useState({
    states:[],
    cities:[],
    stores:[],
    currCity:"",
    isLoading:true,

  });
  const [seoData, setSeoData] = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })

  const [selEle, setSelEle] = useState({state:"", city:""})
  const [error, setError] = useState({status:false, msg:"", fun:undefined, canName:"Cancle"})
  useEffect(() => { 
    getSeoData()
    getStates()
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.bookShopSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"bookshop"})
        });
      let result = await response.json();
      if(result.status=="1"){ 
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  function removeError(){
    setError({status:false, msg:"", fun:undefined, canName:"Cancle"})
  }


  function getStates(){
    removeError();
    setShopData((prev)=>{

      return {
        ...prev,
        states:[],
        cities:[],
        isLoading:true
       }
    });
    fetch(apiBaseURL+apiURL.boostoreStates,{
      method:'POST',
      headers:{
          'rskey':apiKey
      },
    })
    .then( (result) => {
      result.json().then((res) => {
        if (res.status == "1") {
          setShopData((prev)=>{
            return {...prev, states:res.State_List, isLoading:false}
          })
        } else {
          setShopData((prev)=>{
            return {...prev, isLoading:false}
          })
          setError({status:true, msg:res.message, fun:getStates, canName:"Cancle"})
        }
      });
      //====================================

    }).catch( (err)=>{
     
      console.log(err);
      setShopData((prev)=>{
        return {...prev, isLoading:false}
      })
      setError({status:true, msg:"Network Error!", fun:getStates, canName:"Cancle"})
    })

  }

  function getCities(stateId){

    removeError();
    setShopData((prev)=>{

      return {
        ...prev,
        cities:[],
        // isLoading:true
       }
    })
    setSelEle({state:stateId, city:""})
    fetch(apiBaseURL+apiURL.boostoreCities,{
      method:'POST',
      headers:{
        'rskey':apiKey
      },
      body:JSON.stringify({StateId:stateId})
  } )
  .then( (result) => {
      result.json().then((res) => {
        if (res.status == "1") {
          setShopData((prev)=>{
            return {...prev, cities:res.State_List, isLoading:false}
          })
        } else {
          setShopData((prev)=>{
            return {...prev, isLoading:false}
          })
          setError({status:true, msg:res.message, fun:getCities, canName:"Cancel"})
        }
      });
      //====================================

    }).catch( (err)=>{
     
      console.log(err);
      setShopData((prev)=>{
        return {...prev, isLoading:false}
      })
      setError({status:true, msg:"Network Error!", fun:getCities, canName:"Cancel"})
    })

  }

  function reloadBookStores(){
    getBookStores(selEle.city);
  }
  function getBookStores(cityId=undefined){
    removeError();
    setShopData((prev)=>{

      return {
        ...prev,
        isLoading:true
       }
    })
    setSelEle((prev)=>({...prev, city:cityId}))
    fetch(apiBaseURL+apiURL.boostoreStores,{
      method:'POST',
      headers:{
        'rskey':apiKey
      },
      body:JSON.stringify({StateId:selEle.state, CityId:cityId})
    })
    .then( (result) => {
      result.json().then((res) => {
        console.log(res);

        if (res.status == "1") {
          setShopData((prev)=>{
            return {...prev, stores:res.State_List, currCity:res.City_Name, isLoading:false}
          })
        } else {
          setShopData((prev)=>{
            return {...prev, isLoading:false, stores:[]}
          })
          setError({status:true, msg:res.message, fun:undefined, canName:"OK"})
        }
      });
      //====================================

    }).catch( (err)=>{
     
      console.log(err);
      setShopData((prev)=>{
        return {...prev, isLoading:false}
      })
      setError({status:true, msg:"Network Error!", fun:reloadBookStores, canName:"Cancle"})
    })
  }
  return(
    <>
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
      <div className='banner' data-aos="fade-right">
          <img src={bookshop}/>
      </div>
      <Container>
        <div className='bookshop'>
          <Row>
            <h1>Bookstore Near Me</h1> 
            <Col md={6}> 
              <div className="bookshop-from">
              <select value={selEle.state} onChange={(e)=>{getCities(e.target.value)}}>
                <option> --- Select State --- </option>
                {shopData.states.map((item)=><option key={item.StateId} value={item.StateId}>{item.State_Title}</option>)}
                {/* <option>Andhra Pradesh</option>
                <option> Bihar </option>
                <option> Chhattisgarh</option> */}
              </select>
            </div>
            </Col>
            <Col md={6}> 
              <div className="bookshop-from">
              <select  value={selEle.city} onChange={(e)=>{getBookStores(e.target.value)}}>
                <option>  --- Select City ---  </option>
                {shopData.cities.map((item)=><option key={item.CityId} value={item.CityId}>{item.City_Title}</option>)}
                {/* <option>Andhra Pradesh</option>
                <option> Bihar </option>
                <option> Chhattisgarh</option> */}
              </select>
            </div>
            </Col>
          </Row>
          <div className="line"></div>
          {shopData.isLoading ? 
          <div style={{position:'relative', height:'200px'}}>
            <Loader width="80px" height="80px" backgroundColor="transparent"/>
          </div>:
          <>
          {shopData.stores?.length>0&&
            <Col> 
              <div className="bohedi">In <span className="bohedi1">{shopData.currCity}</span>, 
              <span className="font">Together with<sup>®</sup></span> books are available at the following stores : </div>
            </Col>
          }
          <Row>
            <Col md={10} sm={12} xs={12} className="margin-auto">
              {shopData.stores.map((item)=>{

                return (
                  <div className="bookshop-main">
                    <div className="bookshop-panel">{item.Shop_Name}</div>
                    <Row>
                      <Col md={4} sm={4} xs={12}>
                        <div className="bookfa favi2"><i className="fa fa-home" aria-hidden="true"></i></div>
                        <div className="add">Shop Name</div>
                      </Col>
                      <Col md={8} sm={8} xs={12}>
                        <div className="add">{item.City}</div>
                      </Col>
                      <Col md={4} sm={4} xs={12}>
                        <div className="bookfa"><i className="fa fa-map-marker" aria-hidden="true"></i></div>
                        <div className="add">Address</div> 
                      </Col>
                      <Col md={8} sm={8} xs={12}>
                        <div className="add">{item.Address}</div>
                      </Col>
                      <Col md={4} sm={4} xs={12}>
                        <div className="bookfa favi2"><i className="fa fa-map-pin" aria-hidden="true"></i></div>
                        <div className="add">Pin code</div>
                      </Col>
                      <Col md={8} sm={8} xs={12}>
                        <div className="add">{item.Pincode}</div>
                      </Col>
                       <Col md={4} sm={4} xs={12}>
                      <div className="bookfa"><i className="fa fa-phone" aria-hidden="true"></i></div>
                        <div className="add">Contact Number</div>
                      </Col>
                      <Col md={8} sm={8} xs={12}>
                        <div className="add">{item.Contact_Number}</div>
                      </Col>
                      <Col md={4} sm={4} xs={12}>
                      <div className="bookfa favi1"><i className="fa fa-envelope" aria-hidden="true"></i></div>
                        <div className="add">Email Id</div>
                      </Col>
                      <Col md={8} sm={8} xs={12}>
                        <div className="add">{item.EmailId}</div>
                      </Col>
                    </Row>
                  </div>
                )
              })}
              
              {/* Bookshop Address */}
                {/* <div className="bookshop-main">
                  <div className="bookshop-panel">M/S Jyothi Book Stall</div>
                  <Row>
                    <Col md={4} sm={4} xs={12}>
                      <div className="bookfa"><i className="fa fa-map-marker" aria-hidden="true"></i></div>
                      <div className="add">Address</div> 
                    </Col>
                    <Col md={8} sm={8} xs={12}>
                      <div className="add">8/100, Subash Road, Near Over Bridge, Anantapur</div>
                    </Col>
                    <Col md={4} sm={4} xs={12}>
                      <div className="bookfa favi1"><i className="fa fa-map-pin" aria-hidden="true"></i></div>
                      <div className="add">Pincode</div>
                    </Col>
                    <Col md={8} sm={8} xs={12}>
                      <div className="add">515001</div>
                    </Col>
                    <Col md={4} sm={4} xs={12}>
                      <div className="bookfa"><i className="fa fa-home" aria-hidden="true"></i></div>
                      <div className="add">City</div>
                    </Col>
                    <Col md={8} sm={8} xs={12}>
                      <div className="add">Anantapur</div>
                    </Col>
                    <Col md={4} sm={4} xs={12}>
                      <div className="bookfa favi2"><i className="fa fa-phone" aria-hidden="true"></i></div>
                      <div className="add">Contact Number</div>
                    </Col>
                    <Col md={8} sm={8} xs={12}>
                      <div className="add">9849274962 , 08554-221598</div>
                    </Col>
                    <Col md={4} sm={4} xs={12}>
                      <div className="bookfa"><i className="fa fa-envelope" aria-hidden="true"></i></div>
                      <div className="add">Email Id</div>
                    </Col>
                    <Col md={8} sm={8} xs={12}>
                      <div className="add">jyothibookstall.atp27@gmail.com</div>
                    </Col>
                  </Row>
              </div> */}
            </Col>
          </Row>
          </>
        }
        </div>
      </Container>
      {error.status && 
        <Error msg={error.msg} cancle={removeError} fun={error.fun} canName={error.canName}/>
      }
      <Footer/>
    </>
  );
};
export default Bookshop;



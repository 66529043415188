import React, {useState, useEffect, useContext, useRef} from 'react';
import { Container, Row, Col, Placeholder} from "react-bootstrap";

import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import payment from "../images/paytm.jpg";
import ccavenue from "../images/ccavenue.jpg";
import junio from "../images/junio.jpg";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../../../Loader';
import Error from '../Error/Error';
import { Contaxt } from '../../../Store';
import { apiBaseURL, apiURL, apiKey } from '../../../constant';

const Payment = () => {

  const [payData, setPayData] = useState({
    isLoading:false,
    isError:false,
    msg:"",
    fun:undefined,
    error:""
  });
  const navigate = useNavigate();
  const {userData} = useContext(Contaxt);
  const ccForm = useRef(null);
  const [ccData, setCcData] = useState({
    encRequest:"",
    access_code:"",
  })
  const [queryString] = useSearchParams();


  useEffect(() => { 
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  useEffect(()=>{
    console.log(ccData)
    if(ccData.encRequest!=undefined && ccData.access_code!=undefined && ccData.encRequest!="" && ccData.access_code!=""){
      ccForm.current.submit();
    }
  }, [ccData])

  
 
  async function palceOrder(type){
    if(!userData.loginStatus){
      navigate('/account/login')
      return;
    }
    setPayData((prev)=>{
      return {...prev, isLoading:true, error:false, fun:undefined}
    })
    let link = apiURL.onlineOrder
    if(queryString.get('type')=="buyNow"){
      link = apiURL.buyNowOnlineOrder
    }
    try {
      let response = await  fetch(apiBaseURL+link,{
          method:'POST',
          headers:{
              'rskey':apiKey,
              'utoken':userData.token
          },
          body:JSON.stringify({"payment_gateway":type, 'order_type':'web'})
        });
      let result = await response.json();
      console.log(result)
      if(result.status=="1"){
        if(type=='paytm'){
          openPaytmPage(result.data.orderId, result.data.txnToken, result.data.amount)
        } else {
          setPayData((prev)=>{
            return {...prev, isLoading:false}
          })
          setCcData({
            encRequest:result.ccavenue_data?.encRequest,
            access_code:result.ccavenue_data?.access_code
          })
        }
      } else {
        setPayData((prev)=>{
          return {...prev, isLoading:false, error:true, msg:result.message, fun:()=>{palceOrder(type)}}
        })
        
      }

    } catch (error) {
      console.log(error)
      setPayData((prev)=>{
        return {...prev, isLoading:false, error:true, msg:"Network Error!", fun:()=>{palceOrder(type)}}
      })
    }
  }



  function openPaytmPage(orderId, token, amount){
    let config = {
      "root": "",
      "flow": "DEFAULT",
      "data": {
      "orderId": orderId, /* update order id */
      "token": token, /* update token value */
      "tokenType": "TXN_TOKEN",
      "amount": amount /* update amount */
      },
      "handler": {
        "notifyMerchant": function(eventName,data){
          console.log("notifyMerchant handler function called");
          console.log("eventName => ",eventName);
          console.log("data => ",data);
        } 
      }
    };

    if(window.Paytm && window.Paytm.CheckoutJS){
        // window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
            // initialze configuration using init method 
            window.Paytm.CheckoutJS.init(config)
            .then(function onSuccess() {
                // after successfully updating configuration, invoke JS Checkout
                window.Paytm.CheckoutJS.invoke();
                setPayData((prev)=>{
                  return {...prev, isLoading:false}
                })
            }).catch(function onError(error){
                console.log("error => ",error);
                setPayData((prev)=>{
                  return {...prev, isLoading:false, error:true, msg:error, fun:undefined}
                })
            });
        // });
    } 
  }
 
  function canclePopup(){
    setPayData((prev)=>{
      return {...prev, error:false, isLoading:false, fun:undefined, msg:""}
    })
  }
  return(
    <>
      <Header/>
      <Navigation/>
        <div className='cart-bg' style={{position:'relative'}}>
          {payData.isLoading && 
          <Loader/>  
        }
          <Container>
            <div className="payment-option">
              <h1> Select Your Payment Option </h1>
              <br/><br/><br/>
              <div className='imgpay' style={{cursor:'pointer'}} onClick={()=>{palceOrder("paytm")}}> <img src={payment}/> </div>
              <div className='imgpay' style={{cursor:'pointer'}} onClick={()=>{palceOrder("ccavenue")}}> <img src={ccavenue}/> </div>
              {/* <div className='imgpay'> <img src={junio}/> </div> */}
            </div>
          </Container>
        </div>
        <form 
          method="post" 
          name="redirect" 
          action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
          ref={ccForm}
        >
          <input  hidden name="encRequest" value={ccData.encRequest} readOnly/>
          <input  hidden name="access_code" value={ccData.access_code} readOnly/>
        </form>
      {payData.error && 
        <Error msg={payData.msg} fun={payData.fun} cancle={canclePopup} />
      }
      <Footer/>
    </>
  );
};
export default Payment;



import React,{useState,useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import {useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 1000,
    });
    setTimeout(()=>{
      navigate('/');
    }, 2000)

  }, []);


  return(
    <div className="App">
      <Header/>
      <Navigation/>
          <Row>
            <Col>
            <div className="pageNot">
                Page Not Found!
            </div>
            </Col>
          </Row>
      <Footer/>
    </div>
  );
};
export default PageNotFound;



import React,{useEffect, useState, useContext} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import Loader from '../../../Loader';
import {Contaxt} from '../../../Store'
import {Link, useNavigate } from "react-router-dom";

const RequsetOtp = () => {

  
  const [emailData, setEamilData]  = useState({
                                              status:"",
                                              email:"",
                                              otp:"",
                                              msg:"",
                                              type:'request'
                                            });
  const {changeUserData, userData} = useContext(Contaxt);
  let navigate = useNavigate();
  useEffect(()=>{
    if(userData.loginStatus){
      navigate(`/orderDetail`);
    }
  })
  useEffect(() => {

    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  function submitData(e){
    e.preventDefault();
    if(emailData.type=="request"){
      sendOtp();
    } else {
      varifyOtp();
    }
  }
  function sendOtp(){
    
    
    setEamilData((prev)=>{
      return {...prev, status:"loading"}
    })
    fetch(apiBaseURL+apiURL.requestOtp,{
      method:'POST',
      headers:{
        'rskey' : 'rspl',
      },
      body:JSON.stringify({email:emailData.email}) 
    })
    .then( response => response.json() )
    .then( (result) => {
      console.log(result)
      
      if(result.status=="1"){
        setEamilData((prev)=>{
          return {...prev, status:"success", msg:"OTP has been sent to your mobile or email address.", type:"sendOpt"}
        })
      } else if(result.status=="0"){
        setEamilData((prev)=>{
          return {...prev, status:"error", msg:result.message}
        })
        // show error
      }
    })
    .catch( (error)=>{
        console.log(error)
        setEamilData((prev)=>{
          return {...prev, status:"error", msg:"Network error!"}
        })
      //  show error
    });

  }

  function varifyOtp(){


    setEamilData((prev)=>{
      return {...prev, status:"loading"}
    })
    fetch(apiBaseURL+apiURL.varifyOtp,{
      method:'POST',
      headers:{
        'rskey' : 'rspl',
      },
      body:JSON.stringify({email:emailData.email, otp:emailData.otp}) 
    })
    .then( response => response.json() )
    .then( (result) => {
      console.log(result)
      
      if(result.status=="1"){
        // setEamilData((prev)=>{
        //   return {...prev, status:"success", msg:result.message}
        // });
        changeUserData({
          name:result.data.name,
          email:result.data.email,
          address:result.data.address,
          pin:result.data.Zip,
          city:result.data.City,
          country:result.data.Country,
          state:result.data.State,
          landmark:result.data.landmark,
          mobile:result.data.Mobile,
          userType:result.data.user_type,
          token:result.data.api_token,
          isLoading:false,
          loginStatus:true,
          error:{staue:false, msg:""}
        });
        localStorage.setItem('token', result.data.api_token);
      } else if(result.status=="0"){
        setEamilData((prev)=>{
          return {...prev, status:"error", msg:result.message}
        })
        // show error
      }
    })
    .catch( (error)=>{
        console.log(error)
        setEamilData((prev)=>{
          return {...prev, status:"error", msg:"Network error!"}
        })
      //  show error
    });
  }
  function changeEmailData(value, type){
    setEamilData((prev)=>{
      return {...prev, [type]:value}
    })
  }

  return(
    <div className="App">
      <Header/>
      <Navigation/>
      <div className="branch-bg">
        <Container>
          <Row>
            <Col>
              <div className="form-wrap">
              <h1 className="login-main">Request OTP</h1>
                <form id="warp-login" onSubmit={submitData}>
                  <div className="form-group">
                    <label className="login-hedi">Enter Email</label>
                    <input type="email" className="form-control login-mar" placeholder="Enter email" value={emailData.email} 
                                              onChange={(e)=>{changeEmailData(e.target.value, 'email')}} />
                  </div>
                  {emailData.type=="sendOpt" && 
                    <div className="form-group">
                      <label className="login-hedi">Enter OTP</label>
                      <input type="text" className="form-control login-mar" placeholder="Enter OTP"  value={emailData.opt} 
                                            onChange={(e)=>{changeEmailData(e.target.value, 'otp')}}/>
                    </div>
                  }
                  <Col md={12}>
                    <center>
                    {(emailData.status=="error" || emailData.status=="success") &&
                      <div style={emailData.status=="error"?{color:'red'}:{color:'green'}} >{emailData.msg}</div>
                    }
                    {emailData.status=="loading"?
                      <div style={{position:'relative', height:'80px'}}>
                        <Loader width="50px" height="50px" backgroundColor="transparent"/>
                      </div>: 
                      <button className="forget_btn" type="submit"> Submit </button> 
                    }
                    </center>
                  </Col>


                  <Row>
                    <Col md={12}> 
                      <div className="google_btn">
                        <Link to="/forget-passward">Forget Passward</Link>
                        <Link to="/signup">Create Account</Link>
                      </div>  
                    </Col>
                  </Row>

                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
};
export default RequsetOtp;



import React,{useState,useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { useSearchParams, useNavigate } from "react-router-dom";
import { apiBaseURL, apiURL, apiKey} from "../../../constant";

const ResetPassword = () => {
  
  const [queryString] = useSearchParams();
  const navigate = useNavigate();
  const [passwordData, setPasswordData] = useState({
    email:"",
    password:"",
    cnfPassword:"",
    status:"",
    msg:"",
    rt:"",
  })
  useEffect(() => {

    let token = queryString.get("t");
    //let retToken = queryString.get("rt");
    if(token==null){
      //navigate('/');
      return;
    }
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  function resetPassword(e){

    e.preventDefault();
    // if(passwordData.email==""){
    //   setPasswordData((prev)=>{
    //     return {...prev, status:"error", msg:"Email is required"}
    //   })  
    //   return;
    // } 
    
    if(passwordData.password==""){
      setPasswordData((prev)=>{
        return {...prev, status:"error", msg:"password is required"}
      })  
      return;
    } if(passwordData.cnfPassword==""){
      setPasswordData((prev)=>{
        return {...prev, status:"error", msg:"confirm password is required"}
      })  
      return;
    } if(passwordData.password!==passwordData.cnfPassword){
      setPasswordData((prev)=>{
        return {...prev, status:"error", msg:"password and confirm password did not match."}
      })  
      return;
    } 
    const sendData = {
      //"email": passwordData.email,
      "password" : passwordData.password,
      "t" : queryString.get("t")
    }
    setPasswordData((prev)=>{
      return {...prev, status:"loading", msg:""}
    }) 
    fetch(apiBaseURL+apiURL.resetPassword,{
      method:'POST',
      headers:{
        'rskey':apiKey
      },
      body:JSON.stringify( sendData )
    })
    .then( (result) => {
    result.json().then((data) => {
      if (data.status == "1") {
        setPasswordData((prev)=>{
          return {...prev, status:"success", msg:data.message}
        })
        setTimeout(()=>{
          navigate('/account/login')
        }, 2000)
      } else {
        setPasswordData((prev)=>{
          return {...prev, status:"error", msg:data.message}
        }) 
      }
    });
    }).catch( (err)=>{
        console.log(err);
        setPasswordData((prev)=>{
          return {...prev, status:"error", msg:"Network error!"}
        })
    })
  }

  function changePasswordData(value, type){
    setPasswordData((prev)=>{
      return {...prev, [type]:value}
    })
  }
  return(
    <div className="App">
      <Header/>
      <Navigation/>
      
      <div className="branch-bg">
        <Container>
          <Row>
            <Col>
              <div className="form-wrap">
              <h1 className="login-main">Reset Password</h1>
                <form id="warp-login" onSubmit={resetPassword}>
                  {/* <div className="form-group">
                    <label className="login-hedi">Enter Email</label>
                    <input type="email" className="form-control login-mar" placeholder="Enter email" value={passwordData.email} onChange={(e)=>{changePasswordData(e.target.value, "email")}} />
                  </div> */}
                  <div className="form-group">
                    <label className="login-hedi">Enter Password</label>
                    <input type="password" className="form-control login-mar" placeholder="Password" value={passwordData.password} onChange={(e)=>{changePasswordData(e.target.value, "password")}} />
                  </div>
                  <div className="form-group">
                    <label className="login-hedi">Confirm  Password</label>
                    <input type="password" className="form-control login-mar" placeholder="Confirm Password" value={passwordData.cnfPassword} onChange={(e)=>{changePasswordData(e.target.value, "cnfPassword")}} />
                  </div>
                  <Col md={12}>
                    <center>
                      {(passwordData.status=='error' || passwordData.status=="success") &&
                        <div className={`alert ${passwordData.status=='error'?'alert-danger':'alert-success'}`}>{passwordData.msg}</div> 
                      }
                      {passwordData.status=='loading' ?
                        <div disabled> <span className="spinner-grow spinner-grow-sm"></span> Loading... </div>:
                        <button className="forget_btn" type="submit"> Submit </button> 
                      }
                    </center>
                  </Col>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
};
export default ResetPassword;



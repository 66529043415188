import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import awardcetiN from '../images/caward/cetifaction-n.png';
import awardcetiN1 from '../images/caward/cetifaction-1.png';
import awardcircle1 from '../images/caward/book-production-first.png';
import awardcircle2 from '../images/caward/award for excellence-2020.png';
import awardcircle3 from '../images/caward/FIB-Book Production Award 2018 (First Price).png';
import awardcircle4 from '../images/caward/p-book-second-price.png';


import awardc from '../images/caward/FIB-Be-an-Artist-Art--&-Activity-Book-2020.png';
import awardc1 from '../images/caward/Educational-Publishers-Catalogue.png';
import awardc2 from '../images/caward/FIP-Sunhari-Dhup2020.png';  
import awardc3 from '../images/caward/FIP-Divayam--Sanskrit-Pathya-Pustakam-2017.png';

import awardverti1  from '../images/caward/sanskrit-vertical-1.png';
import awardverti2  from '../images/caward/sanskrit-vertical-2.png';
import awardvertij  from '../images/caward/sanskrit-vertical-j.png';
import awardverti6  from '../images/caward/sanskrit-vertical-6.png';
import awardverti4  from '../images/caward/sanskrit-vertical-4.png';
import awardverti5  from '../images/caward/sanskrit-vertical-5.png';


import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Helmet from 'react-helmet';

const AwardDetail = () => {
  const [seoData, setSeoData] = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  useEffect(() => { 
    getSeoData();
    AOS.init({
    offset: 300,
    duration:1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.faqSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"faqs"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
  <>
  {seoData.isDone &&
    <Helmet>
        <title>{seoData.title} </title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />

        <meta property="og:title" content={seoData.ogTags?.og_title} />
        <meta property="og:description" content={seoData.ogTags?.og_description} />
        <meta property="og:url" content={seoData.ogTags?.og_url} />
        <meta property="og:image" content={seoData.ogTags?.og_image_url} />

        <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
        <meta name="twitter:site" content="@rachnasagargrp"/>
        <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
        <meta name="twitter:" content={seoData.twitterTags?.twitter_image_url} />
        
    </Helmet>
  }
  <Header/>
  <Navigation/>
  <div className='branch-bg'>
    <Container fluid className='back_width'>
      <h1 className="invi-authors"> Awards & Recognitions Detail </h1>
    </Container>
  </div>
    <Container fluid className='back_width'>
      <div className='award-detail'>
        <Row className='display-center'>
          <Col md={3} sm={6}>
            <img className="img-thumbnail" src={awardcircle1}/>
            <p>Award for excellence in book production 2020</p>
          </Col>
          <Col md={3} sm={6}>
            <img className="img-thumbnail" src={awardcircle2}/>
            <p>Award for excellence in book production 2020</p>
          </Col>
          <Col md={3} sm={6}>
            <img className="img-thumbnail" src={awardcircle3}/>
            <p> Award for excellence in book production 2018 </p>
          </Col>
          <Col md={3} sm={6}>
            <img className="img-thumbnail" src={awardcircle4}/>
            <p> Award for excellence in book production 2018 </p>
          </Col>
          {/* <Col md={4} sm={6}>
            <img className="img-thumbnail" src={awardcetiN}/>
            <p> Central board of direct </p>
          </Col> */}
          <Col md={4} sm={6}>
            <img className="img-thumbnail" src={awardcetiN1}/>
            <p> Award for excellence in book production </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6}>
            <img className="img-thumbnail" src={awardc}/>
            <p> Be an Artist Art & Activity Book </p>
          </Col>
          <Col md={6} sm={6}>
            <img className="img-thumbnail" src={awardc1}/>
            <p> Educational Publishers Catalogue </p>
          </Col>
          <Col md={6} sm={6}>
            <img className="img-thumbnail" src={awardc2}/>
            <p> Sunhari Dhup </p>
            
          </Col>
          <Col md={6} sm={6}>
            <img className="img-thumbnail" src={awardc3}/>
            <p> Divayam Sanskrit Pathya Pustakam   </p>
          </Col>
        </Row>
        <Row>
        <Col md={4} sm={4}>
            <img className="img-thumbnail" src={awardverti1}/>
            <p> Divyam (Sankrit) </p>
        </Col>
        <Col md={4} sm={4}>
            <img className="img-thumbnail" src={awardverti2}/>
            <p> Zoom in science - 6 </p>
        </Col>
        <Col md={4} sm={4}>
            <img className="img-thumbnail" src={awardvertij}/>
        </Col>
        </Row>
        <Row>
        <Col md={4} sm={4}>
            <img className="img-thumbnail" src={awardverti6}/>
        </Col>
        <Col md={4} sm={4}>
            <img className="img-thumbnail" src={awardverti4}/>
        </Col>
        <Col md={4} sm={4}>
            <img className="img-thumbnail" src={awardverti5}/>
        </Col>
        </Row>
      </div>
    </Container>
  <Footer/>
  
  </>
  );
};
export default AwardDetail;
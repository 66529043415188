import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import game from "../images/game-banner.jpg";

import Collapse from 'react-bootstrap/Collapse'
import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from '../../../Loader'

const Authors = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [autherList, setAutherList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
useEffect(() => {
  getSeoData();
  getAuthors();
  AOS.init({
    offset: 300,
    duration: 1000,
  });
}, []);
async function getSeoData(){
  try {
    let response = await  fetch(apiBaseURL+apiURL.renownedAuthorsSeo,{
        method:'POST',
        headers:{
            'rskey':apiKey,
        },
        body:JSON.stringify({"seo":"ourrenownedauthors"})
      });
    let result = await response.json();
    if(result.status=="1"){
      setSeoData({
        title:result.Meta_Title,
        description:result.Meta_Description,
        keywords:result.Meta_Keyword,
        ogTags:result.Og_Tag,
        twitterTags:result.Twitter_Tag,
        isDone:true,
      })
    } else {
      console.log(result.message)
     
    }

  } catch (error) {
    console.log(error)
  }
}
async function getAuthors(){
  if(!loading){
    setLoading(true)
  }
  try {
    let response = await  fetch(apiBaseURL+apiURL.authors,{
        method:'GET',
        headers:{
            'rskey':apiKey,
        },
      });
    let result = await response.json();
    // console.log(result);
    setLoading(false);
    if(result.status=="1"){
      setAutherList(result.authors.map((item)=>({...item, readMore:false})))
    } else {
      console.log(result.message)
     
    }

  } catch (error) {
    setLoading(false);
    console.log(error)
  }
}

function toggleReadMore(ind, val){

  setAutherList((prev)=>{
    let oldData = [...prev];
    if(oldData[ind]){
      oldData[ind].readMore = val;
    }
    // console.log(oldData[ind].readMore);
    // console.log(oldData[ind]);
    return oldData
  })
}
  return(
    <>
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
      <div className="branch-bg">
        <Container>
          <Row>
            <img src={game} />
          </Row>
        </Container>
      </div>
      <Container>
      
        {loading?
        <div style={{position:'relative', height:'200px'}}>
          <Loader width="80px" height="80px" backgroundColor="transparent"/>
        </div>:
        <>
         <div className="hedi_auther">Our Renowned Authors</div>
          {autherList.map((item, index)=>{
            return(
            <div className="main_authors" key={item.id} >
              <div className="row display-center">
                <div className="col-md-2 col-6">
                  <div className="img-auther">
                    <figure className="effect-apollo">
                       <img src={item.image} />
                    <figcaption> </figcaption>
                    </figure>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="content"> <h1 className="name-auther"> <b> {item.Title} </b> </h1>
                    <h2 className="name-auther" dangerouslySetInnerHTML={{__html:item.qualification_desp}} />
                    <span className='inlinep' dangerouslySetInnerHTML={{__html:item.desp}}/>
                     <Collapse in={item.readMore}> 
                      <span dangerouslySetInnerHTML={{__html:item.readmore_desp}}/>
                        {/* <p>  */}
                          {/* He has been providing video lectures to some renowned publication houses. */}
                        {/* </p> */}
                    </Collapse>
                    <span className='collapse-auth' onClick={() => toggleReadMore(index, !item.readMore)} aria-controls="one"aria-expanded={open}>{item.readMore?"Read Less":"Read More"}</span>
                  </div>
                </div>
              </div>
            </div>
            )
          })}
        </>
    }
        {/* Column 1 */}
        {/* <div className="main_authors">
          <div className="hedi_auther">Our Renowned Authors</div>
          <div className="row">
            <div className="col-md-3">
              <div className="img-auther"> <img src={deepak} /></div>
            </div>
            <div className="col-md-9">
              <div className="content"><h1 className="name-auther">Deepak Gupta !</h1>
                <h2 className="name-auther">(B.Com, CA Inter from ICAI)</h2>
                Deepak Gupta is a renowned author of the famous book ‘Together with Accountancy Study Material for Class 12’. He is the Founder and Head Faculty of STG Commerce Classes.
                With an overall teaching/writing experience of more than 24 years in Accountancy and Economics, he has served as Senior Career Councilor in Skill Score and also worked
                as Head Faculty of Commerce in CLA. <br/>
                Deepak Gupta has been an imperative part of several successful workshops for students and faculty. 
                <span className='collapse-auth' onClick={() => setOpen(!open)} aria-controls="one"aria-expanded={open}>Read More</span>
                <Collapse in={open}> 
                  <div>
                    <p> He has been providing video
                      lectures to some renowned publication houses. His contribution towards education and teaching has been incredible. He has been helping students in this pandemic time
                      by providing online lectures and motivational counselling. <br/>
                      <br/>
                      <b>His Principals of Teaching</b><br/>
                      <i className="fa icon-fa"></i>Education is learning, not mugging.<br/>
                      <i className="fa icon-fa"></i>Education is understanding, not spoon feeding.<br/>
                      <i className="fa icon-fa"></i>Education is a long process, not a matter of learning few hours before exams. </p>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div> */}
      </Container>
      <Footer/>
    </>
  );
};
export default Authors;



import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import Loader from '../../../Loader';
import { apiURL, apiBaseURL, apiKey } from '../../../constant';

const OrderFrom = () => {

  const [formData, setFormData] = useState({
    status:"",
    list:[],
    msg:"",
  })
  useEffect(() => { 
    getFormData()
    AOS.init({
    offset: 300,
    duration:1000,
    });
  }, []);

  function getFormData(){
    setFormData((prev)=>{

      return {
        ...prev,
        status:"loading"
      }
    })
    fetch(apiBaseURL+apiURL.orderForm,{
      method:'POST',
      headers:{
        'rskey' : 'rspl',
        
      },
      body:JSON.stringify({ "action":"orderform"})
    } )
      .then( response => response.json() )
      .then( (result) => {
        console.log(result)
        
        if(result.status=="1"){
          setFormData((prev)=>{

            return {
              ...prev,
              list:result.OrderForm_List,
              status:""
            }
          })
        } else {
          setFormData((prev)=>{

            return {
              ...prev,
              status:""
            }
          })
        }
      })
      .catch( (error)=>{
          console.log(error)
          setFormData((prev)=>{
            return {
              ...prev,
              status:""
            }
          })
      } );
  }

  return(
  <>
  <Header/>
  <Navigation/>
  <div className='branch-bg'>
    <Container>
    <h1 className="invi-authors"> Order Form </h1>
      <div className='order-from'>
        <Row className='display-center'>
        {formData.status=="loading"?
        <div style={{position:'relative', height:'200px'}}>
            <Loader width="80px" height="80px" backgroundColor="transparent"/>
        </div>:
        <>
        {formData.list.map((item, index)=>{
          let clsName = "togrther-box";
          if(index%2!==0){
            clsName = "primary-box"
          }
          return(
          <Col md={4} key={index}> 
            <div className={clsName}>
              <h4> <Link to={item.slug}> {item.title} </Link> </h4>  
                <a className='togrther-btn' href={item.pdfUrl} target="_blank">
                   <i className="fa fa-download" aria-hidden="true"></i> &nbsp; Download
                </a>          
            </div>
          </Col>
          )
        })}
        </>
        }
          
          {/* <Col md={4}> 
            <div className='primary-box'>
              <h4>Primary Order Form </h4>
                <div className='primary-btn'>
                   <i className="fa fa-download" aria-hidden="true"></i> &nbsp; Download
                </div>          
            </div>
          </Col> */}
      </Row>
      </div>
    </Container>
    <br/> 
  </div>
  <Footer/>
  </>
  );
};
export default OrderFrom;
import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import Collapse from 'react-bootstrap/Collapse';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import Loader from '../../../Loader';
import Helmet from 'react-helmet';
const CareerDetail = () => {
  const [open, setOpen] = useState(false);
  const [sign, setSign] = useState(false);
  const [careerDetail, setCareerDetail] = useState({});
  const [resData, setResData] = useState({
    status:"loading"
  });
  const [formOpen,setFormOpen] = useState({
    status:"",
    applyData:{
      title:"",
      msg:"",
      resume:"",
      status:"",
      errMsg:""
    },
    friendData:{
      yourName:"",
      yourEamil:"",
      yourPhone:"",
      friendName:"",
      friendEmail:"",
      friendPhone:"",
      status:"",
      errMsg:""
    },
  });
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  const params = useParams();
  // const location = useLocation();
  useEffect(() => {
    getCareerDetail(); 
    getSeoData();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.careerDetailSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"careerdetalsa"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  function getCareerDetail(){
    fetch(apiBaseURL+apiURL.careersDetail,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
      },
      body:JSON.stringify({"career_id":params.careerId})
    } )
      .then( response => response.json() )
      .then( (result) => {
        console.log(result)
        if(result.status=="1"){
          setCareerDetail(result.career_detail)
        } else {
         
        }
      })
      .catch( (error)=>{
        console.log(error)
      
      })
      .finally(()=>{
        setResData({
          status:""
        })
      });
  }

  function openForm(val){
    setFormOpen((prev)=>{
      
      return {...prev, status:val}
    })
  }
  function changeFormData(value, mainType, subType){

    setFormOpen((prev)=>{
      let mainTypeObj = {...prev[mainType]};
      mainTypeObj[subType] = value;
      // console.log(mainTypeObj)
      return {...prev, [mainType]:mainTypeObj}
    })
  }
  function applyJob(){


    if(formOpen.applyData.title==""){
      setFormOpen((prev)=>{
        return {...prev, applyData:{...prev.applyData, status:'error', errMsg:"Please fill the title"}}
      })
      return;
    } else if(formOpen.applyData.resume==""){
      setFormOpen((prev)=>{
        return {...prev, applyData:{...prev.applyData, status:'error', errMsg:"Please upload your resume."}}
      })
      return;
    }
    setFormOpen((prev)=>{
      return {...prev, applyData:{...prev.applyData, status:'loading', errMsg:""}}
    })
    const formData = new FormData();
    formData.append("job_title", formOpen.applyData.title);
    formData.append("message", formOpen.applyData.msg);
    formData.append("resume", formOpen.applyData.resume);
    fetch(apiBaseURL+apiURL.applyJob,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
      },
      body:formData
    } )
      .then( response => response.json() )
      .then( (result) => {
        console.log(result)
        if(result.status=="1"){
          setFormOpen((prev)=>{
            return {...prev, 
              applyData:{
                ...prev.applyData, 
                title:"",
                msg:"",
                resume:"",
                status:'success', 
                errMsg:"Successfully submitted!"
              }}
          })
          document.getElementById("file").value = "";
        } else {
          setFormOpen((prev)=>{
            return {...prev, applyData:{...prev.applyData, status:'error', errMsg:result.message}}
          })
        }
      })
      .catch( (error)=>{
        console.log(error)
        setFormOpen((prev)=>{
          return {...prev, applyData:{...prev.applyData, status:'error', errMsg:"network error! try again."}}
        })
      })
      .finally(()=>{
        setResData({
          status:""
        })
      });
  }

  function recommendAFriend(){
    if(formOpen.friendData.yourName==""){
      setFormOpen((prev)=>{
        return {...prev, friendData:{...prev.friendData, status:'error', errMsg:"Please fill your name"}}
      })
      return;
    } else if(formOpen.friendData.yourEamil==""){
      setFormOpen((prev)=>{
        return {...prev, friendData:{...prev.friendData, status:'error', errMsg:"Please fill your email"}}
      })
      return;
    } else if(formOpen.friendData.yourPhone==""){
      setFormOpen((prev)=>{
        return {...prev, friendData:{...prev.friendData, status:'error', errMsg:"Please fill your phone"}}
      })
      return;
    }else if(formOpen.friendData.friendName==""){
      setFormOpen((prev)=>{
        return {...prev, friendData:{...prev.friendData, status:'error', errMsg:"Please fill your friends name"}}
      })
      return;
    }else if(formOpen.friendData.friendEmail==""){
      setFormOpen((prev)=>{
        return {...prev, friendData:{...prev.friendData, status:'error', errMsg:"Please fill your friend email"}}
      })
      return;
    }else if(formOpen.friendData.friendPhone==""){
      setFormOpen((prev)=>{
        return {...prev, friendData:{...prev.friendData, status:'error', errMsg:"Please fill your friend phone"}}
      })
      return;
    }

    setFormOpen((prev)=>{
      return {...prev, friendData:{...prev.friendData, status:'loading', errMsg:""}}
    })

    const sendData = {
      "your_name": formOpen.friendData.yourName,
      "your_email": formOpen.friendData.yourEamil,
      "your_phone": formOpen.friendData.yourPhone,
      "friend_name": formOpen.friendData.friendName,
      "friend_email": formOpen.friendData.friendEmail,
      "friend_phone": formOpen.friendData.friendPhone,
      "career_id": params.careerId
    }

    fetch(apiBaseURL+apiURL.recommendAFriend,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
      },
      body:JSON.stringify(sendData)
    })
    .then( response => response.json() )
    .then( (result) => {
      console.log(result)
      if(result.status=="1"){
        setFormOpen((prev)=>{
          return {...prev, 
            friendData:{
              ...prev.friendData, 
              yourName:"",
              yourEamil:"",
              yourPhone:"",
              friendName:"",
              friendEmail:"",
              friendPhone:"",
              status:'success', 
              errMsg:"Successfully submitted!"
            }}
        })
       
      } else {
        setFormOpen((prev)=>{
          return {...prev, friendData:{...prev.friendData, status:'error', errMsg:result.message}}
        })
      }
    })
    .catch( (error)=>{
      console.log(error)
      setFormOpen((prev)=>{
        return {...prev, friendData:{...prev.friendData, status:'error', errMsg:"network error! try again."}}
      })
    })
    .finally(()=>{
      setResData({
        status:""
      })
    });

  }
  return(
    <>
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
        {resData.status=="loading"?
           <div style={{position:'relative', height:'80px'}}>
           <Loader width="50px" height="50px" backgroundColor="transparent"/>
         </div>:
         <>
        <div className='branch-bg'>
          <Container>
            <center><h1 className="career-detail-hedi">{careerDetail.title} </h1></center>
            <div className='career-detail-bg'>
              <Row>
                <Col md={4}>
                  <div className="car-main-hedi"> 
                    <i className="fa fa-briefcase" aria-hidden="true"></i> &nbsp;
                    <b>Experience</b> : {careerDetail.min_exp} - {careerDetail.max_exp} Years
                  </div>
                </Col>
                <Col md={4}>
                  <div className="car-main-hedi"> 
                  <i className="fa fa-inr" aria-hidden="true"></i>  &nbsp;
                  <b>Salary</b> : {careerDetail.min_ctc} - {careerDetail.max_ctc} Lac P.A.
                  </div>
                </Col>
                <Col md={4}>
                  <div className="car-main-hedi"> 
                  <i className="fa fa-location-arrow" aria-hidden="true"></i>  &nbsp;
                  <b>Location</b> : {careerDetail.location}
                  </div>
                </Col>
                <Col md={8}>
                  <div className="car-main-hedi"> 
                  <i className="fa fa-cogs" aria-hidden="true"></i>  &nbsp;
                  <b>Skill Set</b> : {careerDetail.skills}
                  </div>
                </Col>
                <Col md={4}>
                  <div className="car-main-hedi"> 
                  <i className="fa fa-calendar" aria-hidden="true"></i>  &nbsp;
                  <b>Job Post Date</b> : 04-12-2021
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
          <Container>
            <Row> 
              <Col md={12}> <h2 className='discri-career'> Job Description : </h2> </Col>
                <div dangerouslySetInnerHTML={{__html:careerDetail.description}}/>
                
              <div>
                <button className="detailc-btn" type="button" onClick={() => openForm("apply")} aria-controls="example-collapse-text1"  > Apply Now </button> 
                <button className="detailc-btn" type="button" onClick={() => openForm("friend")} aria-controls="example-collapse-text"  > Recommend To Friend </button>
                
                <Collapse in={formOpen.status=="apply"}>
                    <div className='recommed example-collapse-text1'>
                      <form>
                      <center> <h4 className='discri-career pb-2'>Application Form</h4> </center>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="login-hedi pb-1">Job Title *</label>
                              <input type="job-title" className="form-control" id="" placeholder="" value={formOpen.applyData.title} onChange={(e)=>{changeFormData(e.target.value, 'applyData', 'title')}}/>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="login-hedi pb-1">Message</label>
                              <textarea className="form-control" rows="1" value={formOpen.applyData.msg} onChange={(e)=>{changeFormData(e.target.value, 'applyData', 'msg')}}></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="login-hedi pb-1">Resume *</label>
                              <input type="file" accept="application/pdf" className="form-control file" id="file" onChange={(e)=>{changeFormData(e.target.files[0], 'applyData', 'resume')}}/>
                              <div className='font-small mt-1'> Please Upload Only PDF file With MAximum 10Mb in Size. </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <center> 
                              {(formOpen.applyData.status=="error" || formOpen.applyData.status=="success") &&
                                <div style={formOpen.applyData.status=="error"?{color:'red'}:{color:'green'}} >{formOpen.applyData.errMsg}</div>
                              }
                              {formOpen.applyData.status=="loading"?
                                <div style={{position:'relative', height:'80px'}}>
                                 <Loader width="50px" height="50px" backgroundColor="transparent"/>
                               </div>: 
                                <button className="forget_btn" type="button" onClick={applyJob}> Submit </button> 
                              }
                            </center>
                          </div>
                        </div>
                      </form> 
                    </div>
                </Collapse>

                <Collapse in={formOpen.status=="friend"}>
                  <div className='recommed example-collapse-text'>
                    <form>
                    <center> <h4 className='discri-career pb-2'>Recommend To Friend</h4> </center>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="login-hedi pb-1">Your Name *</label>
                            <input type="name" className="form-control" id="" placeholder=""  value={formOpen.friendData.yourName} onChange={(e)=>{changeFormData(e.target.value, 'friendData', 'yourName')}}/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="login-hedi pb-1">Your Email *</label>
                            <input type="email" className="form-control" id="" placeholder=""  value={formOpen.friendData.yourEamil} onChange={(e)=>{changeFormData(e.target.value, 'friendData', 'yourEamil')}}/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="login-hedi pb-1">Your Phone *</label>
                            <input type="phone" className="form-control" id="" placeholder=""  value={formOpen.friendData.yourPhone} onChange={(e)=>{changeFormData(e.target.value, 'friendData', 'yourPhone')}}/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="login-hedi pb-1">Friend Name *</label>
                            <input type="name" className="form-control" id="" placeholder=""  value={formOpen.friendData.friendName} onChange={(e)=>{changeFormData(e.target.value, 'friendData', 'friendName')}}/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="login-hedi pb-1">Friend Email *</label>
                            <input type="email" className="form-control" id="" placeholder=""  value={formOpen.friendData.friendEmail} onChange={(e)=>{changeFormData(e.target.value, 'friendData', 'friendEmail')}}/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="login-hedi pb-1">Friend Phone *</label>
                            <input type="phone" className="form-control" id="" placeholder=""  value={formOpen.friendData.friendPhone} onChange={(e)=>{changeFormData(e.target.value, 'friendData', 'friendPhone')}}/>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <center> 
                          {(formOpen.friendData.status=="error" || formOpen.friendData.status=="success") &&
                            <div style={formOpen.friendData.status=="error"?{color:'red'}:{color:'green'}} >{formOpen.friendData.errMsg}</div>
                          }
                          {formOpen.friendData.status=="loading"?
                            <div style={{position:'relative', height:'80px'}}>
                              <Loader width="50px" height="50px" backgroundColor="transparent"/>
                            </div>: 
                            <button className="forget_btn" type="button"  onClick={recommendAFriend}> Submit </button> 
                          }
                          </center>
                        </div>
                      </div>
                    </form>
                  </div>
                </Collapse>
              </div>
              <hr/>
              </Row>
          </Container>
         </>
        }
      <Footer/>
    </>
  );
};
export default CareerDetail;

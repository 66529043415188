import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import shoimg from "../images/s-sho.png";
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../../Loader';
import { apiURL, apiBaseURL, apiKey, BOOK_VARIENTS } from '../../../constant';
import { Contaxt } from '../../../Store';
import Error from '../Error/Error';
import Helmet from "react-helmet";

const OrderDetail = () => {
  const [addNoteData, setAddNoteData] = useState({
    status:false,
    text:'',
    isLoading:false,
    msg:""
  });

  const[promoCode, setPromoCode]=useState({
    status:false,
    isLoading:false,
    code:""
  });
 
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })

  const [orderData, setOrderData] = useState({
      "totalPrice":"",
      "discount":"",
      "totalAfterDis":"",
      "subTotal":"",
      "shippingCharge":"",
      "couponDiscount":"",
      "grandTotal":"",
      "couponStatus":0,
      "items":[],
      "isLoading":true,
  });
  const [error, setError] = useState({status:false, msg:"", fun:undefined, canName:undefined});
  const navigate = useNavigate();
  const {userData, resetUser, offlineCartData, updateItemOnCart, removeItemFromCart, setCartItemNum} = useContext(Contaxt);

  // useEffect(()=>{
  //   if(!userData.loginStatus){
  //     navigate('/login')
  //   } 
  // })

  useEffect(()=>{
    if(!userData.loginStatus){
      console.log("dskfls sdfsdf sdf")
      setCartDataFromOffline();
    }
   
  }, [offlineCartData, userData.loginStatus])
  
  useEffect(() => { 
    if(!userData.loginStatus){
    
      // setCartDataFromOffline();
    } else {
      getOrderDetails();
    }
    getSeoData();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.orderDetailsSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"orderdetails"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
      }

    } catch (error) {
      console.log(error)
    }
  }
  function canclePopup(){

    setError({status:false, msg:"", fun:undefined, canName:undefined})
  }
  async function getOrderDetails(){

    if(!userData.loginStatus){
      // setPopupCart({status:true, addingToCart:false})
      // let url = btoa(`bookdetail/${params.bookId}`); 
      navigate('/account/login')
    } else {
       
        try {
          canclePopup();
          setOrderData((prev)=>{
            return {...prev, isLoading:true};
          })
          const response = await fetch(apiBaseURL+apiURL.orderDetail, {
            method:"POST",
            headers:{
              'rskey' : apiKey,
              'utoken': userData.token
            }
          });
          const result = await response.json();
          console.log(result)
          if(result.status=="1"){
                setOrderData({
                    "totalPrice":result.Total_MRP,
                    "discount":result.Discount,
                    "totalAfterDis":result.Total,
                    "subTotal":result.SubTotal,
                    "shippingCharge":result.Shipping_Charge,
                    "couponDiscount":result.Coupon_Discount,
                    "grandTotal":result.Grand_Total,
                    "couponStatus":result.Coupon_Status,
                    "items":result.Order_Items?.map(item=>({...item, isUpdating:false})),
                    "isLoading":false,
                    "isEmpty":false,
                })
          } else {
            if(result.Message=="Cart Empty"){
                setOrderData((prev)=>{
                    return {
                        ...prev,
                        isLoading:false,
                        isEmpty:true,
                    }
                })

            } else if(result.message=="Invalid TOKEN"){
                resetUser()
            } else {
              setOrderData((prev)=>{
                return {...prev, isLoading:false};
              })
              setError((prev)=>{
                return {...prev, status:true, msg:result.message, fun:getOrderDetails}
              })
            }
          }
        } catch (error) {

            console.log(error)
            setError((prev)=>{
              return {...prev, status:true, msg:"Network Error!", fun:getOrderDetails}
            })
            //show error
        }   
     
    }

  }

  function setCartDataFromOffline(){
    setOrderData({
      "totalPrice":offlineCartData.totalMrp,
      "discount":offlineCartData.totalDiscount,
      "totalAfterDis":offlineCartData.totalAfterDiscount,
      "subTotal":offlineCartData.totalAfterDiscount,
      "shippingCharge":offlineCartData.shippingCharge,
      "couponDiscount":0,
      "grandTotal":offlineCartData.grandTotal,
      "couponStatus":0,
      "items":offlineCartData.items?.map(item=>({...item, isUpdating:false})),
      "isLoading":false,
      "isEmpty":offlineCartData.items.length==0?true:false,
    })
  }
 
function updateProduct(bookId, bookType, action, index){


  if(!userData.loginStatus){
    // setPopupCart({status:true, addingToCart:false})
    // let url = btoa(`bookdetail/${params.bookId}`); 
    // navigate('/login')
    updateItemOnCart(bookId, bookType, action)
    return;
  }
  setOrderData((prev)=>{
    let items = [...prev.items];

    if(items[index]!=undefined && items[index].ProductId==bookId){
      items[index].isUpdating = true;
    }
    return {...prev, items:items}
  })
  let sendData = {
    bookId: bookId,
    product_type: bookType,
    action: action
  }
  fetch(apiBaseURL+apiURL.updateProduct,{
    method:'POST',
    headers:{
      'rskey' : 'rspl',
      'utoken': userData.token,

    },
    body:JSON.stringify(sendData) 
  } )
    .then( response => response.json() )
    .then( (result) => {
      console.log(result)
      
      if(result.status=="1"){
        
        setOrderData((prev)=>{
          return {
            ...prev, 
            items:result.cart_items?.map(item=>({...item, isUpdating:false})),
            totalPrice:result.Total_MRP,
            discount:result.Total_Discount,
            totalAfterDis:result.Total,
            subTotal:result.SubTotal,
            shippingCharge:result.Shipping_Charge,
            couponStatus:result.Coupon_Status,
            couponDiscount:result.Coupon_Discount,
            grandTotal:result.Grand_Total
          }
         
        });
    
      } else if(result.status=="0") {
        // show error

      }
    })
    .catch( (error)=>{
       console.log(error)
      //  show error
    } );
 
} 
function removeProduct(bookId, bookType, index){
  
  if(!userData.loginStatus){
    // setPopupCart({status:true, addingToCart:false})
    // let url = btoa(`bookdetail/${params.bookId}`); 
    // navigate('/login')
    removeItemFromCart(bookId, bookType)
    return;
  }
  setOrderData((prev)=>{
    let items = [...prev.items];

    if(items[index]!=undefined && items[index].ProductId==bookId){
      items[index].isUpdating = true;
    }
    return {...prev, items:items}
  })
  const sendData = {
    bookId: bookId,
    product_type: bookType,
  }
  fetch(apiBaseURL+apiURL.deleteFromCart,{
    method:'POST',
    headers:{
      'rskey' : 'rspl',
      'utoken': userData.token,

    },
    body:JSON.stringify(sendData) 
  })
    .then( response => response.json() )
    .then( (result) => {
      console.log(result)
      
      if(result.status=="1"){
        setOrderData((prev)=>{
          return {
            ...prev, 
            items:result.cart_items?.map(item=>({...item, isUpdating:false})),
            totalPrice:result.Total_MRP,
            discount:result.Total_Discount,
            totalAfterDis:result.Total,
            subTotal:result.SubTotal,
            shippingCharge:result.Shipping_Charge,
            couponStatus:result.Coupon_Status,
            couponDiscount:result.Coupon_Discount,
            grandTotal:result.Grand_Total
          }
        });
        setCartItemNum(result.Total_Items)
        
      } else if(result.status=="0"){
        if(result.Message=="Cart Empty"){
            setOrderData((prev)=>{
            return {
              ...prev, 
              items:[],
              totalPrice:0,
              discount:0,
              totalAfterDis:0,
              subtotal:0,
              shippingCharge:0,
              couponDiscount:0,
              isEmpty:true,
            }
          });
          setCartItemNum(0)
        }
        // show error
      }
    })
    .catch( (error)=>{
       console.log(error)
      //  show error
    });
 
} 

function togglePromoCode(value){
  setPromoCode((prev)=>{
    return {...prev, status:value}
  })
}
function toggleAddNote(value){
  setAddNoteData((prev)=>{
    return {...prev, status:value}
  })
}
function setNoteValue(value){
  setAddNoteData((prev)=>{
    return {...prev, text:value}
  })
}
function setPromoValue(value){
  setPromoCode((prev)=>{
    return {...prev, code:value}
  })
}
async function applyPromoCode(){

  if(promoCode.code==""){
    setError((prev)=>{
      return {...prev, status:true, msg:"Please Enter the Promo Code First.", fun:undefined, canName:"OK"}
    })
    return
  }
  if(!userData.loginStatus){
    navigate('/account/login')
  } else {
      try {
        canclePopup();
        setPromoCode((prev)=>{
          return {...prev, isLoading:true}
        })
        const response = await fetch(apiBaseURL+apiURL.coupon, {
          method:"POST",
          headers:{
            'rskey' : apiKey,
            'utoken': userData.token
          },
          body:JSON.stringify({"coupon":promoCode.code})
        });
        const result = await response.json();
        console.log(result)
        setPromoCode((prev)=>{
          return {...prev, isLoading:false}
        })
        if(result.status=="1"){
          // setOrderData({
          //     "totalPrice":result.Total_MRP,
          //     "discount":result.Discount,
          //     "totalAfterDis":result.Total,
          //     "subTotal":result.SubTotal,
          //     "shippingCharge":result.Shipping_Charge,
          //     "couponDiscount":result.Coupon_Discount,
          //     "grandTotal":result.Grand_Total,
          //     "items":result.Order_Items?.map(item=>({...item, isUpdating:false})),
          //     "isLoading":false,
          //     "isEmpty":false,
          // })
          setOrderData((prev)=>{

            return {
              ...prev,
              "totalPrice":result.Total_MRP,
              "discount":result.Discount,
              "totalAfterDis":result.Total,
              "subTotal":result.SubTotal,
              "shippingCharge":result.Shipping_Charge,
              "couponDiscount":result.Coupon_Discount,
              "couponStatus":result.Coupon_Status,
              "grandTotal":result.Grand_Total,
            }
          })
        } else {
         if(result.message=="Invalid TOKEN"){
              resetUser()
          } else {
          
            
            setError((prev)=>{
              return {...prev, status:true, msg:result.message, fun:undefined, canName:"OK"}
            })
          }
        }
      } catch (error) {

          console.log(error)
          setPromoCode((prev)=>{
            return {...prev, isLoading:false}
          })
          setError((prev)=>{
            return {...prev, status:true, msg:"Network Error!", fun:undefined, canName:"OK"}
          })
          //show error
      }   
   
  }
}
async function sendAddNote(){

  if(addNoteData.text==""){
    setError((prev)=>{
      return {...prev, status:true, msg:"Please write somthing on add note.", fun:undefined, canName:"OK"}
    })
    return
  }
  if(!userData.loginStatus){
    navigate('/account/login')
  } else {

      try {
        canclePopup();
        setAddNoteData((prev)=>{
          return {...prev, isLoading:true, msg:""}
        })
        const response = await fetch(apiBaseURL+apiURL.addNote, {
          method:"POST",
          headers:{
            'rskey' : apiKey,
            'utoken': userData.token
          },
          body:JSON.stringify({"AddNote":addNoteData.text})
        });
        const result = await response.json();
        // console.log(result)
       
        if(result.status=="1"){
          setAddNoteData((prev)=>{
            return {...prev, isLoading:false, msg:"successfully submitted!", text:""}
          })
        } else {
          setAddNoteData((prev)=>{
            return {...prev, isLoading:false,  msg:""}
          })
         if(result.message=="Invalid TOKEN"){
            resetUser()
          } else {
            setError((prev)=>{
              return {...prev, status:true, msg:result.message, fun:undefined, canName:"OK"}
            })

          }
        }
      } catch (error) {

          console.log(error)
          setAddNoteData((prev)=>{
            return {...prev, isLoading:false, msg:""}
          })
          setError((prev)=>{
            return {...prev, status:true, msg:"Network Error!", fun:undefined, canName:"OK"}
          })
          //show error
      }   
   
  }
}
  
  return(
    
    <div className="App">
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
        <div className='cart-bg'>
          <Container fluid className='back_width'> 
            <div className='mycart'>
            <center> <div className='cart-mhedi'>Order detail</div> </center>
              <Row>
                <Col md={8} sm={12}>
                  <h1>My cart</h1>
                  {orderData.isLoading ?
                   <div style={{position:'relative', height:'200px'}}>
                        <Loader width="80px" height="80px" backgroundColor="transparent"/>
                    </div>:
                    <>
                      {orderData.isEmpty && 
                        <div>Your Cart is Empty!</div>
                      }
                      {orderData.items.map((item, index)=>{
                        // console.log(item);
                        // console.log("ssdf")
                          return(
                              <React.Fragment key={item.ProductId+item.Product_Type} >
                                <Row >
                                  <Col md={2} sm={2} xs={4}>
                                  <img className="img-thumbnail" src={item.Image} />
                                  </Col> 
                                  <Col md={10} sm={8} xs={8}>
                                  <h3 className="copy-walls">{item.Product_Title}</h3>
                                  <Row> 
                                    <Col md={6} sm={6} xs={12}>
                                    <div style={{fontSize: "16px"}}>
                                        {item.Product_ISBN!==""&&
                                        	<div>ISBN : {item.Product_ISBN} </div>
                                      	}
                                        {/* <div>ISBN : {item.Product_ISBN} </div> */}
                                        <div>Class : {item.Product_Class}</div>
                                        <div>Subject : {item.Product_Subject}</div>
                                        <div>Product Type : {item.Product_Type_Title}</div>
                                        <div>Quantity : {item.Quantity}</div>
                                        <div>
                                        <span className="booPrice">₹ {item.Total_Price}</span> 
                                        <span className='order-rupess'> &nbsp; ₹ </span> <span className="linethrow">{item.Total_MRP}</span> 
                                        </div>
                                        
                                        {item.Product_Discount_Percent!="0"&&
                                        <div><b style={{color:"#717171"}}> You save ₹ {item.Total_Discount}</b> <span className='bookPer'> ({item.Product_Discount_Percent}% off) </span></div>
                                        }
                                      </div>
                                    </Col>
                                      <Col md={2} sm={6} xs={6}>
                                        <div className={(item.Product_Type!=BOOK_VARIENTS.eBook && item.Product_Type!=BOOK_VARIENTS.ieBook)?"right-number":""}>
                                        {item.isUpdating?
                                            <div style={{position:'relative', height:"40px", width:'100%'}}>
                                                <Loader width='20px' height='20px'/>
                                            </div>:
                                            <>
                                            {item.Product_Type!=BOOK_VARIENTS.eBook && item.Product_Type!=BOOK_VARIENTS.ieBook&&
                                            <>
                                              <span className="minus-right" onClick={()=>{updateProduct(item.ProductId, item.Product_Type, 'remove', index)}}>-</span>
                                              <input type="submit" value={item.Quantity} />
                                              <span className="plus-right" onClick={()=>{updateProduct(item.ProductId, item.Product_Type, 'add', index)}}>+</span>
                                            </>
                                            }
                                            </>
                                        }
                                        </div>
                                      </Col>
                                    
                                    <Col md={2} sm={4} xs={6}>
                                      <div className="multiple">
                                      {/* <h4><b>₹ {item.Product_MRP}</b></h4> 
                                      <h4>₹ {item.Total_Price}</h4> */}
                                      {!item.isUpdating &&
                                        <div className="multi_empty" onClick={()=>{removeProduct(item.ProductId, item.Product_Type, index)}}><i className="fa fa-trash" aria-hidden="true" ></i></div>
                                      }
                                      </div>
                                    </Col>
                                    
                                  </Row>
                                  
                                  </Col>
                                </Row>
                                {index!==orderData.items.length-1 && 
                                <hr/>
                                }
                              </React.Fragment>
                          )
                      })}
                    </>
                  }
                  
                </Col>

                {!orderData.isLoading && !orderData.isEmpty &&
                
                <Col md={4} sm={12}> 
                    <h2>Order summary </h2> 
                    <Row>
                      <Col md={6} sm={6} xs={6} className="right-login mt-3"> 
                        <div className='order-cal'> <b>Total MRP</b> </div>
                        <div className='order-cal'> Discount (-) </div>
                      </Col>
                      <Col md={6} sm={6} xs={6} className="mt-3"> 
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {orderData.totalPrice}</b></span> </div>
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {orderData.discount}</b></span> </div>
                      </Col>
                      <Col md={12}> <div className='discount-border'></div> </Col>
                      <Col md={6} sm={6} xs={6} className="right-login"> 
                        <div className='order-cal'> <b>Total</b> </div>
                        {orderData.couponStatus=="1"&&
                          <div className='order-cal'> Coupon Discount (-) </div>
                        }
                      </Col>
                      <Col md={6} sm={6} xs={6}> 
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {orderData.totalAfterDis}</b></span> </div>
                      {orderData.couponStatus=="1"&&
                        <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {orderData.couponDiscount}</b></span> </div>
                      }
                      </Col>
                      <Col md={12}> <div className='discount-border'></div> </Col>
                      <Col md={6} sm={6} xs={6} className="right-login"> 
                        <div className='order-cal'> <b>Sub Total</b> </div>
                      </Col>
                      <Col md={6} sm={6} xs={6}> 
                      <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {orderData.subTotal}</b></span> </div>
                      </Col>
                      {userData.loginStatus&&
                        <>
                          <Col md={12}> <div className='discount-border'></div> </Col>
                          <Col md={6} sm={6} xs={6} className="right-login"> 
                            <div className='order-cal'> Shipping Charges (+) &nbsp;  
                            </div>
                          </Col>
                          <Col md={6} sm={6} xs={6}> 
                          <div><span className='cartdot'>:</span>  <span> <b>₹ &nbsp; {orderData.shippingCharge}</b></span> </div>
                          <br/> <br/>
                          </Col>
                        </>
                      }
                      
                      <Col md={12}> <div className='discount-border'></div> </Col>
                      <Col md={6} sm={6} xs={6} className="right-login"> 
                        <div className='garnd-total'> <b>Grand Total</b> </div>
                      </Col>
                      <Col md={6} sm={6} xs={6}> 
                      <div><span className='cartdot'>:</span>  <span className='garnd-total'> <b>₹ &nbsp; {orderData.grandTotal}</b></span> </div>
                      </Col>
                      <Col md={12}> <div className='discount-border'></div> </Col>
                    </Row>
                    <div className="check-out">
                     <Link to="/order"> <button> <i className="fa fa-lock" aria-hidden="true"></i> &nbsp; Checkout </button> </Link>
                    </div> 
                    {userData.loginStatus && 

                      <Col className="text-center border-top">
                        <div className='mycart-collapse'  style={{cursor:'pointer'}} onClick={()=>{togglePromoCode(!promoCode.status)}} aria-controls="example-collapse-text" > <i className="fa fa-pencil" aria-hidden="true"></i>
                          &nbsp; Enter a promo code
                        </div>
                        <Collapse in={promoCode.status}>
                          <div className="input-group mb-3">
                            <input type="text" className="form-control mycart-input" placeholder="Enter a promo code" disabled={orderData.couponStatus=="1"} value={promoCode.code} onChange={(e)=>{if(orderData.couponStatus!="1")setPromoValue(e.target.value)}}/>
                            {promoCode.isLoading?
                            <div style={{position:'relative', height:'44px', width:"90px", borderRadius:"0.25rem", border:"1px solid #ced4da"}}>
                                <Loader width="40px" height="40px" backgroundColor="transparent"/>
                            </div>:
                            <div className="input-group-append" style={orderData.couponStatus!="1"?{cursor:"pointer"}:{cursor:"no-drop"}} onClick={()=>{if(orderData.couponStatus!="1" )applyPromoCode()}}>
                              <span className="input-group-text">Apply</span>
                            </div>
                            }
                          </div>
                        </Collapse>
                      </Col>                     
                    }

                    {/* Apply Rewards */}
                    {/*
                    <div className='d-flex justify-content-center border-top my-2'>
                          <FormGroup>
                            <FormControlLabel required control={<Checkbox />} label="Apply Rewards" />
                          </FormGroup>
                    </div>
                    */}
                    {/* Apply Rewards */}

                </Col>
                }
              </Row>
              <hr/>
             
           
              <Row>
                <Col md={10} sm={10} xs={10}>
              {!orderData.isEmpty && userData.loginStatus &&
                  <>
                    <div className='mycart-collapse left-collapse' onClick={() => toggleAddNote(!addNoteData.status)} aria-controls="example-collapse-text"> <i className="fa fa-sticky-note-o" aria-hidden="true"></i>
                      &nbsp; Add a note
                    </div>
                    <Collapse in={addNoteData.status}>
                      <div className="note-flax">
                        <textarea className="form-control" placeholder="Instructions? Special requests? Add them here." rows="2" value={addNoteData.text} onChange={(e)=>{setNoteValue(e.target.value)}}></textarea> 
                        <div style={{color:'green'}}>{addNoteData.msg}</div>
                        {addNoteData.isLoading?
                            <div style={{position:'relative', margin:'20px 0 6px 0', height:'44px', width:"90px", borderRadius:"0.25rem", border:"1px solid #ced4da"}}>
                                <Loader width="40px" height="40px" backgroundColor="transparent"/>
                            </div>:
                          <button className="forget_btn" type="button" onClick={sendAddNote}> Submit </button>
                        }
                      </div>
                    </Collapse>
                  </>
              }
            </Col>
                <Col sm={2} xs={2}>
                  <div> <img src={shoimg} /> </div>
                </Col>
              </Row>
              </div>
          </Container>
        </div>
      {error.status && 
        <Error msg={error.msg} fun={error.fun} cancle={canclePopup} canName={error.canName}/>
      }
      <Footer/>
    </div>
  );
};
export default OrderDetail;



import React,{useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import  './Smap.css';

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { Link } from "react-router-dom";

const SiteMap = () => {
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  return(
    <>
      <Header/>
      <Navigation/>
        <Container fluid>
          <Row> 
            <Col md={12}> 
            <div className="sitemap">
            <h1> Sitemap </h1>
            <ul id="primaryNav" className="col6">
            <br/> <br/> 
              <li id="home"> <Link to="/"> Home </Link> </li>
              <li> <Link to="/signup"> Create Account </Link> </li>
              <li> <Link to="/account/login"> Log In  </Link> </li>
              <li> <Link to="/aboutus"> About us </Link> </li>
              <li> <Link to="/books/1?classId=10"> Best CBSE ISC sample papers study material text books 10 class for 2021-2022  exam  </Link> </li>
              <li> <Link to="/books/5"> Best Educational Games and Teaching Aids for children learning fun </Link></li>
              <li> <Link to="/catalogue"> Catalogue: A Complete List of Books </Link></li>
            </ul>
            <ul id="primaryNav" className="col6">
              <li> <Link to="/career"> Career   </Link></li>
              <li> <Link to="/contactus"> Contact Us for Online Email Queries Web Support Customer Care|Rachnasagar.in  </Link></li>
              <li> <Link to="/newsEvent"> News and Events|Rachansagar.in </Link></li>
              <li> <Link to="/become-distributor"> Become A Distributor|Rachansagar.in </Link></li>
              <li> <Link to="/specimen"> Together With Specimen for Teacher|Rachansagar.in  </Link></li>
              <li> <Link to="/renowedauthors"> Our Renowned Authors </Link></li>
            </ul>
            <ul id="primaryNav" className="col6">
              <li> <Link to="/invitionauthors"> Invitation to Authors and Writers | Rachansagar.in </Link></li>
              <li> <Link to="/termcondition"> Term and Conditions | Rachansagar.in </Link> </li>
              <li> <Link to="/cancelPolicy"> Cancellation & Refund Policy | Rachansagar.in  </Link> </li>
              <li> <Link to="/privacypolicy"> Privacy Policy| Rachansagar.in </Link> </li>
              <li> <Link to="/disclaimer"> Disclaimer| Rachansagar.in </Link> </li>
              <li> <Link to="/faq"> FAQ|Frequently Asked Questions| Rachnasagar.in </Link> </li>
            </ul>
            <ul id="primaryNav" className="col6">
              <li> <Link to="/orderForm"> Stockist Online Order Form|Primary and TW  </Link> </li>
              <li> <Link to="/feedback"> Feed Back|Customer Online Support and Care |Rachnasagar.in </Link> </li>
            </ul>
            </div>
            <br/>
            </Col>
          </Row>
        
        </Container>
      <Footer/>
    </>
  );
};
export default SiteMap;



import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import refund from '../images/refund.webp';
import pricepolicy from '../images/pricepolicy.png';
import advertsing from '../images/advertsing.png';
import privacy from '../images/privacy.jpg';
import security from '../images/security.jpg';
import stop from '../images/Stop.png';
import dispute from '../images/dispute.jpg';
import jurisdiction from '../images/jurisdiction.jpg';
import liability from '../images/liabilitydisclaimer.jpg';
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Helmet from "react-helmet";


const PrivacyPolicy = () => {
  const [seoData, setSeoData] = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  useEffect(() => {
    getSeoData(); 
    AOS.init({
    offset: 300,
    duration:1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.privacyPolicySeo,{
          method:'POST',
          headers:{
            'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"privacypolice"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
  <>
  {seoData.isDone &&
    <Helmet>
        <title>{seoData.title} </title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.ogTags?.og_title} />
        <meta property="og:description" content={seoData.ogTags?.og_description} />
        <meta property="og:url" content={seoData.ogTags?.og_url} />
        <meta property="og:image" content={seoData.ogTags?.og_image_url} />
        <meta name="twitter:site" content="@rachnasagargrp"/> 
        <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
        <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
        <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
    </Helmet>
  }
  <Header/>
  <Navigation/>
  <div className='branch-bg'>
    <Container>
        <h1 className="invi-authors"> Privacy Policy </h1>
        <div className='box-invi'>
          <Row>
             <Col md={12}> <div className="policy-hedi">Cancellation & Refund Policy</div> </Col>
              <Col md={1}>
              <img src ={refund} />
              </Col>
              <Col md={11}>
              <p> As of now we do not provide any option for cancellation of products and/or services you have purchased or subscribed. Once a product/service has been purchased by you, we cannot provide any refund, either totally or partially. We suggest that first you go through the demos and/or free to use contents/products/services before you subscribe to or purchase from rachnasagar.in. </p>
              </Col>
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
          <Col md={12}> <div className="policy-hedi"> Pricing Policy </div> </Col>
            <Col md={1}>
              <img src ={pricepolicy} />
            </Col>
            <Col md={11}>
             <p> All Price are subject to change without any notice. Our Pricing policy includes all the taxes and norms that are applicable as per the government policies. </p>
            </Col>            
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
          <Col md={12}> <div className="policy-hedi"> Advertisement </div> </Col>
            <Col md={1}>
              <img src ={advertsing} />
            </Col>
            <Col md={11}>
             <p> The site may at its discretion display advertisements. The user’s correspondence or business dealings with, or participation in promotions of, advertisers found on or through the rachnasagar service, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between the user and such advertiser. The user agrees that Rachna Sagar shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Rachnasagar service. </p>
            </Col>            
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
          <Col md={12}> <div className="policy-hedi"> Privacy & Cookie Policy </div> </Col>
            <Col md={1}>
              <img src ={privacy} />
            </Col>
            <Col md={11}>
              <div className='poli-bold'> Privacy Statement </div>
              <p> This Privacy Statement is designed to advise you about the type of information that RACHNASAGAR.IN collects and the purposes, for which this information is being processed, used, maintained and disclosed. RACHNASAGAR.IN treats personal data which it receives through its websites; portals and any other means with due care and are dedicated to safeguarding any personal data it receives. This Privacy Policy helps to ensure that RACHNASAGAR.IN complies with applicable law in the countries in which we operate. We, at RACHNASAGAR.IN, take your privacy seriously, and will only use your personal information for the purposes permitted by law and specified in this Privacy Policy. Please read our Privacy Policy, and let us know if you have any questions.
RACHNASAGAR.IN is committed to protect your privacy whenever you buy goods. RACHNASAGAR.IN recognizes its responsibility to keep all personal information confidential at all times. Any information that RACHNASAGAR.IN acquires in connection with such Transactions is kept confidential. However, please note that RACHNASAGAR.IN responsibility is limited to protection of information that obtained by RACHNASAGAR.IN. </p>
            </Col> 
            <Col md={12}>
              <div className='poli-bold'> Personal Data </div>
              <p> Personal data refers to any information that tells us something about you or that we can link to you. We will only collect and
                 process personal data about you where we have lawful bases. Lawful bases on which we would process our personal information 
                 includes obtaining explicit consent from you for processing your personal information or processing for “legitimate interests” 
                 where processing is necessary by us to provide you with required services. However, please note that your personal data may be
                shared with legal authorities, if required by law.<br/>
                We may share personal information with our other corporate entities and affiliates. As a result of such sharing, they may 
                market to you for the required services unless you explicitly opt-out. The disclosure may be required for us to provide you 
                access to our Services, to comply with our legal obligations, to facilitate our marketing and advertising activities related 
                to our Services. We never disclose your personal information of any of our user to third parties for their marketing and 
                advertising purposes without your explicit consent.<br/>
                RACHNASAGAR.IN uses a variety of technologies and procedures to help protect personal information from unauthorized access, use or disclosure.
               </p>
            </Col>    
            <Col md={12}>
              <div className='poli-bold'> Cookies </div>
              <p> Cookie is a small text file of letters and numbers that is stored by the browser on your computer when you visit certain websites. A cookie allows a website to distinguish your computer from other users’ computers. Cookies do not contain Personal Data unless you have already given this information to the Website. You may delete or block the cookies, if that is what you choose to do. Note that this may make it less convenient for you to use our websites. RACHNASAGAR.IN website may contain links to other websites of your interest. Please note that once the Visitor opts to link to other websites, RACHNASAGAR.IN shall not be responsible for protection of further disclosure of information and this Privacy Policy / statement cease to be applicable for such disclosure of information. The Visitor may therefore, like to exercise caution and look at the privacy statement applicable to those websites. Similarly, your financial Data like, Account Number, Card Number etc, provided by you to pay us through third party Payment Gateway is protected by the service provider only and Rachnasagar.in never ask for any such information from you directly and is not in any case, responsible for the misuse of the same.<br/>
              RACHNASAGAR.IN website may be available on various other third party websites. However, RACHNASAGAR.IN shall not be responsible to the contents and material of such other websites. If any person provides its information on other websites, where link of RACHNASAGAR.IN website or RACHNASAGAR.IN logo is available, RACHNASAGAR.IN shall not be responsible to any acts or omissions committed by third party websites including but not limited to data theft, misuse of information of such person.<br/>
              RACHNASAGAR.IN reserves its right to amend the present Privacy Policy at any time and will place such amended Privacy Policy, if any, on its website. This Privacy Policy is neither intended to and nor does it create any contractual rights whatsoever or any other legal rights, nor does it create any obligations on RACHNASAGAR.IN in respect of any other party or on behalf of any party. By accessing this website and any of its pages, you are agreeing to the terms set out above.  </p>
            </Col>           
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
          <Col md={12}> <div className="policy-hedi"> Security Policy </div> </Col>
            <Col md={1}>
              <img src ={security} />
            </Col>
            <Col md={11}>
             <p> The user might receive a password and User ID upon completing the service's registration process. The users are undertakes to 
               maintain the confidentiality of the password and account, and shall be held solely responsible for all acts and omissions of 
               password or unique ID. <strong>Rachna Sagar</strong> cannot and will not be liable for any loss or damage arising from the 
               user’s failure to comply with the terms and conditions of this agreement. </p>
            </Col>            
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
          <Col md={12}> <div className="policy-hedi"> Termination </div> </Col>
            <Col md={1}>
              <img src ={stop} />
            </Col>
            <Col md={11}>
             <p> If users commit any breach of trust under this terms and conditions like disclose your user ID &amp; Password to another who is
              not authorized. <strong>Rachna Sagar</strong> have right to terminate or discontinue services immediately without any notice.</p>
            </Col>            
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
          <Col md={12}> <div className="policy-hedi"> Arbitration </div> </Col>
            <Col md={1}>
              <img src ={dispute} />
            </Col>
            <Col md={11}>
             <p>Any dispute, difference or question arising out of this terms and conditions shall be settle amicably between users and officer 
              which is appointed by <strong>Rachna Sagar</strong> failing which the same shall be referred to arbitrator under the arbitration 
              &amp; conciliation act 1996, and the venue of the arbitration shall be in Delhi only.</p>
            </Col>            
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
          <Col md={12}> <div className="policy-hedi"> Court of Jurisdiction </div> </Col>
            <Col md={1}>
              <img src ={jurisdiction} />
            </Col>
            <Col md={11}>
             <p><strong>Rachna Sagar</strong> controls and operates this Website from its headquarters in Delhi, India. You agree that any 
             disputes shall be subject to the exclusive jurisdiction of Courts at Delhi only.</p>
            </Col>            
          </Row>
        </div>
        <div className='box-invi'>
          <Row>
          <Col md={12}> <div className="policy-hedi">  Content & Liability Disclaimer </div> </Col>
            <Col md={1}>
              <img src ={liability} />
            </Col>
            <Col md={11}>
             <p> This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, 
              interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or 
              destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortuous behavior, 
              negligence, or under any other cause of action. </p>
            </Col> 
            <Col md={12}>
              <p> The documents and graphics on this website could include technical inaccuracies or typographical errors. Changes are periodically added to the information herein. Rachna Sagar may make improvements and/or changes herein at any time. Rachna Sagar makes no representations about the accuracy of the information contained in the documents and graphics on this web site for any purpose. All documents and graphics are provided "as is". Rachna Sagar hereby disclaims all warranties and conditions with regard to this information, including all implied warranties and conditions of merchantability, fitness for any particular purpose, title and non-infringement. In no event, Rachna Sagar and/or its licensor/ supplier shall not be liable to any party for any direct, indirect, special or other consequential damages for any use of the sites, the information, or on any other hyperlinked web site, including, without limitation, any lost profits, business interruption, loss of programs or other data on your information handling system or otherwise, even if Rachna Sagar is expressly advised of the possibility of such damages.
               <br/> Use of this Website is at your own risk, and the Rachna Sagar will not be held liable for any errors or omissions contained in this Website. In no event, shall the Rachna Sagar be liable for any special, indirect or consequential damages, or any damages whatsoever resulting from loss of use, data or profits whether contract, negligence or any tort action arising out of, or in connection with, the use or performance of the information available from Rachna Sagar . </p>
            </Col>           
          </Row>
        </div>
    </Container>
  </div>
  <Footer/>
  </>
  );
};
export default PrivacyPolicy;
import React, {useState, useEffect} from 'react';

const EbookPopup = () => {

 useEffect(() => {
    setTimeout(() => {
      setEbookPopup(true);
    }, 1000);
	
	  setTimeout(() => {
      setEbookPopup(false);
    }, 15000);
	
  }, []); 

  const [showEbookPopup, setEbookPopup]=useState(false);

  return(
      <div className="App">
          {/* Ebook popup */}
            {showEbookPopup &&  
            <div className="student_popup" onClick={(()=>{setEbookPopup(false)})} >
                <div className="ebookPopup" onClick={(e)=>{ e.stopPropagation()}}>
                  <div className="modal-header ebookheader">
                      <div className="modal-title">Ebook Notification</div>
                      <button onClick={(()=>{setEbookPopup(false)})} class="close"> ✕ </button>
                  </div>
                  <ul>
                    <li> The eBook content can’t be downloaded or printed. </li>
                    <li> No Refund will be made if the eBook has been downloaded or read by you. </li>
                    <li> Any refund related query or after sale technical support may take maximum 2 working days. </li>

                  </ul> 
        
                </div>
            </div>
            }
          {/* Ebook popup */}
      </div>
      
    );
  };
  export default EbookPopup;
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import Loader from '../../../Loader';
import {Contaxt} from '../../../Store';


const Wishlist = () => {

  const [wishlistData, setWishlistData] = useState({
    status:"loading",
    list:[],
    msg:"",
    isEmpty:false,
  })
  const {userData} = useContext(Contaxt);
  useEffect(() => { 
    getWishlist();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
    // listWishlist
  }, []);

  async function getWishlist(){
      setWishlistData((prev)=>{
        return {
          ...prev,
          status:"loading"
        }
      })
    try {
      let response = await  fetch(apiBaseURL+apiURL.listWishlist,{
          method:'POST',
          headers:{
              'rskey':apiKey,
              'utoken': userData.token
          },
          body:JSON.stringify({"action":"list"})
        });
      let result = await response.json();
      console.log(result)
      if(result.status=="1"){
        setWishlistData((prev)=>{
          return {
            ...prev,
            list:result.wishlist_items,
            status:"",
            isEmpty:false,
          }
        })
      } else {
        console.log(result.message)
        if(result.message=="Wishlist Empty"){
          setWishlistData((prev)=>{
            return {
              ...prev,
              status:"",
              isEmpty:true,
            }
          })

        } else {
          setWishlistData((prev)=>{
            return {
              ...prev,
              status:"",
            }
          })
        }
      }

    } catch (error) {
      console.log(error)
    }
  }
  // function addToCart(item){
  //   // console.log(userData)
    
  //   const sendData = {
  //     "bookId":`${item.ProductId}`,
  //     "product_type":item.Product_Type,
  //     "quntity":1
  //   }
  //   // setPopupCart({status:false, addingToCart:true})
    
  //   fetch(apiBaseURL+apiURL.addToCart,{
  //     method:'POST',
  //     headers:{
  //       'rskey' : 'rspl',
  //       'utoken': userData.token,
  //     },
  //     body:JSON.stringify(sendData)
  //   } )
  //     .then( response => response.json() )
  //     .then( (result) => {
  //       console.log(result)
  //       if(result.status=="1"){
          
  //       } else {
  //         // show error
          
  //       }
  //     })
  //     .catch( (error)=>{
  //         console.log(error)
        
  //       //  show error
  //     } );
  // }
  // function removeFromWishList(item){
  //   const sendData = {
  //     "bookId":`${item.ProductId}`,
  //     "product_type":item.Product_Type,
  //     "action":'remove'
  //   }
  //   // setWishlistLoading(true);
    
  //   fetch(apiBaseURL+apiURL.addToWishList,{
  //     method:'POST',
  //     headers:{
  //       'rskey' : 'rspl',
  //       'utoken': userData.token,
  //     },
  //     body:JSON.stringify(sendData)
  //   } )
  //     .then( response => response.json() )
  //     .then( (result) => {
  //       console.log(result)
  //       // setWishlistLoading(false);
  //       if(result.status=="1"){
  //         // setWishlistData((prev)=>{

  //         //   return {...}
  //         // })
  //       } else {
  //         // show error
  //       }
  //     })
  //     .catch( (error)=>{
  //         console.log(error)
  //         // setWishlistLoading(false);
  //       //  show error
  //     } );
     
    
  // }
  return(
    <>
      <Header/>
      <Navigation/>
        <div className='branch-bg'>
          <Container fluid className='back_width'> 
          <h1 className="invi-authors"> Wishlist Detail </h1>
            <div className='mycart'>
              <Row>
                {wishlistData.status=="loading"?
                  <div style={{position:'relative', height:'200px'}}>
                   <Loader width="80px" height="80px" backgroundColor="transparent"/>
                  </div>:
                  <>
                    {wishlistData.isEmpty?
                      <div>Your Wishlist is Empty</div>:
                      <>
                        {wishlistData.list.map((item)=>{
                          return(
                            <Col md={6} sm={12}>
                              <div className='wislist-main'>
                                <Row>
                                  <Col md={2} sm={2} xs={4}>
                                    {/* <Link to={"/products/"+item.ProductId+'?type='+item.Product_Type+'&id='+item.ProductId}> */}
                                    <Link to={"/"+item.catSlug+"/"+item.slug+'?id='+item.ProductId}>
                                      <img className="img-thumbnail" src={item.Image} />
                                    </Link>
                                  </Col> 
                                  <Col md={10} sm={10} xs={8}>
                                  {/* <Link to={"/products/"+item.ProductId+'?type='+item.Product_Type+'&id='+item.ProductId}> */}
                                 
                                    <h3 className="copy-walls">  <Link to={'/'+item.catSlug+'/'+item.slug+'?id='+item.ProductId}>  {item.Product_Title}   </Link></h3>
                                
                                    <Row> 
                                      {/* <Col md={4} sm={4} xs={4}><h4><strike>₹ {item.Product_MRP}</strike>&nbsp;&nbsp; <b style={{color:"black"}}>₹ {item.Product_Price}</b></h4> </Col> */}
                                      <Col md={6} sm={6} xs={12}>
                                      <div style={{fontSize: "16px"}}>
                                        <div>ISBN : {item.Product_ISBN} </div>
                                        <div>Class : {item.Product_Class}</div>
                                        <div>Subject : {item.Product_Subject}</div>
                                        <div>Product Type : {item.Product_Type_Title}</div>
                                        <div>Quantity : {item.Quantity}</div>
                                        <div>
                                        <span className="booPrice">₹ {item.Product_Price}</span> 
                                        <span className='order-rupess'> &nbsp; ₹ </span> <span className="linethrow">{item.Product_MRP}</span> 
                                        </div>
                                        
                                        {item.Product_Discount_Percent!="0"&&
                                        <div><b style={{color:"#717171"}}> You save ₹ {item.Total_Discount}</b> <span className='bookPer'> ({item.Product_Discount_Percent}% off) </span></div>
                                        }
                                      </div>
                                      {/* <div className="multiple">
                                      
                                      <button className='wislist-btn' onClick={()=>{addToCart(item)}}>Add to Cart</button>
                                      <div className="multi_empty" onClick={()=>{removeFromWishList()}}><i className="fa fa-trash" aria-hidden="true"></i></div>
                                      </div> */}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </Col>  
                          )
                        })}
                      </>
                    }
                  </>
                }
                {/* <Col md={6} sm={12}>
                <div className='wislist-main'>
                  <Row>
                    <Col md={2} sm={2} xs={4}>
                    <img className="img-thumbnail" src={englioder} />
                    </Col> 
                    <Col md={10} sm={10} xs={8}>
                      <h3 className="copy-walls">Together with English Core Study Material for Class 12 Term I & Term II (For 2021-2022 Examination)</h3>
                      <Row> 
                        <Col md={4} sm={4} xs={4}><h4><b>₹ 55.00</b></h4> </Col>
                        <Col md={6} sm={6} xs={6}>
                        <div className="multiple">
                         
                         <h4>Add to Cart</h4>
                            <div className="multi_empty"><i className="fa fa-trash" aria-hidden="true"></i></div>
                        </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  </div>
                </Col>   
                <Col md={6} sm={12}>
                <div className='wislist-main'>
                  <Row>
                    <Col md={2} sm={2} xs={4}>
                    <img className="img-thumbnail" src={englioder} />
                    </Col> 
                    <Col md={10} sm={10} xs={8}>
                      <h3 className="copy-walls">Together with English Core Study Material for Class 12 Term I & Term II (For 2021-2022 Examination)</h3>
                      <Row> 
                        <Col md={6} sm={6} xs={6}><h4><b>₹ 55.00</b></h4> </Col>
                        <Col md={6} sm={6} xs={6}>
                        <div className="multiple">
                            <div className="multi_empty"><i className="fa fa-trash" aria-hidden="true"></i></div>
                        </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  </div>
                </Col>             */}
              </Row>
            </div>
          </Container>
        </div>
      <Footer/>
    </>
  );
};
export default Wishlist;



import React ,{useState,useEffect} from 'react';

import { apiBaseURL, apiURL } from './../../../constant';
import Loader from '../../../Loader';

import { Container, Row, Col} from 'react-bootstrap';

import Auther_errow from '../images/auther-errow.png';
import Testi_left from '../images/teacher.png';
import Testi_right from '../images/student.png';



function Testimonials({testimonialItems}) {


// const [testimonialItems,setTestimonialItems] = useState([]);
const [isLoding, setLoading] = useState(true);

// window.sessionStorage.setItem('anik',"sssss")
// let a = window.sessionStorage.getItem('anik')
// alert(a);

useEffect(()=>{

  if(testimonialItems.length!=0){
      owlCarouselInit()
  }

})


const owlCarouselInit = () => {
  window.$("#Tesimo").owlCarousel({
      items:1,
      itemsDesktop:[1000,1],
      itemsDesktopSmall:[979,1],
      itemsTablet:[768,1],
      pagination:false,
      navigation:true,
      navigationText:["",""],
      Default: false,
      autoPlay: false
  });
}

//-------------Testimonials -----------------
// function getTestimonials() {
    
//     fetch(apiBaseURL+apiURL.testimonials,{
//       method:'get',
//       headers:{
//         'rskey' : 'rspl'
//       }
//     } )
//       .then( response => response.json() )
//       .then( (result) => {
//         console.log( result);
//         if( result.status==='1' ){
//           setTestimonialItems( result.testimonials_data ); 
//           owlCarouselInit();
//            //alert(result) 

//         }else{
//           setTestimonialItems([]);
//           alert(result)
//         }
//         setLoading(false);
//       } )
//       .catch( (error)=>{
//         setTestimonialItems([]);
//         setLoading(false);
//       } );

// }

return (
    <div className="testimonials">
        <Row>
          <Col md={2} sm={2} data-aos="fade-right" className='posi-bottom-left'>  <img src={Testi_left} /> </Col>
          <Col md={8} sm={12} className='text-center'> 

            <h2 className="testi-hedi">Testimonials</h2>
             <div className="bottom-border2" data-aos="zoom-in-right" data-aos-duration="800"> </div>

             <div id="Tesimo" className="owl_carousel">
                {testimonialItems.map( (item)=>{                  
                    return(        
                    
                      <div className="box-item" key={item.id}>
                        <div className="item">                      
                          <img className="testi-img" src={Auther_errow} />  
                          <p>{item.desp}</p>
                          <div className="tesi-titel"> {item.testimonialTitle}  </div>                   
                        </div>
                      </div>                     
                      
                    );
                  } )}
              </div>

              {/*
                <div id="Tesimo" className="owl_carousel"> 
                
                  <div className="box-item">
                      <div className="item"> 
                            <img className="testi-img" src={Auther_errow} />
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                            <div className="tesi-titel"> Name Title </div>
                      </div>
                  </div>
                  <div className="box-item">
                      <div className="item"> 
                          <img className="testi-img" src={Auther_errow} />
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                          <div className="tesi-titel"> Name Title </div>
                      </div>
                  </div>
                </div>
            */}
          </Col>
          <Col md={2} sm={2} data-aos="fade-left" className='posi-bottom-right'> <img src={Testi_right} />  </Col>
        </Row>
    </div>

);

}
export default Testimonials;

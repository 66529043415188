import React from "react";

function SearchPopup({closePopup,children}){
  return (
    <div className="searchpopup-bg" onClick={closePopup}>
      <div className="searchpopup" onClick={(e)=>{ e.stopPropagation()}} >
        <div className="row">
          {children}
        </div>
      </div>
    </div>
  )
}
export default SearchPopup;
import React, {useEffect, useContext} from 'react'
import {Outlet} from 'react-router-dom'
import { Contaxt } from "../../../Store";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

export default function Profile(){
    const {userData} = useContext(Contaxt)
    const navigate = useNavigate();
    const {pathname, state} = useLocation();

    useEffect(()=>{
        if(!userData.loginStatus){
          let url = btoa(pathname.substring(1)); 
          navigate('/account/login/'+url,  { replace: true, state:state})
          
        } else if(pathname.toLowerCase()=='/profile' || pathname.toLowerCase()=='/profile/'){
            navigate('/Profile/account')
            
        }
    })

    return(
       
        <Outlet/>
        
    )
}

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import Loader from "../../../Loader";

const NewReleases = () => {
  const [newReleaseData, setNewReleaseData] = useState({
    status: "loading",
    list: []
  });
  const [queryString] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const type = queryString.get('new-release');
    if (type == null) {
      navigate('/');
    } else {
      getNewReleaseData(type);
    }

    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    // Dynamically update the document title and meta description
    if (newReleaseData.list.length > 0) {
      const productMetaDescription = newReleaseData.list[0].new_release_title || "New Releases";
      document.title = `Rachna Sagar: ${productMetaDescription}`;
    }
  }, [newReleaseData]);

  async function getNewReleaseData(type) {
    let sendData = {
      "New_Releases": type,
      "action": "newrelease"
    };
    try {
      let response = await fetch(apiBaseURL + apiURL.newRelease, {
        method: 'POST',
        headers: {
          'rskey': apiKey,
        },
        body: JSON.stringify(sendData)
      });
      let result = await response.json();
      if (result.status == "1") {
        setNewReleaseData((prev) => {
          return { ...prev, list: result.products, status: "" }
        });
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="App">
      {/* Helmet to update meta tag */}
      <Helmet>
        <meta name="description" content={newReleaseData.list.length > 0 ? newReleaseData.list[0].meta_description : "New Releases"} />
      </Helmet>
      <Header />
      <Navigation />
      <div className="books-bg" data-aos="fade-right">
        <h1>New Releases</h1>
      </div>
      <Container className="mb-4">
        {newReleaseData.status === "loading" ? (
          <div style={{ position: 'relative', height: '200px' }}>
            <Loader width="80px" height="80px" backgroundColor="transparent" />
          </div>
        ) : (
          <Row>
            {newReleaseData.list.map((item) => {
              return (
                <Col md={3} sm={6} key={item.productId}>
                  <div className="book-section">
                    <Link to={`/${item.catSlug ? item.catSlug : 'product'}/${item.slug}?id=${item.productId}`}>
                      <img src={item.main_image} alt={item.product_title} />
                    </Link>
                  </div>
                  <div className="book-dis">
                    <Link to={`/${item.catSlug ? item.catSlug : 'product'}/${item.slug}?id=${item.productId}`}>
                      {item.product_title}
                    </Link>
                  </div>
                  <div className="book-price">
                    ₹ {item.book_price} &nbsp;
                    {item.book_discount_percent !== 0 && (
                      <>
                        <strike>
                          <span className="bookBtMrp">₹ {item.book_mrp} </span>
                        </strike>
                        <span className="bookPer"> &nbsp; ({item.book_discount_percent}% off) </span>
                      </>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default NewReleases;

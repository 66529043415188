import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Collapse from 'react-bootstrap/Collapse';

import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

// import SliderFrom from './SliderFrom';
import Loader from '../../../Loader';
import { apiURL, apiBaseURL, apiKey } from '../../../constant';

const TogetherFrom = () => {

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(true);
  //=============================
  useEffect(() => { 
      AOS.init({
        offset:300,
        duration:1000,
      });
  }, []);
  //==============================
  //================= // Together Order Form  \\ ===================salesPerson
  const [orderNo,setOrderNo]=useState("");
  const [salesPerson,setSalesPerson]=useState("");
  const [date,setDate] = useState(""); 

  const [partyName,setPartyName] = useState("");
  const [bank,setBank] = useState("");
  const [tpt,setTpt] = useState("");
  const [grNo,setGrNo] = useState("");

  const [grDate,setGrDate] = useState("");
  const [bkgStn,setBkgStn] = useState("");
  const [fwdTptExp,setFwdTptExp] = useState("");
  const [discount,setDiscount] = useState("");


  const [erOrderNo, setErOrderNo] = useState("");
  const [erSalesPerson, setErSalesPerson] = useState("");
  const [erDate,setErDate] = useState("");
  
  
  const [erPartyName,setErPartyName] = useState("");
  const [erBank,setErBank] = useState("");
  const [erTpt,setErTpt] = useState("");
  const [erGrNo,setErGrNo] = useState("");

  const [erGrDate,setErGrDate] = useState("");
  const [erBkgStn,setErBkgStn] = useState("");
  const [erFwdTptExp,setErFwdTptExp] = useState("");
  const [erDiscount,setErDiscount] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");

  const [lodar, setLodar] = useState("");

  function saveTogetherOrderFrm()
  {   
      
      //alert(orderno);
     
      let data = {orderNo,salesPerson,date,partyName,bank,tpt,grNo,grDate,bkgStn,fwdTptExp,discount,bookData:togetherOrderInfo}
      console.warn(data)  

      setErOrderNo("");
      setErSalesPerson("");
      setErDate("");
      setErPartyName("");

      setErBank("");
      setErTpt("");
      setErGrNo("");
      setErGrDate("");

      setErBkgStn("");
      setErFwdTptExp("");
      setErDiscount("");

  

      setLodar("Loading...");
      if( orderNo=='' || salesPerson =='' || date =='' || partyName=='' || bank=='' || tpt=='' || grNo=='' || grDate=='' || bkgStn=='' || fwdTptExp=='' || discount==''){

        if(orderNo==""){  setErOrderNo("Order No required");     }
        if(salesPerson==""){  setErSalesPerson("Sales Person required");   }
        if(date==""){  setErDate("Date required");  }
        if(partyName==""){  setErPartyName("Party Name required");  }

        if(bank==""){   setErBank("Bank required");     }
        if(tpt==""){    setErTpt("tpt required");     }
        if(grNo==""){   setErGrNo("RG No. required");     }
        if(grDate==""){   setErGrDate("GR Date required");     }

        if(bkgStn==""){   setErBkgStn("Bank required");     }
        if(fwdTptExp==""){   setErFwdTptExp("Bank required");     }
        if(discount==""){   setErDiscount("Discount required");     }
            
        setLodar("");

      }else{

        fetch(apiBaseURL+apiURL.togetherorder,{
            method:'POST',
            headers:{
                'rskey':'rspl'
            },
            body:JSON.stringify( data )
        } )
        .then( (result) => {
            setErrorMsg("");
            
            //==============================================
            result.json().then((data) => {
                  if (data.status == "1") {
                      

                      //setOrderNo("");                      
                      //setErrorMsg("");

                      setMsg(data.message);
                      console.log(data.message.data);
                      setLodar("");


                      setTimeout(function() {
                          setMsg("");
                      }, 30009999);


                  
                  } else {
                      
                    setErrorMsg(data.message);
                      //console.log(data.errors);
                      setLodar("");
                  }
            });
            //===========================================

        } ).catch( (err)=>{
            setMsg("");
            setErrorMsg("Failed to fetch");
            alert(err);
            console.log(err);
        } )

      }

  }

  //===================================================
  async function addProduct(value, id, item){


    
    //let ele = document.getElementById(id);
    //let quantity = document.getElementById(id+'inp').value;

    let ele = "2222";
    let quantity = "1";
    
    console.log(value, id, quantity)
    
    const sendData = {
      "bookId" : item?.toString(),  
      "product_type" : "p_book",
      "quantity" : quantity,
      "mode":value?'add':'remove'
    }
    // console.log(item)
    // return;
    try {
      let response = await  fetch(apiBaseURL+apiURL.addTogetherOrder,{
          method:'POST',
          headers:{
              'rskey':apiKey,
              'utoken':"123456"
          },
          body:JSON.stringify(sendData)
        });
      let result = await response.json();
      console.log(result);
      if(result.status=="1"){
        
        /*
        ele.style.display = 'block';
        ele.innerHTML = value?"Added":"Removed"
        setTimeout(()=>{
          ele.style.display = 'none'
        }, 2000)

        */

        //setCartItemNum(result.Total_Items)
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  //=========================================

  //====================================================
  //  [{quantity:2, code:2323}, {quantity:5, code:52323}]
  // [5, 8]
  const [togetherOrderInfo, setTogetherOrderInfo] = useState([]);
  
  const manageCheckBox = (e) => {
    // Destructuring
   

    const { value, checked } = e.target;
    // const { codeNo } = togetherOrderInfo;
    
    let quantity = document.getElementById(value).value;

    console.log(`${value} is ${checked} -  ${quantity}`);

    //value = value+"ani";
    
     
    // Case 1 : The user checks the box
    if (checked) {
      setTogetherOrderInfo((prev)=>{
        let newList = [...prev];
        /*newList.push({name:value, quantity:quantity})*/
        newList.push({bookCodeNo:value, quantity:quantity})
        return newList;
      })
    }
  
    // Case 2  : The user unchecks the box
    else {
      setTogetherOrderInfo((prev)=>{
        let newList = [...prev];
        //let index = newList.findIndex((item)=>item.name==value);
        let index = newList.findIndex((item)=>item.bookCodeNo==value);
       
        if(index!=-1){
          newList.splice(index, 1);
        }
        return newList;
      })
    }

    
  };

  console.log(togetherOrderInfo);
//========================================
  //================= \\ Together Order Form  // ===================


  return(
  <>
    <Header/>
    <Navigation/>
    <form >
      <div className='branch-bg'>
        <h1 className="invi-authors"> Together With Order Form </h1>
        <Container>
        
          <div className='order_primary'>
            <Row className='m-1'> 
              <Col md={4}>
                <div className='primary-from'>
                    <label> ORDER NO </label> 
                  {/* <input type="order" className="form-control" placeholder="ORDER NO" /> */}
                  <input type="name" name="orderNo" id="orderNo" value={orderNo} onChange={(e)=>setOrderNo(e.target.value)} className="form-control" placeholder="ORDER NO" />
                  { erOrderNo!=='' &&    <span className="errMgs">{erOrderNo}</span> }
                </div>
              </Col>
              <Col md={4}>
                <div className='primary-from'>
                <label> SALES PERSON </label> 
                  <input type="name" name="salesPerson" id="salesPerson" value={salesPerson} onChange={(e)=>setSalesPerson(e.target.value)} className="form-control" placeholder="SALES PERSON" />
                  { erSalesPerson!=='' &&    <span className="errMgs">{erSalesPerson}</span> }
                </div>
              </Col>
              <Col md={4}>
                <div className='primary-from'>
                <label> DATE </label> 
                  <input type="date" name="date" id="date" value={date} onChange={(e)=>setDate(e.target.value)} className="form-control" placeholder="Date" />
                  { erDate!=='' &&    <span className="errMgs">{erDate}</span> }
                </div>
              </Col>
              <Col md={6}>
                <div className='primary-from'>
                  <label> PARTY NAME </label> 
                  <input type="name" name="partyName" id="partyName" value={partyName} onChange={(e)=>setPartyName(e.target.value)} className="form-control" placeholder="Party Name" />
                  { erPartyName!=='' &&    <span className="errMgs">{erPartyName}</span> }
                </div>
              </Col>
              <Col md={6}>
                <div className='primary-from'>
                <label> BANK </label> 
                <input type="name" name="bank" id="bank" value={bank} onChange={(e)=>setBank(e.target.value)} className="form-control" placeholder="Bank" />
                { erBank!=='' &&    <span className="errMgs">{erBank}</span> }
                </div>
              </Col>
              <Col md={4}>
                <div className='primary-from'>
                <label> TPT </label> 
                <input type="name" name="tpt" id="tpt" value={tpt} onChange={(e)=>setTpt(e.target.value)} className="form-control" placeholder="TPT" />
                { erTpt!=='' &&    <span className="errMgs">{erTpt}</span> }
                </div>
              </Col>
              <Col md={4}> 
                <div className='primary-from'>
                  <label> GR NO </label> 
                  <input type="name" name="grNo" id="grNo" value={grNo} onChange={(e)=>setGrNo(e.target.value)} className="form-control" placeholder="GR NO" />
                  { erGrNo!=='' &&    <span className="errMgs">{erGrNo}</span> }
                </div>
              </Col>
              <Col md={4}> 
                <div className='primary-from'>
                  <label> GR DATE </label> 
                  <input type="date" name="grDate" id="grDate" value={grDate} onChange={(e)=>setGrDate(e.target.value)} className="form-control" placeholder="GR Date" />
                  { erGrDate!=='' &&    <span className="errMgs">{erGrDate}</span> }
                </div>
              </Col>
              <Col md={4}> 
                <div className='primary-from'>
                  <label> BKG STN </label> 
                  <input type="name" name="bkgStn" id="bkgStn" value={bkgStn} onChange={(e)=>setBkgStn(e.target.value)} className="form-control" placeholder="BKG STN" />
                  { erBkgStn!=='' &&    <span className="errMgs">{erBkgStn}</span> }
                </div>
              </Col>
              <Col md={4}> 
                <div className='primary-from'>
                  <label> FWD. | TPT.EXP </label> 
                  <input type="name" name="fwdTptExp" id="fwdTptExp" value={fwdTptExp} onChange={(e)=>setFwdTptExp(e.target.value)} className="form-control" placeholder="FWD. | TPT.EXP" />
                  { erFwdTptExp!=='' &&    <span className="errMgs">{erFwdTptExp}</span> }
                </div>
              </Col>
              <Col md={4}>                                        
                <div className='primary-from'>
                  <label> DISCOUNT </label> 
                  <input type="name" name="discount" id="discount" value={discount} onChange={(e)=>setDiscount(e.target.value)} className="form-control" placeholder="Discount" />
                  { erDiscount!=='' &&    <span className="errMgs">{erDiscount}</span> }
                </div>
              </Col>

              

            </Row>
          </div>
        
        </Container>
      </div>
      <Container fluid className='back_width'>
      <Row>
        <Col md={12}> 
        <div className='together-hedi'> SOLVED QUESTION BANK/STUDY MATERIAL FOR CLASSES 9 – 12 ( CBSE ) </div>          
          <Row>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class IX </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0243 <input type="hidden" name="codeNo2" id="codeNo2" value="0243" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> ASL </td>
                      <td className='text-center'>
                          <input type="number" name="0243" id="0243" className='cal-quantity' defaultValue="1" min="1" />                       
                      </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0243" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1158 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1158" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Be a Genius </td>
                      <td className='text-center'>  <input type="number" name="1158" id="1158" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1158" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 4034 <input type="hidden" name="codeNo[]" id="codeNo[]" value="4034" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Communicative </td>
                      <td className='text-center'>  <input type="number" name="4034" id="4034" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="4034" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>


                    

                    <tr>
                      <td className='text-center'> 4036 <input type="hidden" name="codeNo[]" id="codeNo[]" value="4036" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Communicative Pull-out Worksheets </td>
                      <td className='text-center'>  <input type="number" name="4036" id="4036" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="4036" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 4125 <input type="hidden" name="codeNo[]" id="codeNo[]" value="4125" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Communicative + Pull-out Worksheets </td>
                      <td className='text-center'>  <input type="number" name="4125" id="4125" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="4125" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>

                    
                    <tr>
                      <td className='text-center'> 1426 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1426" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Language and Literature </td>
                      <td className='text-center'>  <input type="number" name="1426" id="1426" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1426" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
                    
					
					<tr>
                      <td className='text-center'> 1983 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1983" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Language and Literature Pull-out Worksheets </td>
                      <td className='text-center'>  <input type="number" name="1983" id="1983" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1983" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
                    
                    
					
					

                    
                   <tr>
                      <td className='text-center'> 2476 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2476" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Language and Literature + Pull-out Works </td>
                      <td className='text-center'>  <input type="number" name="2476" id="2476" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2476" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
					
					
					<tr>
                      <td className='text-center'> 1004 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1004" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Language & Literature Pull-out Worksheets Solution </td>
                      <td className='text-center'>  <input type="number" name="1004" id="1004" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1004" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
					
					<tr>
                      <td className='text-center'> 0124 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0124" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>French </td>
                      <td className='text-center'>  <input type="number" name="0124" id="0124" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0124" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
                    
					
                    
					
					
					<tr>
                      <td className='text-center'> 1427 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1427" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Hindi A </td>
                      <td className='text-center'>  <input type="number" name="1427" id="1427" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1427" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
                    
                    <tr>
                      <td className='text-center'> 1428 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1428" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Hindi B </td>
                      <td className='text-center'>  <input type="number" name="1428" id="1428" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1428" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 1429 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1429" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Mathematics </td>
                      <td className='text-center'>  <input type="number" name="1429" id="1429" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1429" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
					
					
					<tr>
                      <td className='text-center'> 4035 <input type="hidden" name="codeNo[]" id="codeNo[]" value="4035" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Sanskrit Communicative </td>
                      <td className='text-center'>  <input type="number" name="4035" id="4035" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="4035" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
                    
                    
					<tr>
                      <td className='text-center'> 1430 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1430" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Sanskritam </td>
                      <td className='text-center'>  <input type="number" name="1430" id="1430" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1430" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
                    
					<tr>
                      <td className='text-center'> 1431 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1431" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Science </td>
                      <td className='text-center'>  <input type="number" name="1431" id="1431" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1431" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
					
                    <tr>
                      <td className='text-center'> 1432 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1432" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Social Science </td>
                      <td className='text-center'><input type="number" name="1432" id="1432" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1432" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    

                  </tbody>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class X  </b> </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 0252 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0252" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>ASL </td>
                      <td className='text-center'><input type="number" name="0252" id="0252" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0252" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
					
					
					<tr>
                      <td className='text-center'> 1418 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1418" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Language & Literature </td>
                      <td className='text-center'><input type="number" name="1418" id="1418" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1418" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 0297 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0297" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Language & Literature Pull-out Worksheets </td>
                      <td className='text-center'><input type="number" name="0297" id="0297" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0297" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
					
					<tr>
                      <td className='text-center'> 2477 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2477" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>English Language & Literature + Pull-out Worksheets </td>
                      <td className='text-center'><input type="number" name="2477" id="2477" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2477" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    

                    <tr>
                      <td className='text-center'> 2475 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2475" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Language & Literature Pull-out Worksheets Solution </td>
                      <td className='text-center'><input type="number" name="2475" id="2475" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2475" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 0092 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0092" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>French </td>
                      <td className='text-center'><input type="number" name="0092" id="0092" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0092" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 1419 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1419" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Hindi A </td>
                      <td className='text-center'><input type="number" name="1419" id="1419" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1419" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 1420 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1420" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Hindi B </td>
                      <td className='text-center'><input type="number" name="1420" id="1420" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1420" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 2557 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2557" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Mathematics (Basic)</td>
                      <td className='text-center'><input type="number" name="2557" id="2557" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2557" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 1421 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1421" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Mathematics (Stadard) </td>
                      <td className='text-center'><input type="number" name="1421" id="1421" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1421" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
					
					<tr>
                      <td className='text-center'> 1422 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1422" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Sanskritam </td>
                      <td className='text-center'><input type="number" name="1422" id="1422" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1422" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
                    
					<tr>
                      <td className='text-center'> 1423 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1423" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Science </td>
                      <td className='text-center'><input type="number" name="1423" id="1423" className='cal-quantity' defaultValue="1" min="1" /> </td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1423" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                        <div  style={{display:"none"}} className="catlogMsg"> </div>
                      </td>
                    </tr>
					
					
                    <tr>
                      <td className='text-center'> 1424 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1424" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Social Science </td>
                      <td className='text-center'><input type="number" name="1424" id="1424" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1424" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                   <tr>
                      <td className='text-center'> 2707 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2707" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}>Competency Based and Objective Type Questions (English Language and  Literature, Mathematics, Science, Social Science) (Combo) </td>
                      <td className='text-center'><input type="number" name="2707" id="2707" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2707" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr> 
                    
                    

                  </tbody>
                </table>
              </div> 

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class X | CBSE Sample Papers (EAD) </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1545 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1545" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Language & Literature </td>
                      <td className='text-center'><input type="number" name="1545" id="1545" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1545" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1546 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1546" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi A </td>
                      <td className='text-center'><input type="number" name="1546" id="1546" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1546" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1547 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1547" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi B </td>
                      <td className='text-center'><input type="number" name="1547" id="1547" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1547" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 1548 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1548" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics (Standard) </td>
                      <td className='text-center'><input type="number" name="1548" id="1548" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1548" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 1549 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1549" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Science </td>
                      <td className='text-center'><input type="number" name="1549" id="1549" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1549" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1550 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1550" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Social Science</td>
                      <td className='text-center'><input type="number" name="1550" id="1550" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1550" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    

                  </tbody>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class X | NCERT solutions </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1433 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1433" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Language and Literature </td>
                      <td className='text-center'><input type="number" name="1433" id="1433" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1433" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1434 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1434" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi A </td>
                      <td className='text-center'><input type="number" name="1434" id="1434" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1434" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1435 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1435" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi B </td>
                      <td className='text-center'><input type="number" name="1435" id="1435" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1435" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 1436 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1436" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics </td>
                      <td className='text-center'><input type="number" name="1436" id="1436" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1436" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 1437 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1437" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Science </td>
                      <td className='text-center'><input type="number" name="1437" id="1437" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1437" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1438 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1438" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Social Science</td>
                      <td className='text-center'><input type="number" name="1438" id="1438" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1438" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    

                  </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class X | cbse Previous years Questions </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1439 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1439" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Language and Literature </td>
                      <td className='text-center'><input type="number" name="1439" id="1439" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1439" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1440 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1440" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi A </td>
                      <td className='text-center'><input type="number" name="1440" id="1440" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1440" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1441 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1441" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi B </td>
                      <td className='text-center'><input type="number" name="1441" id="1441" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1441" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 1442 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1442" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics </td>
                      <td className='text-center'><input type="number" name="1442" id="1442" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1442" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 1443 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1443" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Science </td>
                      <td className='text-center'><input type="number" name="1443" id="1443" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1443" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1444 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1444" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Social Science</td>
                      <td className='text-center'><input type="number" name="1444" id="1444" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1444" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    

                  </tbody>
                </table>
              </div>
            </Col>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class X | CBSE Pariksha </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0183 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0183" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Language and Literature </td>
                      <td className='text-center'><input type="number" name="0183" id="0183" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0183" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1586 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1586" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi A </td>
                      <td className='text-center'><input type="number" name="1586" id="1586" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1586" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1587 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1587" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi B </td>
                      <td className='text-center'><input type="number" name="1587" id="1587" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1587" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0184 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0184" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics (Standard)</td>
                      <td className='text-center'><input type="number" name="0184" id="0184" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0184" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0185 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0185" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Science </td>
                      <td className='text-center'><input type="number" name="0185" id="0185" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0185" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0186 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0186" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Social Science</td>
                      <td className='text-center'><input type="number" name="0186" id="0186" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0186" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class XI </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 3275 <input type="hidden" name="codeNo[]" id="codeNo[]" value="3275" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Applied Mathematics </td>
                      <td className='text-center'><input type="number" name="3275" id="3275" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="3275" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0244 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0244" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> ASL </td>
                      <td className='text-center'><input type="number" name="0244" id="0244" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0244" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0233 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0233" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Biology </td>
                      <td className='text-center'><input type="number" name="0233" id="0233" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0233" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 1159 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1159" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Be a Genius </td>
                      <td className='text-center'><input type="number" name="1159" id="1159" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1159" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0232 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0232" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Chemistry </td>
                      <td className='text-center'><input type="number" name="0232" id="0232" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0232" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0046 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0046" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Computer Science (C++) with Python</td>
                      <td className='text-center'><input type="number" name="0046" id="0046" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0046" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0044 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0044" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Economics </td>
                      <td className='text-center'><input type="number" name="0044" id="0044" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0044" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0033 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0033" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core </td>
                      <td className='text-center'><input type="number" name="0033" id="0033" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0033" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0974 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0974" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core Pull-out Worksheets </td>
                      <td className='text-center'><input type="number" name="0974" id="0974" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0974" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 2581 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2581" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core + Pull-out Worksheets </td>
                      <td className='text-center'><input type="number" name="2581" id="2581" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2581" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 2176 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2176" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core Pull-out Worksheets Solution </td>
                      <td className='text-center'><input type="number" name="2176" id="2176" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2176" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0036 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0036" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics </td>
                      <td className='text-center'><input type="number" name="0036" id="0036" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0036" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0231 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0231" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physics </td>
                      <td className='text-center'><input type="number" name="0231" id="0231" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0231" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class XII </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 3275 <input type="hidden" name="codeNo[]" id="codeNo[]" value="3275" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Applied Mathematics </td>
                      <td className='text-center'><input type="number" name="3275" id="3275" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="3275" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0014 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0014" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Accountancy </td>
                      <td className='text-center'><input type="number" name="0014" id="0014" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0014" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0256 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0256" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Biology </td>
                      <td className='text-center'><input type="number" name="0256" id="0256" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0256" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0017 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0017" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Business Studies </td>
                      <td className='text-center'><input type="number" name="0017" id="0017" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0017" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                    <tr>
                      <td className='text-center'> 0255 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0255" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Chemistry </td>
                      <td className='text-center'><input type="number" name="0255" id="0255" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0255" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 2747 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2747" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Computer Science (C++) with Python</td>
                      <td className='text-center'><input type="number" name="2747" id="2747" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2747" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0015 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0015" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Economics </td>
                      <td className='text-center'><input type="number" name="0015" id="0015" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0015" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0001 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0001" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core </td>
                      <td className='text-center'><input type="number" name="0001" id="0001" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0001" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0975 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0975" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core Pull-out Worksheets </td>
                      <td className='text-center'><input type="number" name="0975" id="0975" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0975" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 2623 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2623" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core + Pull-out Worksheets </td>
                      <td className='text-center'><input type="number" name="2623" id="2623" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2623" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 2177 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2177" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core Pull-out Worksheets Solution </td>
                      <td className='text-center'><input type="number" name="2177" id="2177" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2177" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0026 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0026" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Geography </td>
                      <td className='text-center'><input type="number" name="0026" id="0026" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0026" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0029 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0029" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Home Science </td>
                      <td className='text-center'><input type="number" name="0029" id="0029" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0029" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0031 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0031" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> History </td>
                      <td className='text-center'><input type="number" name="0031" id="0031" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0031" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0020 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0020" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Informatics Practices with Python </td>
                      <td className='text-center'><input type="number" name="0020" id="0020" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0020" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0253 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0253" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics Applied Mathematics </td>
                      <td className='text-center'><input type="number" name="0253" id="0253" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0253" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1055 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1055" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physics </td>
                      <td className='text-center'><input type="number" name="1055" id="1055" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1055" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0024 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0024" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physical Education (E) </td>
                      <td className='text-center'><input type="number" name="0024" id="0024" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0024" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0025 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0025" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physical Education (H) </td>
                      <td className='text-center'><input type="number" name="0025" id="0025" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0025" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0027 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0027" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Political Science </td>
                      <td className='text-center'><input type="number" name="0027" id="0027" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0027" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0231 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0231" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physics </td>
                      <td className='text-center'><input type="number" name="0231" id="0231" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0231" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 1160 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1160" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Project Book (ACCOUNTancy & B.STUDIES) </td>
                      <td className='text-center'><input type="number" name="1160" id="1160" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1160" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 2708 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2708" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Competency Based and Objective Type Questions (English Core, Mathematics, Physics, Chemistry, Biology) (Science Stream) </td>
                      <td className='text-center'><input type="number" name="2708" id="2708" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2708" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 2880 <input type="hidden" name="codeNo[]" id="codeNo[]" value="2880" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Competency Based and Objective Type Questions (English Core, Mathematics, Accountancy, Business Studies, Economics) (Commerce Stream) </td>
                      <td className='text-center'><input type="number" name="2880" id="2880" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="2880" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div> 
            </Col>

            


            {/* <Col>
            
                <center>
                  <button onClick={saveTogetherOrderFrm} className="forget_btn" type="button"> Submit </button>
                  { errorMsg!=='' &&  
                      <div className="alert alert-danger"> {errorMsg} </div>  }
                  { msg!=='' &&
                      <div className="alert alert-success">{msg}  </div>  }

                  { lodar!=='' &&
                  <div disabled><span className="spinner-grow spinner-grow-sm"></span> Loading... </div> }

                  
                </center>
              

             </Col> */}
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={12}> 
        < div className='together-hedi'> SOLVED QUESTION BANK/STUDY MATERIAL FOR CLASSES 9 – 12 (CBSE) </div>
        </Col>
      </Row>
      <Row>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class XII | CBSE Sample Papers (EAD) </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0262 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0262" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Accountancy </td>
                      <td className='text-center'><input type="number" name="0262" id="0262" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0262" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0261 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0261" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Biology </td>
                      <td className='text-center'><input type="number" name="0261" id="0261" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0261" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0264 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0264" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Business Studies </td>
                      <td className='text-center'><input type="number" name="0264" id="0264" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0264" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0260 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0260" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Chemistry </td>
                      <td className='text-center'><input type="number" name="0260" id="0260" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0260" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0257 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0257" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core </td>
                      <td className='text-center'><input type="number" name="0257" id="0257" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0257" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0263 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0263" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Economics </td>
                      <td className='text-center'><input type="number" name="0263" id="0263" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0263" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0258 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0258" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics </td>
                      <td className='text-center'><input type="number" name="0258" id="0258" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0258" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0259 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0259" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physics </td>
                      <td className='text-center'><input type="number" name="0259" id="0259" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0259" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class XII | NCERT solutions </b> </td>
                    </tr>
                    
                    <tr>
                      <td className='text-center'> 1175 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1175" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Biology </td>
                      <td className='text-center'><input type="number" name="1175" id="1175" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1175" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 1176 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1176" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Business Studies </td>
                      <td className='text-center'><input type="number" name="1176" id="1176" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1176" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 1177 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1177" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Chemistry </td>
                      <td className='text-center'><input type="number" name="1177" id="1177" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1177" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    

                    <tr>
                      <td className='text-center'> 1178 <input type="hidden" name="codeNo[]" id="codeNo[]" value="1178" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Economics </td>
                      <td className='text-center'><input type="number" name="1178" id="1178" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="1178" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    
                    

                  </tbody>
                </table>
              </div>         
              
            </Col>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td className='text-center' colSpan={4}> <b> Class XII | CBSE Pariksha </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0180 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0180" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Accountancy </td>
                      <td className='text-center'><input type="number" name="0180" id="0180" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0180" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0299 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0299" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Biology </td>
                      <td className='text-center'><input type="number" name="0299" id="0299" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0299" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0182 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0182" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Business Studies </td>
                      <td className='text-center'><input type="number" name="0182" id="0182" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0182" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0179 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0179" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Chemistry </td>
                      <td className='text-center'><input type="number" name="0179" id="0179" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0179" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0181 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0181" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Economics </td>
                      <td className='text-center'><input type="number" name="0181" id="0181" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0181" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0176 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0176" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Core </td>
                      <td className='text-center'><input type="number" name="0176" id="0176" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0176" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    

                    <tr>
                      <td className='text-center'> 0177 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0177" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics </td>
                      <td className='text-center'><input type="number" name="0177" id="0177" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0177" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0178 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0178" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physics </td>
                      <td className='text-center'><input type="number" name="0178" id="0178" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0178" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    
                    
                  </tbody>
                </table>
              </div>
              
              
            </Col>

            


            
      </Row>

      <Row>
        <Col md={12}> 
        < div className='together-hedi'> SOLVED QUESTION BANK/STUDY MATERIAL FOR CLASSES 9 & 10 (ICSE) </div>
        </Col>
      </Row>
      <Row>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class iX & X Combined </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0189 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0189" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Language </td>
                      <td className='text-center'><input type="number" name="0189" id="0189" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0189" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0190 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0190" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> English Literature </td>
                      <td className='text-center'><input type="number" name="0190" id="0190" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0190" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0191 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0191" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Hindi </td>
                      <td className='text-center'><input type="number" name="0191" id="0191" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0191" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class iX </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0203 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0203" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Biology </td>
                      <td className='text-center'><input type="number" name="0203" id="0203" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0203" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0202 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0202" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Chemistry </td>
                      <td className='text-center'><input type="number" name="0202" id="0202" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0202" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0204 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0204" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Computer Applications </td>
                      <td className='text-center'><input type="number" name="0204" id="0204" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0204" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0206 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0206" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Geography </td>
                      <td className='text-center'><input type="number" name="0206" id="0206" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0206" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0205 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0205" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> History & Civics </td>
                      <td className='text-center'><input type="number" name="0205" id="0205" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0205" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0200 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0200" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics </td>
                      <td className='text-center'><input type="number" name="0200" id="0200" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0200" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0201 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0201" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physics </td>
                      <td className='text-center'><input type="number" name="0201" id="0201" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0201" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    


                    

                  </tbody>
                </table>
              </div>         
              
            </Col>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> Class X </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0195 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0195" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Biology </td>
                      <td className='text-center'><input type="number" name="0195" id="0195" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0195" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0194 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0194" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Chemistry </td>
                      <td className='text-center'><input type="number" name="0194" id="0194" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0194" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0197 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0197" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Computer Applications </td>
                      <td className='text-center'><input type="number" name="0197" id="0197" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0197" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0199 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0199" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Geography </td>
                      <td className='text-center'><input type="number" name="0199" id="0199" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0199" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0198 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0198" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> History & Civics </td>
                      <td className='text-center'><input type="number" name="0198" id="0198" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0198" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0192 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0192" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics </td>
                      <td className='text-center'><input type="number" name="0192" id="0192" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0192" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0193 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0193" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Physics </td>
                      <td className='text-center'><input type="number" name="0193" id="0193" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0193" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
              
              
            </Col>

            


            
      </Row>

      <Row>
        <Col md={12}> 
        < div className='together-hedi'> Competition Books </div>
        </Col>
      </Row>
      <Row>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td className='text-center'> 0219 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0219" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> CTET Part – I </td>
                      <td className='text-center'><input type="number" name="0219" id="0219" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0219" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    

                    

                  </tbody>
                </table>
              </div>         
              
            </Col>
            <Col md={6}> 
              
              
              
            </Col>

            


            
      </Row>


      <Row>
        <Col md={12}> 
        < div className='together-hedi'> Novels (fOR EXTENDED READING) </div>
        </Col>
      </Row>
      <Row>
      <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td className='text-center'> 0222 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0222" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Gulliver's Travels </td>
                      <td className='text-center'><input type="number" name="0222" id="0222" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0222" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0250 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0250" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Silas Marner </td>
                      <td className='text-center'><input type="number" name="0250" id="0250" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0250" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0227 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0227" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Three Men in a Boat </td>
                      <td className='text-center'><input type="number" name="0227" id="0227" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0227" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0225 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0225" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> The Diary of a Young Girl </td>
                      <td className='text-center'><input type="number" name="0225" id="0225" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0225" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
              
              
            </Col>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td className='text-center'> 0228 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0228" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> The Story of My Life</td>
                      <td className='text-center'><input type="number" name="0228" id="0228" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0228" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0223 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0223" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> The Canterville Ghost </td>
                      <td className='text-center'><input type="number" name="0223" id="0223" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0223" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0251 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0251" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> The Invisible Man </td>
                      <td className='text-center'><input type="number" name="0251" id="0251" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0251" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0224 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0224" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Up From Slaver </td>
                      <td className='text-center'><input type="number" name="0224" id="0224" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0224" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
              
              
            </Col>
            

            


            
      </Row>


      <Row>
        <Col md={12}> 
        < div className='together-hedi'> SOLVED QUESTION BANK/STUDY MATERIAL FOR CLASSES 5 – 8 </div>
        </Col>
      </Row>
      <Row>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col"> Code No </th>
                      <th scope="col"> Name of the Title </th>
                      <th scope="col"> QTY. </th>
                      <th scope="col"> Select </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> English PULL-OUT worksheets </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0143 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0143" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Pull-out Worksheets – 5 </td>
                      <td className='text-center'><input type="number" name="0143" id="0143" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0143" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0230 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0230" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> TRM – 5 </td>
                      <td className='text-center'><input type="number" name="0230" id="0230" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0230" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0144 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0144" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Pull-out Worksheets – 6 </td>
                      <td className='text-center'><input type="number" name="0144" id="0144" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0144" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0145 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0145" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> TRM – 6 </td>
                      <td className='text-center'><input type="number" name="0145" id="0145" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0145" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center'> 0146 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0146" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Pull-out Worksheets – 7 </td>
                      <td className='text-center'><input type="number" name="0146" id="0146" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0146" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0147 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0147" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> TRM – 7 </td>
                      <td className='text-center'><input type="number" name="0147" id="0147" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0147" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>


                    <tr>
                      <td className='text-center'> 0148 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0148" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Pull-out Worksheets – 8 </td>
                      <td className='text-center'><input type="number" name="0148" id="0148" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0148" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0149 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0149" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> TRM – 8 </td>
                      <td className='text-center'><input type="number" name="0149" id="0149" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0149" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>

                    

                  </tbody>
                </table>
              </div>         
              
            </Col>
            <Col md={6}> 
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className='onlyfontswith'>
                    <tr className='text-center'>
                      <th scope="col">Code No</th>
                      <th scope="col">Name of the Title</th>
                      <th scope="col">QTY.</th>
					  <th scope="col">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center' colSpan={4}> <b> English Class - VIII </b> </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0129 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0129" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Mathematics </td>
                      <td className='text-center'><input type="number" name="0129" id="0129" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0129" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0132 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0132" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Science </td>
                      <td className='text-center'><input type="number" name="0132" id="0132" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0132" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'> 0135 <input type="hidden" name="codeNo[]" id="codeNo[]" value="0135" /></td>
                      <td style={{maxWidth:'300px', wordBreak:'break-all'}}> Social Science </td>
                      <td className='text-center'><input type="number" name="0135" id="0135" className='cal-quantity' defaultValue="1" min="1" /></td>
                      <td >
                        <div className="check_mark">
                        <label className="checkbox-wrap">
                        <input className='cal-quantity' type="checkbox" name="codeNo" value="0135" id="twCodesNo" onChange={manageCheckBox} />
                        <div className="checkmark"></div>
                        </label>
                        </div>            
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
              
              
            </Col>

            


            
      </Row>




      <Row>
        <Col md={12}> 
        <Col>
            
                <center>
                  <button onClick={saveTogetherOrderFrm} className="forget_btn" type="button"> Submit </button>
                  { errorMsg!=='' &&  
                      <div className="alert alert-danger"> {errorMsg} </div>  }
                  { msg!=='' &&
                      <div className="alert alert-success">{msg}  </div>  }

                  { lodar!=='' &&
                  <div disabled><span className="spinner-grow spinner-grow-sm"></span> Loading... </div> }

                  
                </center>
              

             </Col>
        </Col>
      </Row>

      </Container>
      </form>

    <Footer/>
  </>
  );
};
export default TogetherFrom;
import React,{useContext} from "react";
import Header from "./Front/Coman/Header";
import Navigation from "./Front/Coman/Navigation";
import Footer from "./Front/Coman/Footer";
import Home_page from './Front/Home_Page/Home';
import ImgPopup from './ImgPopup';
import Helmet from "react-helmet";
import { Contaxt } from "../Store";

const Home = ({}) => {   

  const {homePageSeo} = useContext(Contaxt)

  return(
    <div>
      {/* <Loader/> */}
      <Helmet>
          <title>{homePageSeo.title} </title>
          <meta name="description" content={homePageSeo.description} />
          <meta name="keywords" content={homePageSeo.keywords} />

          <meta property="og:title" content={homePageSeo.ogTags.og_title} />
          <meta property="og:description" content={homePageSeo.ogTags.og_description} />
          <meta property="og:url" content={homePageSeo.ogTags.og_url} />          
          <meta property="og:image" content={homePageSeo.ogTags.og_image_url} />

          <meta name="twitter:title" content={homePageSeo.twitterTags.twitter_title} />
          <meta name="twitter:site" content="@rachnasagargrp"/>
          <meta name="twitter:description" content={homePageSeo.twitterTags.twitter_description} />
          <meta name="twitter:image" content={homePageSeo.twitterTags.twitter_image_url} />


      </Helmet>
       <ImgPopup/>
       <Header/>
       <Navigation />
       <Home_page/>
       <Footer/>
    </div>
  );
};
export default Home;

import React, {useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Bio from '../images/bio.jpg';
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";

const LivingChange = () => {
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  useEffect(() => { 
    getSeoData();
    AOS.init({
    offset: 300,
    duration:2000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.livingForChangeSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"livingForChange"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
  <>
  {seoData.isDone &&
    <Helmet>
        <title>{seoData.title} </title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <meta property="og:title" content={seoData.ogTags?.og_title} />
        <meta property="og:description" content={seoData.ogTags?.og_description} />
        <meta property="og:url" content={seoData.ogTags?.og_url} />
        <meta property="og:image" content={seoData.ogTags?.og_image_url} />
        <meta name="twitter:site" content="@rachnasagargrp"/> 
        <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
        <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
        <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
    </Helmet>
  }
  <Header/>
  <Navigation/>
  <div className='branch-bg'>
    <Container fluid className='bioGraphy-main'>
        <h1 className="bioGraphy"> "  LIVING FOR CHANGE  " </h1>
        <div className='biography-section'>
      <br/>
       <Row className='d-flex justify-content-center'>       
        <Col md="9" xs="12">
          <div className='box-shadow' data-aos="fade-up">
            <Row>
              <Col md="3">  <Link to="/meera-balchandran"><img className='mw-100 biography' src={Bio} /></Link> </Col>
              <Col md="9">
              <Link to="/meera-balchandran"><h2 className='biography-hedi'>The Patchwork Quilt</h2></Link>
                <p>In "The Patchwork Quilt" Meera Balachandran founder Principal of Ramjas School RK Puram, today known as Ramjas 
                  <Link to="/meera-balchandran" className='bio-btn'> Read more </Link>               
                </p>
              </Col>
            </Row>
          </div>         
        </Col>
        
        </Row>       
      </div>
    </Container>
  </div>
  <Footer/>
  
  </>
  );
};
export default LivingChange;
import React ,{useState,useEffect,useContext} from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { apiBaseURL, apiURL } from './../../../constant';
import Loader from '../../../Loader';
import { Link } from 'react-router-dom';
//import banner from '../images/banner/banner.jpg';

import { Contaxt } from "../../../Store";

function Banner() {


  const {bannerItems} = useContext(Contaxt);



//=====================================================================================
/*
const [bannerItems,setBannerItems] = useState([]);
const [isLoding, setLoading] = useState(true);

useEffect( () =>{
  
  
  fetch(apiBaseURL+apiURL.banners,{
    method:'get',
    headers:{
      'rskey' : 'rspl'
    }
  } )
    .then( response => response.json() )
    .then( (result) => {
      console.log( result);
      if( result.status==='1' ){
        setBannerItems( result.banner_data );
        

      }else{
        setBannerItems([]);
      }
      setLoading(false);
    } )
    .catch( (error)=>{
      setBannerItems([]);
      setLoading(false);
    } );

    
  
},[] );

*/

//===================================================================================


  return (
    <div className="banner">

   
      {bannerItems.isLoading?
        <div style={{position:'relative', height:'250px'}}>
          <Loader width='100px' height='100px'/>
          </div>
        :
        <Carousel>

        {bannerItems.items.map( (item)=>{   
          return(                     

            <Carousel.Item key={item.id}>
              <Link to={item.prUrl}>
                <img src={item.image}/>
              </Link>
            </Carousel.Item>     
          );
        } )}

      </Carousel>
      }


    

        
      
    </div>
  );
}
export default Banner;


import React,{useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import ProfileLinks from "../Profile/ProfileLinks";

const TrackingDetails = () => {

  const location = useLocation();
  
  console.log(location.state)
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  return(
    <>
      <Header/>
      <Navigation/>
      <ProfileLinks/>
        
        <Container>
          <Row>
            <Col>
              <div className="tracking-detail">
                <h2 className="login-main">Tracking Details</h2>
                <div className="track-box">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="track-hedi">Order No</div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="track-hedi"> : {location.state?.order_id}</div>
                    </div> 
                  </div> 

                  {(location.state.payment_status=='Success')?
                      <>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="track-hedi" style={{color: '#228B22', fontWeight:'bold' }}>Order confirmed</div>
                          </div>                          
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="track-hedi" style={{color: '#FF4500', fontWeight:'bold' }}>We will dispatch your order within two working day.    </div>
                          </div>                          
                        </div>
                      </>
                    :
                    <>
                      <div className="row">
                          <div className="col-sm-12">
                            <div className="track-hedi" style={{color: '#FF4500', fontWeight:'bold' }}>Order pending</div>
                          </div>                          
                        </div>                      
                    </>
                  }

                  {((location.state.trackingNumber!='') && (location.state.courierName!=''))?
                      <>
                        <div className="order-border"> </div>
                        <div className="row">
                          
                          <div className="col-md-4 col-sm-4 col-6">
                            <div className="track-hedi">Traching ID/No </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-6">
                            <div className="track-hedi"> : {location.state?.trackingNumber}</div>
                          </div>  
                        </div>
                        <div className="order-border"> </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-4 col-6">
                            <div className="track-hedi">Courier Name</div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-6">
                            <div className="track-hedi"> :{location.state?.courierName}</div>
                          </div>  
                        </div>
                      </> 
                      : 
                      <>                       
                        
                      </> 
                      
                  }


                 




                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <br/>
      <Footer/>
    </>
  );
};
export default TrackingDetails;



import React from 'react';
import { Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useEffect } from 'react';

function RelatedBooks({relatedBooks, type}) {
useEffect(()=>{
    // console.log(document.getElementById("relatedbook"))
    // console.log("ksjdfks klsdjfkdjsfkjdslkf jskldjf", window.$("#relatedbook").owlCarousel)
    window.$("#relatedbook").owlCarousel({
        items:4,
        itemsDesktop:[1000,3],
        itemsDesktopSmall:[979,3],
        itemsTablet:[768,3],
        pagination:false,
        navigation:true,
        navigationText:["",""],
        autoPlay:true
    });
}, [])
return (
<div className='branch-bg'>
<Container>
    <div className="related_books">
        <h3>Related Books</h3>
        <div id="relatedbook" className="owl_carousel"> 
        {relatedBooks.map((item)=>{
            return(
                // <Link to={"/products/"+item.productId+'?type='+type+'&id='+item.productId} className="box-item" key={item.productId}>
                // <Link to={`/${item.catSlug?item.catSlug:'product'}/${item.slug}?type=${type}&id=${item.productId}`}>
                // <Link to={`/${item.catSlug}/${item.slug}?type=${type}&id=${item.productId}`}>
                <Link to={`/${item.catSlug}/${item.slug}?id=${item.productId}`}>
                    <div className="item"> <img src={item.main_image} />
                    <p className='m-0 mt-2'> 
                        <span> Price - <strong> ₹ {item.book_price}</strong> </span>
                        {item.book_perDiscount!="0"&&
                            <span> 
                                &nbsp; <strike> ₹ {item.book_mrp} </strike>  <span className="bookPer"> &nbsp;  ({item.book_perDiscount}% off) </span>
                            </span> 
                        }
                    </p>
                    </div>
                </Link>
            )
        })}
           
        </div>
    </div>
</Container>
</div>
);

}
export default RelatedBooks;


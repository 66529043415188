import React ,{useState,useEffect,useContext, useRef, useMemo} from "react";
import { Container, Row, Col,} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import logo from  '../images/logo.png';
import toglogo from '../images/together-logo.png';
import { Contaxt } from "../../../Store";


import { apiBaseURL, apiURL, apiKey } from './../../../constant';
// import Loader from "../../../Loader";
import SearchPopup from '../../SearchPopup';
import Dropdown from 'react-bootstrap/Dropdown';

import Marquee from "react-fast-marquee";

const Header = () => {

const {marquee, marqueeSlug, userData, userLogout, cartItmeNum} = useContext(Contaxt);
const [searchPopup, setPopupSearch]=useState({status:false, text:"", list:[], isEmpty:false});
const controller = useRef(null);
const navigate = useNavigate();

function searchBooks(text){
  if(text==""){
    return;
  }
  
  controller.current = new window.AbortController();
  let query = `?SearchKey=${text}`
  fetch(apiBaseURL+apiURL.searchBooks+query,{
    signal:controller.current.signal,
    method:'GET',
    headers:{
        'rskey':apiKey,
    },
  } )
  .then( (result) => {

    result.json().then((res) => {
        console.log(res)
        if (res.status == "1") {
          setPopupSearch((prev)=>{
            return {...prev, list:res.products}
          });    
        } else if(res.status=="0" && res.message=="Book not found.") {
          setPopupSearch((prev)=>{
            return {...prev, list:[], isEmpty:true}
          });    
        }
    });

  }).catch( (err)=>{

    console.log(err);
    // console.log(err.message);
    if(err.name!=="AbortError"){

    }
    // console.log(err.name);
  })

}

function setText(text){
  setPopupSearch((prev)=>{
    return {...prev, text:text}
  });
  if(controller.current){
    controller.current.abort();
    controller.current = null;
  }
    setTimeout(()=>{
      searchBooks(text);
    }, 100)
  
}
function hideList(){
  setTimeout(()=>{
    setPopupSearch((prev)=>{
      return {...prev, status:false}
    })
  }, 300)
}
function closePopup() {
  setPopupSearch((prev)=>{
    return {...prev, status:false, text:"", list:[], isEmpty:false}
  })
}
function openPopup(){
  setPopupSearch((prev)=>{
    return {...prev, status:true}
  })
}
function goToBookPage(e){
  console.log("go to page")
  e.preventDefault();
  if(searchPopup.text==""){
    return;
  }
  closePopup()
  navigate('/books/cbse?search='+searchPopup.text);
}
const bookLists = useMemo(()=>{

  return(
    <>
      {searchPopup.list.map((item)=>{
        return (
          <React.Fragment key={item.productId}> 
          
            {/* <Link to={`/products/${item.productId}?type=p_book`} style={{cursor:"pointer"}} onClick={()=>{closePopup()}}> */}
            <Link to={`/${item.catSlug}/${item.slug}?type=p_book&id=${item.productId}`} style={{cursor:"pointer"}} onClick={()=>{closePopup()}}>
            {/* <Link to={`/${item.catSlug?item.catSlug:'product'}/${item.slug}?type=${type}&id=${item.productId}`}>  </Link> */}
            <Row>
              <Col md={1} sm={1} xs={2}  onClick={()=>{console.log("sdfs")}}> <img src={item.main_image}/> </Col>  
              <Col md={11} xs={10}><p className='booklist-desi'> {item.product_title} </p> </Col> 
            </Row>
            </Link>
            <div className='order-border'> </div>
          </React.Fragment>
        )
      })
      }
    </>
  )
}, [searchPopup.list])
  return(
    <div className="App">

    <Row>
      
    <div className="top-bar">
    <center> 
      <Col md={6} xs={11}> 
        <Marquee direction="left" speed={48} pauseOnHover>
        <Link to={marqueeSlug}>
          <p> {marquee}    </p>
          </Link>
        </Marquee>
      </Col>
    </center>
    </div>

    </Row>

      <Container fluid>
        <div className="logo-section">
          <Row>
            <Col md={3} sm={12} col={4}>
             <div className="logo"> <Link to="/"> <img src={logo}/> </Link>  </div> 
            </Col>
            <Col md={6} sm={6}> 
              <div className="searchpopup">
                  <form onSubmit={(e)=>{goToBookPage(e)}} autoComplete="Off" className="displyflex" > 
                    <input type="text" placeholder="Search.." name="search" onChange={(e)=>{setText(e.target.value)}} value={searchPopup.text} onFocus={openPopup} onBlur={hideList}/>
                    <button type="submit"><i className="fa fa-search"></i></button>
                  </form>
                  {searchPopup.status&&
                    <div className='book-list'>
                      {searchPopup.list?.length===0 && searchPopup.isEmpty &&
                        <div style={{minHeight:"50px", textAlign:"center", paddingTop:"10px"}}>
                          No book found!
                        </div>
                      }
                      {bookLists}
                    </div>
                  }
                </div>
            </Col>
            <Col md={3} sm={6} className="right-login">
            {/* <span className="search"  onClick={()=>{openPopup()}}><i className="fa fa-search" aria-hidden="true"></i></span> */}
            <span className="cart"> 
              <Link to="/orderDetail"> <i class="fa fa-shopping-cart chatsho" aria-hidden="true"></i> </Link>
              <span className="cartcount" style={{display:cartItmeNum==0?'none':'inline'}}> {cartItmeNum} </span>
            </span>   

              {userData.loginStatus?
                <>
                  {userData.isLoading?
                  <div style={{textAlign:'center', margin:"2px 12px 0 0", display:'inline'}}>
                    <span className="spinner-grow spinner-grow-sm" ></span>
                  </div>
                      :
                      <Dropdown style={{display:"inline"}}>
                        <Dropdown.Toggle id="dropdown-basic" className="login">
                        {userData.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item  onClick={userLogout}>Logout</Dropdown.Item>
                          <Dropdown.Item ><Link to='/profile/account' ><span> Profile </span></Link></Dropdown.Item>
                          <Dropdown.Item><Link to='/profile/wishlist' ><span> Wislist </span></Link> </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                  }
                </>
                // <span >
                //     {userData.isLoading?
                //     <div style={{position:'relative', height:'40px', width:'40px', display:"inline-block"}}>
                //       <Loader width="20px" height="20px"/> 
                //     </div>:
                //      <>
                //       <Link to='/profile/account' ><span className="username">{userData.name}</span></Link>
                //       <span onClick={userLogout} className="login">Logout</span>
                //     </>
                //   }   
                //   </span>
                  :
              <Link to="/account/login">
                <button type="button" className="login">Login &nbsp;<i className="fa fa-user favi" aria-hidden="true"></i></button>
              </Link>
              }
              <span className="together"> <img src ={toglogo}/> </span>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default Header;



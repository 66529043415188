import React ,{useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";

import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import Accordion from 'react-bootstrap/Accordion';

import Contactleft from '../images/contact-left.png';
import Contactright from '../images/contact-right.png';
import { apiURL, apiBaseURL } from '../../../constant';
import Loader from "../../../Loader";

const Contactus = () => {

  const [formData, setFormData] = useState({
    firstName:"",
    lastName:"",
    email:"",
    mobile:"",
    msg:""
  });
  const [error, setError] = useState({status:false, msg:""});
  const [isLoading, setLoading] = useState(false);

  useEffect( () =>{
      AOS.init({
        offset: 300,
        duration: 1000,
      });
  },[] );

 function sendData(event){

  event.preventDefault();
  event.stopPropagation();

  if(formData.firstName==""){
    setError({status:true, msg:"please fill the first name"})
    return;
  } else if(formData.lastName==""){
    setError({status:true, msg:"please fill the last name"})
    return;
  } else if(formData.email==""){
    setError({status:true, msg:"please fill the email"})
    return;
  }  else if(formData.mobile==""){
    setError({status:true, msg:"please fill the mobile number"})
    return;
  } else if(formData.msg==""){
    setError({status:true, msg:"please fill the message"})
    return;
  } 

  setError({status:false, msg:""})
  setLoading(true);
  const data = {
    "firstname":formData.firstName,
    "lastname":formData.lastName,
    "email":formData.email,
    "mobile":formData.mobile,
    "message":formData.msg
  } 
  fetch(apiBaseURL+apiURL.contactUs,{
    method:'POST',
    headers:{
      'rskey' : 'rspl',
    },
    body:JSON.stringify(data)
  } )
    .then( response => response.json() )
    .then( (result) => {
      console.log(result)
      setLoading(false)
      if(result.status=="1"){
        
        setFormData({
          firstName:"",
          lastName:"",
          email:"",
          mobile:"",
          msg:""
        })
        setError({status:true, msg:"Message successfully submitted!"})
      } else {
        // show error
        
        setError({status:true, msg:result.message})
      }
    })
    .catch( (error)=>{
       console.log(error)
      //  show error
      setError({status:true, msg:"network error try again!"})
      setLoading(false)
    } );

 }

 function setData(val, type){

  setFormData((prev)=>{

    return {...prev, [type]:val}
  })
 }

  return(
    <div className="App">
      <Header/>
      <Navigation/>
      <div className="contact-us">
        <div className="branch-bg">
          <Container>
            <Row>
              <Col md={3} sm={2}>
                  <img src={Contactleft} /> 
              </Col>
              <Col md={6} sm={8}>
                <h1> Contact </h1>
                <p data-aos="fade-right">If you have any questions about our products or services, please call one of our numbers or send us an email.   
                  We welcome your suggestions to and feedback.
                </p>
              </Col>
              <Col md={3} sm={2}>
                <img src={Contactright} /> 
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <div className="add-from">
          <Row>
            <Col md={6} sm={6} data-aos="fade-up">
              <address>
                <b>Head Office</b><br/>
                <b>Rachna Sagar Pvt. Ltd.</b><br/>
                  4583/15, Daryaganj, New Delhi,110002(INDIA)<br/>
                  +91-11-43585858, 23285568, 23243519
              </address>
              <address>
                <b>For Export Queries :</b><br/>
                <a href=""> export@rachnasagar.in </a>
                </address>
                <address>
                <b>For Online Order/Customer Queries :</b> <br/>
                <a href="">ecommerce@rachnasagar.in</a>
                </address>
                <address>
                <b>For books to Book Related Queries / Errors :</b> <br/>
                <a href="">editorial@rachnasagar.in</a>
                </address>
                <address>
                <b>For Specimen Queries :</b> <br/>
                <a href=""> specimen@rachnasagar.in</a>
                </address>
            </Col>
            <Col md={6} sm={6} data-aos="fade-up">
              <form onSubmit={sendData}>
                <Row style={{textAlign:"center"}}>
                  { error.status && <span className="errMgs">{error.msg}</span> }
                </Row>
                <Row className="form-row">
                  <div className="form-group col-md-6">
                      <label>First Name</label>
                      <input type="name" className="form-control form-contact"  value={formData.firstName}  onChange={(e)=>{setData(e.target.value, "firstName")}} />
                     
                  </div>
                  <div className="form-group col-md-6">
                      <label>Last Name</label>
                      <input type="name" className="form-control form-contact"  value={formData.lastName}  onChange={(e)=>{setData(e.target.value, "lastName")}} />
                  </div>
                </Row>
                <Row className="form-row">
                  <div className="form-group col-md-6">
                    <label>Email *</label>
                    <input type="email" className="form-control form-contact"  value={formData.email}  onChange={(e)=>{setData(e.target.value, "email")}} />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Mobile *</label>
                    <input type="text" className="form-control form-contact"  value={formData.mobile}  onChange={(e)=>{setData(e.target.value, "mobile")}} />
                  </div>
                </Row>
                <div className="form-group">
                  <label>Message</label>
                  <textarea className="form-control form-contact" rows="3"  value={formData.msg} onChange={(e)=>{setData(e.target.value, "msg")}}></textarea> 
                </div>
                <button type="submit" className="contact-btn">Send</button>
                {/* <div style={{position:'relative', height:"45px", width:"128px"}}>
                  <Loader width="38px" height="38px"/>
                </div> */}
                {isLoading &&
                  <Loader width="38px" height="38px"/>
                }
              </form>
            </Col>
          </Row>
        </div>
        <div className="add-from">
          <Row>
            <Col md={6}>
            <b>Office Hours :</b> <br/>
            Monday - Saturday: 9:30 AM - 6:00 PM <br />
            Second Saturday is closed <br />
            We are available/ reachable in all other states.
            </Col>
            <Col md={6}>
              
            </Col>
          </Row>
        </div>
        </Container>
      </div>
    <div className="branch-bg">
      <div className="container-fluid back_width">
      <Row>
      <div className="col-md-12">
          <div className="Off-branch">Branch Offices</div>
        </div>
        <Col md={4}>
          <Accordion>
            <Accordion.Item eventKey="0" >
              <div className='bor'>
                <Accordion.Header>    Dehradun </Accordion.Header>
                <Accordion.Body>  
                  <b>Rachna Sagar Pvt. Ltd.<br/>
                    I-15, Nehru Colony<br/>
                    Dehradun-248001 (Uttarakhand)<br/>
                    Mob: 7388933938<br/>
                    Email:- dehradun.rspl@gmail.com </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <div className='bor'>
                <Accordion.Header> Jaipur  </Accordion.Header>
                <Accordion.Body> 
                  <b>
                  Rachna Sagar Pvt. Ltd.<br/>
                  SHIVAM APPARTMENT<br/>
                  Flat No. G-1,<br/>
                  Plot No.B-1/ 564,<br/>
                  Akshar Dham Chauraha,<br/>
                  Chitrakoot, Vaishali Nagar,<br/>
                  Jaipur(Rajasthan)<br/>
                  Pin Code-302021<br/>
                  Mob: 09799999123<br/>
                  Email:- jaipur.rspl@gmail.com
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <div className='bor'>
                <Accordion.Header> Lucknow  </Accordion.Header>
                <Accordion.Body> 
                    <b>
                    Rachna Sagar Pvt. Ltd.<br/>
                    C-1454, Indira Nagar,<br/>
                    Lucknow-226016 (Uttar Pradesh)<br/>
                    Mob: 09794852855<br/>
                    Ph: 0522-4004909<br/>
                    Email:- lucknow.rspl@gmail.com
                    </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <div className='bor'>
                <Accordion.Header> Patna   </Accordion.Header>
                <Accordion.Body> 
                    <b>
                      Rachna Sagar Pvt. Ltd.<br/>
                      4H/41,Bahadur pur Housing Colony,<br/>
                      Bhootnath Road,Kankarbag<br/>
                      Patna-800026, (Bihar)<br/>
                      Mob: 09771441611<br/>
                      Ph: 09771441611<br/>
                      Email:- patna.rspl@gmail.com
                    </b>   
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <div className='bor'>
                <Accordion.Header> Mohali  </Accordion.Header>
                <Accordion.Body>  
                  <b>
                    Rachna Sagar Pvt. Ltd.<br/>
                    Plot No. BMM-36<br/>
                    First Floor, Bulk Material Market,<br/>
                    Phase 11, Sector 65,SAS Nagar<br/>
                    Mohali, Punjab - 1600629<br/>
                    Phone : 9646633300
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
          </Accordion>
        </Col>

        <Col md={4}>
        <Accordion >
            <Accordion.Item eventKey="0" >
              <div className='bor'>
                <Accordion.Header> Ahemedabad </Accordion.Header>
                <Accordion.Body>  
                  <b>
                  Rachna Sagar Pvt. Ltd.<br/>
                  25, Avani Bungalow and Row house	<br/>
                  Behind D mart, Motera<br/>
                  Ahmedabad (Gujarat) <br/>
                  Pin code -  380005<br/>
                  Mob : 9924645576<br/>
                  Email:- ahmedabad.rspl@gmail.com
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <div className='bor'>
                <Accordion.Header> Ranchi </Accordion.Header>
                <Accordion.Body> 
                  <b>
                    Rachna Sagar Pvt. Ltd.<br/>
                    1360 - Bali Bagicha, Old Argora Road Argora<br/>
                    Ranchi - 834002, (Jharkhand)<br/>
                    Mob:9771441621<br/>
                    Email:- ranchi.rspl@gmail.com
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <div className='bor'>
                <Accordion.Header> Kolkata </Accordion.Header>
                <Accordion.Body> 
                  <b>
                    Rachna Sagar Pvt. Ltd.<br/>
                    220, Bipin Ganguly Road, Dum Dum, Kolkata-700030 (West Bengal)<br/>
                    Mob: 09330102176<br/>
                    Email:- westbengal.rspl@gmail.com
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <div className='bor'>
                <Accordion.Header> Bhopal  </Accordion.Header>
                <Accordion.Body> 
                  <b>
                    Rachna Sagar Pvt. Ltd.<br/>
                    E-6/127, Ground Floor<br/>
                    Arera Colony,<br/>
                    Bhopal-462016 (Madhya Pradesh)<br/>
                    Mob: 09752593355<br/>
                    Ph: 0755-4223838<br/>
                    Email:- bhopal.rspl@gmail.com
                  </b>                    
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <div className='bor'>
                <Accordion.Header> Mumbai  </Accordion.Header>
                <Accordion.Body>  
                    <b>
                      Rachna Sagar Pvt. Ltd.<br/>
                      Flat No- 203 2nd Floor,<br/>
                      Anmol Annexie CTS No- 98/99,<br/>
                      Dhobi Ali Near Civil Hospital Thane West<br/>
                      Mumbai – 400602 (Maharashtra)<br/>
                      Mob: 08108448884,08425869445<br/>
                      Email:- mumbai.rspl@gmail.com
                    </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
          </Accordion>
        </Col>

        <Col md={4}>
        <Accordion >
            <Accordion.Item eventKey="0" >
              <div className='bor'>
                <Accordion.Header> Hyderabad  </Accordion.Header>
                <Accordion.Body>  
                  <b>
                  Rachna Sagar Pvt Ltd <br/>
                  12-5-151/2, Vijayapuri Colony, <br/>
                  South Lalaguda, <br/>
                  Secunderabad- 500017 (Telangana)<br/>
                  Tel: 9100914234
                  Email:- hyderabad.rspl@gmail.com
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <div className='bor'>
                <Accordion.Header> Bengaluru  </Accordion.Header>
                <Accordion.Body> 
                  <b>
                    Rachna Sagar Pvt. Ltd.<br/>
                    90/7 &amp; 90/8, First Floor<br/>
                    1st Cross, Vittal Nagar, Mysore Road,<br/>
                    Bengaluru-560026 (Karnataka)<br/>
                    Mob: 9008557707<br/>
                    Ph: 080-26747475/76<br/>
                    Email:- rachnasagarblr@hotmail.com
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <div className='bor'>
                <Accordion.Header> Chennai </Accordion.Header>
                <Accordion.Body> 
                  <b>
                    Rachna Sagar Pvt. Ltd.<br/>
                    Old No. 18, New No. 80<br/>
                    Ramar Koil Street(Opp. Chennai Trade Centre<br/>
                    Nandambakkam Chennai-600089 (Tamil Nadu) <br/>
                    Mob: 08754580793<br/>
                    Email:- chennai.rspl@gmail.com
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <div className='bor'>
                <Accordion.Header> Cochin </Accordion.Header>
                <Accordion.Body> 
                  <b>
                    Rachna Sagar Pvt. Ltd. <br/>
                    House Number- 1007/A2 , <br/>
                    Sahridaya Nagar road , <br/>
                    Edappally – Cochin - 682024, <br/>
                    Kerala <br/>
                    Phone :- 7356122773 <br/>
                    Email :- cochin.rspl@gmail.com
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <div className='bor'>
                <Accordion.Header> Guwahati   </Accordion.Header>
                <Accordion.Body>  
                  <b>
                    Rachna Sagar Pvt. Ltd.<br/>
                    House no.-03, Fatasil Ambari, Rang Pathar<br/>
                    Bylane name - Uday Nagar path<br/>
                    Guwahati, Assam-781025<br/>
                    Phone: 7086090866
                  </b>
                </Accordion.Body>
              </div>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      </div>
    </div>
    <Container fluid> 
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.361940246237!2d77.24142691549545!3d28.648879490090774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcdf6e71a4cd%3A0x5e265d0812f9fc39!2sRachna%20Sagar%20Private%20Limited!5e0!3m2!1sen!2sin!4v1642670359161!5m2!1sen!2sin" style={{width:"100%", height:"400px" , border: "2px solid #dfd9d9"}}  allowfullScreen="" loading="lazy"></iframe>
    </Container>
      <Footer/>
    </div>
  );
};
export default Contactus;



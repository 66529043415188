import React from "react";
import  './Error.css';

function Error({msg="", fun=undefined, cancle, btnName="Reload", canName="Cancel"}){
    return (
      <div className="arror-bg">
        <div className="main-arror">
            <h1> {msg} </h1> 
            <i className="fa fa-exclamation-circle errow-arror" aria-hidden="true"></i>
            <div className="row">
              <div className="arror-contant">
                {fun!=undefined&&
                  <button className="arror-btn"  type="button" onClick={fun}> {btnName} </button>
                }
                <button className="arror-btn"  type="button" onClick={cancle}> {canName}  </button>
              </div>
            </div>
        </div>
      </div>
  )
}
export default Error;



import React , {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { apiBaseURL, apiURL} from "../../../constant";

import Form from 'react-bootstrap/Form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const Sign_up = () => {

  const [name,setName]=useState("");
  const [mobile,setMobile]=useState("");
  const [email,setEmail]=useState("");
  const [password,setPassword]=useState("");

  const [address,setAddress]=useState(""); 
  const [city,setCity]=useState("");
  const [state,setState]=useState("");
  const [country,setCountry]=useState("India");
  const [zip,setZip]=useState("");
  const [usertype,setUsertype]=useState("student");

  const [ername, setErname] = useState("");
  const [ermobile, setErmobile] = useState("");
  const [eremail, setEremail] = useState("");
  const [erpassword, setErpassword] = useState("");
 
  const [eraddress, setEraddress] = useState("");
  const [ercity, setErcity] = useState("");
  const [erstate, setErstate] = useState("");
  const [ercountry, setErcountry] = useState("");
  const [erzip, setErzip] = useState("");
  const [erusertype, setErusertype] = useState("");
  
  const [errorMsg, setErrorMsg] = useState("");

  const [msg, setMsg] = useState("");
  const [lodar, setLodar] = useState("");

  function saveUser()
  {   
      
      //alert(name);
      let data = {name,mobile,email,address,city,state,country,zip,usertype,password}
      console.warn(data) 
      
      setEraddress("");
      setErcity("");
      setErstate("");
      setErcountry("");
      setErzip("");
      setErusertype("");                     
    
      setErname("");
      setErmobile("");
      setEremail("");
      setErpassword("");
      
      setLodar("Loading...");
      if( name!='' && mobile !='' && email !='' && address!='' && city!='' && state!='' && zip!=''  && password!='' ){
        

          fetch(apiBaseURL+apiURL.signup,{
              method:'POST',
              headers:{
                  'rskey':'rspl'
              },
              body:JSON.stringify( data )
          } )
          .then( (result) => {
              //setErrorMsg("Data Save!");
              console.log(result);

              //=====================================
              result.json().then((data) => {
                    if (data.status == "1") {
                      
                      /* 
                      setEraddress("");
                      setErcity("");
                      setErstate("");
                      setErcountry("");
                      setErzip("");
                      setErusertype("");                     
                    
                      setErname("");
                      setErmobile("");
                      setEremail("");
                      setErpassword("");
                        */  
                        
                        setName("");
                        setMobile("");
                        setEmail("");
                        setPassword("");

                        setAddress("");
                        setCity(""); 
                        setState("");                       
                        setZip("");


                        setErrorMsg("");

                        setMsg(data.message);
                        console.log(data.message.data);
                        setLodar("");


                        setTimeout(function() {
                            setMsg("");
                        }, 3000);


                      //setMsg("Your feedback has been successfully submitted thank you.");
                    } else {
                        
                                        


                        setErrorMsg(data.message);

                        console.log(data);
                        
                        //console.log(data.errors);
                        setLodar("");
                    }
              });
              //====================================


          } ).catch( (err)=>{
              setErrorMsg("Failed to fetch");
              alert(err);
              console.log(err);
          } )
      }else{
        

          if(name==""){
            setErname("Name required");
          }
          if(mobile==""){
            setErmobile("Mobile required");
          }
          if(email==""){
            setEremail("Email Id required");
          }
          if(password==""){
            setErpassword("Password required");
          }
          if(address==""){
            setEraddress("Address required");
          }
          if(city==""){
            setErcity("City required");
          }
          if(state==""){
            setErstate("State required");
          }
          if(zip==""){
            setErzip("Zip required");
          }    
   
        setLodar("");

      }
  }

  return(
    <div className="App">
      <Header/>
      <Navigation/>
      <div className="branch-bg">
        <Container>
          <Row>
            <Col>
            <div className="sign_bg">
            <h1 className="descri_hedi">CREATE ACCOUNT</h1>
            <form className="sign_up">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="login-hedi">Name *</label>
                    <input type="name" name="name" id="name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control login-mar"  placeholder="" />
                    { ername!=='' &&    <span className="errMgs">{ername}</span> }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="login-hedi">Mobile *</label>
                    <input type="text" name="mobile" id="mobile" value={mobile} onChange={(e)=>setMobile(e.target.value)} className="form-control login-mar"  placeholder="" />
                    { ermobile!=='' &&    <span className="errMgs">{ermobile}</span> }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="login-hedi">Email *</label>
                    <input type="text" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control login-mar"  placeholder="" />
                    { eremail!=='' &&    <span className="errMgs">{eremail}</span> }
                  </div>
                </div>
                
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="login-hedi">Address *</label>
                    <textarea name="address" id="address" value={address} onChange={(e)=>setAddress(e.target.value)} className="form-control login-mar" rows="1"></textarea>
                    { eraddress!=='' &&    <span className="errMgs">{eraddress}</span> }
                  </div>
                </div>
              </div> 
              
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="login-hedi">City *</label>
                    <input type="text" name="city" id="city" value={city} onChange={(e)=>setCity(e.target.value)} className="form-control login-mar"  placeholder="" />
                    { ercity!=='' &&    <span className="errMgs">{ercity}</span> }
                  </div>
                </div>
              </div>

              <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="login-hedi">State *</label>
                      <input type="name" name="state" id="state" value={state} onChange={(e)=>setState(e.target.value)} className="form-control login-mar"  placeholder="" />
                      { erstate!=='' &&    <span className="errMgs">{erstate}</span> }
                    </div>
                  </div>
                    
                  <div className="col-md-6">
                    <div className="form-group">
                    <label className="login-hedi">Country *</label>
                      <Form.Select name="country" id="country" onChange={(e)=>setCountry(e.target.value)} className="form-control login-mar">
                        <option value="India">India</option>
                        
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua">Antigua</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="BAHRAIN">BAHRAIN</option>
                        <option value="BANGLADESH">BANGLADESH</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="BOTSWANA">BOTSWANA</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="China">China</option>
                        <option value="Croatia">Croatia</option>
                        <option value="CYPRUS">CYPRUS</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="DJIBOUTI">DJIBOUTI</option>
                        <option value="EGYPT">EGYPT</option>
                        <option value="Estonia">Estonia</option>
                        <option value="ETHIOPIA">ETHIOPIA</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="GHANA">GHANA</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Guam">Guam</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Hong  Kong">Hong  Kong</option>
                        <option value="Hungary">Hungary</option>
                        
                        <option value="Indonesia">Indonesia</option>
                        <option value="IRAQ">IRAQ</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Italy">Italy</option>
                        <option value="Japan">Japan</option>
                        <option value="JORDAN">JORDAN</option>
                        <option value="KENYA">KENYA</option>
                        <option value="KUWAIT">KUWAIT</option>
                        <option value="LEBANON">LEBANON</option>
                        <option value="LIBYA">LIBYA</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="MAURITIUS">MAURITIUS</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Monaco">Monaco</option>
                        <option value="MOROCCO">MOROCCO</option>
                        <option value="MOZAMBIQUE">MOZAMBIQUE</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="NIGERIA">NIGERIA</option>
                        <option value="Norway">Norway</option>
                        <option value="OMAN">OMAN</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Saipan">Saipan</option>
                        <option value="SAUDI ARABIA">SAUDI ARABIA</option>
                        <option value="Singapore">Singapore</option>
                        <option value="SOUTH AFRICA">SOUTH AFRICA</option>
                        <option value="South Korea">South Korea</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="SUDAN">SUDAN</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="SYRIA">SYRIA</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="TANZANIA">TANZANIA</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Turkey">Turkey</option>
                        <option value="UAE">UAE</option>
                        <option value="UGANDA">UGANDA</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="ZAMBIA">ZAMBIA</option>
                        <option value="ZIMBABWE">ZIMBABWE</option>
                      </Form.Select>
                      { ercountry!=='' &&    <span className="errMgs">{ercountry}</span> }
                    </div>
                  </div>

                {/* <div className="col-md-6">
                <div className="form-group">
                <label className="login-hedi">Country *</label>
                <input type="name" name="country" id="country" value={country} onChange={(e)=>setCountry(e.target.value)} className="form-control login-mar"  placeholder="" />
                { ercountry!=='' &&    <span className="errMgs">{ercountry}</span> }
                  </div>
                </div> */}
              </div>

              <div className="row">          
                <div className="col-md-6">
                    <div className="form-group">
                      <label className="login-hedi">Zip / Pincode  *</label>
                      <input type="name" name="zip" id="zip" value={zip} onChange={(e)=>setZip(e.target.value)} className="form-control login-mar"  placeholder="" />
                      { erzip!=='' &&    <span className="errMgs">{erzip}</span> }
                    </div>
                  </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="login-hedi"> Password *</label>
                    <input type="name" name="password" id="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control login-mar" placeholder="" />
                    { erpassword!=='' &&    <span className="errMgs">{erpassword}</span> }
                  </div>
                </div>
                
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="login-hedi"> You are : Student / Teacher *</label>
                    <input type="name" name="usertype" id="usertype" value={usertype} onChange={(e)=>setUsertype(e.target.value)} className="form-control login-mar" placeholder="" />
                    { erusertype!=='' &&    <span className="errMgs">{erusertype}</span> }
                  </div>
                </div> */}

                <div className="col-md-12">
                  <div className="form-group">
                    <label className="login-hedi"> You are *</label>
                      <div>
                        <FormControl>
                          <RadioGroup 
                            row aria-labelledby="demo-row-radio-buttons-group-label" defaultValue="student" name="row-radio-buttons-group">
                            <FormControlLabel name="usertype" id="usertype" value="student" onChange={(e)=>setUsertype(e.target.value)} control={<Radio />} label="Student" />
                            <FormControlLabel name="usertype" id="usertype" value="parents" onChange={(e)=>setUsertype(e.target.value)} control={<Radio />} label="Parents " />
                            <FormControlLabel name="usertype" id="usertype" value="teacher" onChange={(e)=>setUsertype(e.target.value)} control={<Radio />} label="Teacher " />
                            <FormControlLabel name="usertype" id="usertype" value="school" onChange={(e)=>setUsertype(e.target.value)}  control={<Radio />} label="School"/>
                          </RadioGroup>
                        </FormControl>

                        { erusertype!=='' &&    <span className="errMgs">{erusertype}</span> }
                      </div>
                  </div>
                </div>

              </div>
              <div className="row">
            <div className="col-md-12">
              <center> <br/>
                <button  onClick={saveUser} className="sign-btn" type="button">Create Account</button>
                </center>
                { errorMsg!='' &&
                    <div className="alert alert-danger">
                        {errorMsg}
                    </div>
                }
                { msg!=='' &&
                    <div className="alert alert-success">
                        {msg}
                    </div>
                }
                { lodar!=='' &&
                <div disabled>
                  <span className="spinner-grow spinner-grow-sm"></span>
                  Loading..
                </div> }
            </div>
            </div>
            </form>
          </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
};
export default Sign_up;



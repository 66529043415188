import React , {useState,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { apiBaseURL, apiURL, apiKey} from "../../../constant";
import dist from '../images/dist.jpg'; 
import Helmet from "react-helmet";

const Become_distributor = () => {


  const [seoData, setSeoData] = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })

  const [name,setName]=useState("");
  const [mobile,setMobile]=useState("");
  const [email,setEmail]=useState("");
  const [comments,setComments]=useState("");


  const [ername, setErname] = useState("");
  const [ermobile, setErmobile] = useState("");
  const [eremail, setEremail] = useState("");
  const [ercomments, setErcomments] = useState("");
 


  const [errorMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [lodar, setLodar] = useState("");
  
  useEffect(()=>{
    getSeoData()
  }, [])
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.becomeDistutorSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"becomedistributor"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
      }

    } catch (error) {
      console.log(error)
    }
  }
  function saveBecomeDistributor()
  {       
      
      let data = {name,mobile,email,comments}
      console.warn(data)  
      
      setLodar("Loading...");

      setErname("");
      setErmobile("");
      setEremail("");
      setErcomments("");

      if( name!=='' && mobile !=='' && email !=='' && comments!=='' ){

          fetch(apiBaseURL+apiURL.distributors,{
              method:'POST',
              headers:{
                  'rskey':'rspl'
              },
              body:JSON.stringify( data )
          } )
          .then( (result) => {
              setErrorMsg("");
              console.log(result);


              result.json().then((data) => {
                    if (data.status == "1") {
                        

                        setErrorMsg("");
                        setName("");
                        setMobile("");
                        setEmail("");
                        setComments("");                       
                       

                        setMsg(data.message);
                        setLodar("");

                        setTimeout(function() {
                            setMsg("");
                        }, 3000);

                        console.log(data.message.data);
                      
                    } else {
                        setErrorMsg(data.message);                       

                        setLodar("");
                        
                        console.log(data.errors);
                    }
              });

          } ).catch( (err)=>{
              setErrorMsg("Failed to fetch");
              alert(err);
              console.log(err);
          } )
      }else{
          //setErrorMsg("Loading...");
          //alert("dfghfdjk");

          if(name==""){
            setErname("Name required");          
          }
          if(mobile==""){
            setErmobile("Mobile required");          
          }
          if(email==""){
            setEremail("Email Id required");
          }
          if(comments==""){
            setErcomments("Comments required");       
          }
          


          setLodar("");

          
      }


  }

  return(
    <div className="App">
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
        <div className="branch-bg">
          <Container>
            <div className="become_distr">
                <Row>
                    <Col>
                      <center> <h1>BECOME A DISTRIBUTOR</h1> </center>
                      <div className="dis_para">
                        <Row>
                          <Col md={2}> 
                            <img src={dist} />
                          </Col>
                          <Col>
                            <p> We strive to align our goals and values with those of our clients and customers. In partnership with you, we can make an important contribution to society.
                              We are looking for an exclusive distributor who can promote our titles, for promotion and our activities please contact our 
                            <b> General Manager Mr. Sanjay Garg </b> at <a href="mailto:sanjaygarg@rachnasagar.in">sanjaygarg@rachnasagar.in</a> </p>
                          </Col>
                        </Row>
                        <hr/>
                        <Row>
                          <Col>
                            <div className="form-distributor">
                              <form id="form-dis">
                                <div className="form-group">
                                  <label className="login-hedi">Name :</label>
                                  <input type="name" name="name" id="name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control login-mar" placeholder="Enter name" />
                                  { ername!=='' &&    <span className="errMgs">{ername}</span> }
                                </div>
                                <div className="form-group">
                                  <label className="login-hedi">Mobile No :</label>
                                  <input type="name" name="mobile" id="mobile" value={mobile} onChange={(e)=>setMobile(e.target.value)} className="form-control login-mar" placeholder="Enter mobile" />
                                  { ermobile!=='' &&    <span className="errMgs">{ermobile}</span> }
                                </div>
                                <div className="form-group">
                                  <label className="login-hedi">Email Id :</label>
                                  <input type="name" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control login-mar" placeholder="Enter email" />
                                  { eremail!=='' &&    <span className="errMgs">{eremail}</span> }
                                </div>
                                <div className="form-group"><label className="login-hedi">Comments *</label>
                                  <textarea name="comments" id="comments" value={comments} onChange={(e)=>setComments(e.target.value)} className="form-control login-mar" rows="2"></textarea>
                                  { ercomments!=='' &&    <span className="errMgs">{ercomments}</span> }
                                </div>
                                <div className="col-md-12">
                                  <center>
                                  <button onClick={saveBecomeDistributor} className="forget_btn" type="button"> Submit </button>
                                  { errorMsg!='' &&
                                      <div className="alert alert-danger">
                                          {errorMsg}
                                      </div>
                                  }
                                  { msg!='' &&
                                      <div className="alert alert-success">
                                          {msg}
                                      </div>
                                  }                                 

                                  { lodar!=='' &&
                                  <div disabled>
                                    <span className="spinner-grow spinner-grow-sm"></span>
                                    Loading..
                                  </div> }

                                  </center>
                                </div>
                              </form>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                </Row>
              </div>
            </Container>
        </div>
      <Footer/>
    </div>
  );
};
export default Become_distributor;



import React,{useState, useEffect, useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { Contaxt } from "../../../Store";

import Loader from '../../../Loader';

import { apiBaseURL, apiURL, apiKey } from "../../../constant";

import { Link, useSearchParams} from 'react-router-dom';



const ViewOrder = () => {
  //==========================================================================
  //==========================================================================
  const [queryString] = useSearchParams();

  const [OrderList,setOrderList] = useState([]);


  const [userOrderLine, setUserOrderLine]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:true,
  })
  
  useEffect(() => { 

    let action = queryString.get("action")    
    let oId = queryString.get("oId")
    //let type = queryString.get("type")
    //let data = queryString.get("data")

    console.log(action);
    console.log(oId);
    //console.log(data);

    getViewOrderData(oId);

  }, []);


  async function getViewOrderData(oId){
    try {
      let response = await  fetch(apiBaseURL+apiURL.vieworderFmail,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"action":"vieworder","oId":oId})
          //body:JSON.stringify( vLine )
        });
      let result = await response.json();
      if(result.status=="1"){
        
        setUserOrderLine({ 
          TotalItems:result.Total_Items,
          message:result.message,          
          OrderData:result.Order_Data,
          //OrderDetails:result.Order_Details,
          isDone:false,
        })

        

        setOrderList(result.Order_List?result.Order_List:[]);


      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }

  return(
   <>
    <Header/>
    <Navigation/>
    <div className="branch-bg">

        
          <Container>
            <Row>
            {userOrderLine.isDone ? 
            <div style={{position:'relative', height:'200px'}}>
            <Loader width="80px" height="80px" backgroundColor="transparent"/>
            </div>:
            <>
              <Col md={8}>
                <div className="invioce-section">
                    <div> 
                      <i className="fa fa-truck favitruck" aria-hidden="true"></i> 
                      <span className="delived">Delivered</span>  
                      <span className="small-hedi"> Shipment 1 / 1-1 item (1 Delivered) </span>
                    <a href={userOrderLine.OrderData?.Invoice_Download_Path}> <i class="fa fa-file-pdf-o favipdf" aria-hidden="true"></i> </a> 
                      </div>
                      <div className="invioce-shipping">
                        <h5> Shipping to </h5>
                        <h6 className="p-1"> <b> {userOrderLine.OrderData?.Name}  </b>  </h6>
                        <h6> Delivery parther <b>{userOrderLine.OrderData?.courierName}</b> <br/> Tracking ID  <b>{userOrderLine.OrderData?.trackingNumber}</b></h6>
                      </div>
                      <hr/>

                      {OrderList.map( (item)=>{           
                      return(
                        <>
                          <Row>     

                            <Col md={2} xs={2} key={item.bookid}>
                            <Link to={"/"+item.catSlug+"/"+item.slug+'?type='+item.product_type+'&id='+item.bookid}>
                              <img src={item.main_image} /> 
                              </Link> 
                            </Col>
                            <Col md={10} xs={10}>
                              <h6> 
                              <Link to={"/"+item.catSlug+"/"+item.slug+'?type='+item.product_type+'&id='+item.bookid}>
                                <b> {item.product_title} </b> </Link>
                              </h6>
                              <div> Description Binding : {item.product_type_title} </div>
                              <div> MRP : {item.mrp} </div>
                              <div> Discount : {item.total_discount} </div>
                              <div> Price : {item.totalPrice} </div>
                            </Col>
                            
                          </Row>
                          <hr/>
                      </>
                      );
                      } )}                       
                      
                  </div>
              </Col>
              {/* {userOrderLine.isDone &&  */}
              <Col md={4}>
                  <div className="order-address">
                    <p className="order-placeBg">
                     <div> Order ID <span className="order-white"> {userOrderLine.OrderData?.invoiceNo} </span>  ({userOrderLine.TotalItems} Item) </div> 
                     <div>  Order placed on {userOrderLine.OrderData?.orderDate} </div>
                     <div> Paid by  {userOrderLine.OrderData?.Payment_Method} </div>
                    </p>
                    <div className="payment-section"> 
                      <h3> Order Payment Details </h3>
                      <Row>
                        <Col md={8} xs={8}>
                         <div className="paytem-detail"> 
                            <div> Total </div>
                            <div> Discount </div>
                            <div className="totalBorder"> Shpping Fee </div>
                            {/* <div className="totalBorder"> Coupon Discount </div> */}
                            <div > <b> Sub Total  </b> </div>
                         </div>
                        </Col>
                        <Col md={4} xs={4}>
                          <div className="order-price">  
                          <div> <b> {userOrderLine.OrderData?.total_mrp} </b> </div>
                          <div> <b> - {userOrderLine.OrderData?.discount} </b> </div>
                          <div className="totalBorder">  + {userOrderLine.OrderData?.Shipping_and_Handling_Charges}  </div>
                          {/* <div className="totalBorder"> - 00  </div> */}
                          <div> <b> {userOrderLine.OrderData?.Cart_Sub_Total} </b> </div>
                          </div> 
                        </Col>
                        <Col> <div className="totalBorder"> </div> </Col>                      
                      </Row>
                      <Row>
                        <Col md={8} xs={6}>
                         <div className="paytem-detail"> 
                          <div> <b> Total Amount </b>   </div>
                          <br/>
                          </div>
                        </Col>
                        <Col md={4} xs={6}>
                        <div className="paytem-detail order-price"> 
                           <div> {userOrderLine.OrderData?.grand_total}  </div>
                        </div>
                      </Col>
                      <Col> <div className="totalBorder"> </div> </Col>
                     </Row>
                      <Row>
                        <Col md={8} xs={6}>
                         <div className="paytem-detail"> 
                          <div> <b> Payment Mode </b>   </div>
                          <br/>
                          </div>
                        </Col>
                        <Col md={4} xs={6}>
                        <div className="paytem-detail order-price"> 
                           <div> {userOrderLine.OrderData?.Payment_Method}  </div>
                        </div>
                      </Col>
                     </Row>
                     </div>
                  </div>
                  <div className="invioce-section mt-3">
                   <h5> Deliver to </h5>
                   <div> <b> {userOrderLine.OrderData?.Payment_Method} </b>  </div>
                    <p className="address-order">
                      {userOrderLine.OrderData?.Name}<br/>
                      {userOrderLine.OrderData?.Shipping_Address}<br/>                    
                      {userOrderLine.OrderData?.City}, {userOrderLine.OrderData?.State}<br/>
                      {userOrderLine.OrderData?.Country} -{userOrderLine.OrderData?.Zip_Postal_Code} <br/>
                      Phone : <b> {userOrderLine.OrderData?.Mobile_Phone}  </b>
                    </p>
                  </div>
              </Col>
              </>
              } 
            </Row>
          </Container>
        </div>
    <Footer/>
   </>
     
  );
};
export default ViewOrder;



import React,{useEffect,useContext, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import ProfileLinks from "./ProfileLinks";
import { Contaxt } from "../../../Store";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from "../../../Loader";

const Useraccount = () => {

  const {userData, changeUserData} = useContext(Contaxt)
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [accountData, setAccountData] = useState({
    name:"",
    mobileNo:"",
    email:"",
    
  });
  const [error, setError] = useState({type:"", msg:""})
  const [isLoading, setLoading] = useState(false)

  // useEffect(()=>{
  //   console.log(pathname)
  //   if(!userData.loginStatus){
  //     let url = btoa(pathname.substring(1)); 
  //     navigate('/login/'+url)
      
  //   }
   
  // })
  useEffect(() => {
    
    AOS.init({
      offset: 300,
      duration: 1000,
    });
    if(userData.loginStatus){
      setAccountData({
        name:userData.name,
        mobileNo:userData.mobile,
        email:userData.email
      })
    }
  }, []);

  function updateAccount(e){
    e.preventDefault();
    if(accountData.name=="") {
       setError({type:"name", msg:"Please fill the name!"})
       return;
    } else if(accountData.mobileNo=="") {
      setError({type:"mobileNo", msg:"Please fill the mobile number!"})
      return;
    } else if(accountData.email=="") {
      setError({type:"email", msg:"Please fill the email!"})
      return;
    }

    setError({type:"", msg:""})
    setLoading(true);
    fetch(apiBaseURL+apiURL.updateProfile,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
        'utoken': userData.token
      },
      body:JSON.stringify({name:accountData.name, Mobile:accountData.mobileNo, email:accountData.email})
    }).then((response)=>response.json())
      .then( (result)=>{

        console.log(result)
        setLoading(false)
        if( result.status==='1' ){
          setError({type:"success", msg:"Successfully Updated!"})
          changeUserData({
            name:accountData.name,
            mobile:accountData.mobileNo,
            email:accountData.email
          })
        }else{
          setError({type:"failed", msg:result.message})
          
        }
      })
      .catch( (error)=>{
        console.log(error);
        setLoading(false)
        setError({type:"failed", msg:"Network Error! try again"})
      })
    
    
  }
  function changeData(value, type){
    setAccountData((prev)=>{
      return {...prev, [type]:value}
    })
  }

  if(!userData.loginStatus){
   
    return null; 
    
  }
  return(
    <div className="App">
      <Header/>
      <Navigation/>
      <ProfileLinks/>
      <Row>
      <Col>
      <div className="form-wrap" style={{position:"relative"}}>
        <h2 className="login-main">Your Account</h2>
        {(error.type=="success" || error.type=="failed") &&
          <div style={{textAlign:'center', color:error.type=="success"?'green':'red'}}>{error.msg}</div>
        }
        <form id="warp-login" onSubmit={updateAccount}>
          <div className="form-group">
            <label className="login-hedi">Name </label>
            <input type="text" className="form-control login-mar" placeholder="name" value={accountData.name} onChange={(e)=>{changeData(e.target.value, "name")}} />
            { error.type=="name" && <span className="errMgs">{error.msg}</span> }
          </div>
          <div className="form-group">
            <label className="login-hedi">Mobile No</label>
            <input type="text" className="form-control login-mar" placeholder="mobile" value={accountData.mobileNo} onChange={(e)=>{changeData(e.target.value, "mobileNo")}} />
            { error.type=="mobileNo" && <span className="errMgs">{error.msg}</span> }
          </div>
          <div className="form-group">
            <label className="login-hedi">Email</label>
            <input type="email" className="form-control login-mar" placeholder="passward" value={accountData.email} onChange={(e)=>{changeData(e.target.value, "email")}} />
            { error.type=="email" && <span className="errMgs">{error.msg}</span> }
          </div>
          <div className="col-md-12">
            <center>
              <button className="forget_btn" type="submit"> Update </button>
            </center>
          </div>
        </form>
        {isLoading && 
          <Loader width="80px" height="80px"/>
        }
      </div>
    </Col>
      </Row>
      <Footer/>
    </div>
  );
};
export default Useraccount;



import React,{useState,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import policy from '../images/refund.webp';
import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";


const Policy = () => {
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  })
  useEffect(() => {
    getSeoData();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.cancellationPolicySeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"cancellationrefundpolicy"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }
  return(
    <div className="App">
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      <Header/>
      <Navigation/>
        <div className="disclaimer">
          <Container fluid className="back_width">
           <div className="discl_bg">
           
            <Row>
              <Col md={12}>
                  <h1> Cancellation & Refund Policy </h1>
              </Col>
              <Col md={1} sm={2}> 
                  <img src={policy} />
              </Col>
              <Col> 
                <p> 
                  As of now we do not provide any option for cancellation of products and/or services you have purchased
                  or subscribed. Once a product/service has been purchased by you, we cannot provide any refund, either 
                  totally or partially. We suggest that first you go through the demos and/or free to use 
                  contents/products/services before you subscribe to or purchase from rachnasagar.in. 
                 </p>
              </Col>
            </Row>
            <br/><br/><br/>
            </div>
          </Container>
        </div>
      <Footer/>
    </div>
  );
};
export default Policy;



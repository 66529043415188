import React  from "react";


const Loader = ({width='150px', height='150px', backgroundColor="rgb(35 35 35 / 20%)"}) => { 
 return(
      <div className="loaderbg" style={{backgroundColor:backgroundColor}} >
        <div className="spinner-loading" style={{width:width, height:height}}> </div>
      </div>
  );
};
export default Loader;

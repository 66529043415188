import React , { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

// import bookgirl from "../images/study_girl.jpg";
// import bookboy from "../images/study_boy.jpg";

import { apiURL, apiBaseURL } from '../../../constant';
import { Link, useSearchParams} from "react-router-dom";
import Loader from '../../../Loader' 
import AOS from "aos";

const Books = () => {

  const [searchBoard,setSearchBoard] = useState({isLoading:true, list:[]});
  const [queryString] = useSearchParams();

  useEffect( () =>{
    getSearchByBoard(); 
    AOS.init({
      offset: 300,
      duration: 1000,
    });
    
  },[] );

  


  var bookTypeStr = queryString.get('bookType');
  //console.log(ProductTypeStr);

  let byDefaultbookType = "p_book";
  function isEmpty(bookTypeStr){
      return (bookTypeStr === undefined || bookTypeStr == null || bookTypeStr.length <= 0) ? byDefaultbookType : bookTypeStr;
  }
  let bookType = isEmpty(bookTypeStr);

  console.log(bookType);

  //-------------Shop By Board -----------------
  
  function getSearchByBoard() {
    // console.log(location.state)
    fetch(apiBaseURL+apiURL.category,{
      method:'POST',
      headers:{
        'rskey' : 'rspl'
      },
      body:JSON.stringify({parameter:queryString.get('bookType')})
    } )
      .then( response => response.json() )
      .then( (result) => {
        // console.log( result);
        if( result.status==='1' ){
          setSearchBoard( {isLoading:false, list:result.category_data} );  
          //alert(result.category_data)

        }else{
          setSearchBoard({isLoading:false, list:[]});
        }
        //setLoading(false);
      } )
      .catch( (error)=>{
        setSearchBoard({isLoading:true, list:[]});
        //setLoading(false);
      } );

  }

  return(
    <div className="App">
      <Header/>
      <Navigation/>
        <div className="books-bg" data-aos="fade-right">
          <h1> BOOKS AND EDUCATIONAL KITS </h1>
        </div>
        <Container> 
          {/* <Row>
           <Col md={3} data-aos="fade-right">
            <img className="abo-img" src={bookgirl} />
            </Col> */}
            <Col md={12}>
              <Row className="m-2">
              {searchBoard.isLoading ?
                <div style={{position:'relative', height:'100px'}}>
                  <Loader width="80px" height="80px" backgroundColor="transparent"/>
                </div>:
                <>
                {searchBoard.list.map( (item)=>{ 
                  let path = "";  
                  if(bookType=="p_book"){
                    //path = "/books/"+item.categoryId;
                    path = "/books/"+item.slug;
                  }else{
                    //path = "/books/"+item.categoryId+"/"+bookType;
                    //path = "/books/"+item.slug+"/"+bookType+"?pt="+bookType;
                    path = "/books/"+item.slug+"?pt="+bookType;
                  }
                  return(        
                      
                  <Col md={3} sm={6} xs={12} key={item.categoryId}>
                    <div className="book-img-btn">
                      <Link to={`${path}`} ><img src={item.image} /></Link>
                      <div className="book_cbse">
                      <Link to={`${path}`} > <button type="button">  {item.category_title}</button> </Link> 
                      </div>
                    </div> 
                  </Col>

                  );
                  } )}
                </>
              }
              {/* <Col md={6} xs={6}>
            <div className="book-img-btn">
            <img src={ncert} />
            <div className="book_cbse">
                <button type="button">NCERT</button>
            </div>
            </div>
            </Col> */}
          </Row>    
          </Col>   
        {/*<Col md={3} data-aos="fade-left">
            <img className="abo-img" src={bookboy} />
            </Col> 
          </Row> */}
        </Container>
        <br/>
      <Footer/>
    </div>
  );
};
export default Books;



import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import Collapse from 'react-bootstrap/Collapse';

import Helmet from "react-helmet";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import {Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import Loader from '../../../Loader';
import { Contaxt } from "../../../Store";

const Catalogue = () => {
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);

  const {userData, setCartItemNum} = useContext(Contaxt)
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [seoData, setSeoData]  = useState({
    title:"",
    description:"",
    keywords:"",
    ogTags:{},
    twitterTags:{},
    isDone:false,
  });
  const [queryString, setQueryString] = useSearchParams();
  const [sidebarData, setSideBarData] = useState({
    board:[],
    class:[],
    bookType:[],
    subject:[]
  })
  const [catalogueData, setCatalogueData] = useState({
    isLoading:true,
    list:[],
    downPDF:[],
    pagination:[]
  });

  useEffect(()=>{
    // if(!userData.loginStatus){
    //   setCatalogueData({
    //     isLoading:true,
    //     list:[],
    //     downPDF:[]
    //   });
    //   getCatalogueProduct();

    // } 
    constructCatalogueProduct();
  }, [userData.loginStatus])

  useEffect(() => { 
    //console.log("------pathname------")
    console.log(pathname)
    //console.log("------pathname------")
    getSeoData();
    // if(userData.loginStatus){
    //   getCatalogueProduct();

    // }
   
    AOS.init({
    offset: 300,
    duration:1000,
    });
    getCagaloguePdf();
  }, []);


  useEffect(()=>{
    constructCatalogueProduct();
    setTimeout(()=>{
      window.scrollTo(0, window.scrollY)
    }, 0)
  }, [queryString])

  function constructCatalogueProduct(){
    let catId = queryString.get("categoryId");
    console.log(catId);
    if(sidebarData.board.length==0){
      getBoardData();

    }
    if(catId==null || catId==""){
      setSideBarData((prev)=>{
        return {
          ...prev,
          class:[],
          bookType:[],
          subject:[]
        }
      })
      getCatalogueProduct();
    } else {
      let queryStr = `?categoryId=${catId}`;
      let classId = queryString.get("classId");
      getClassData(catId);
      if(classId==null || classId==""){
        setSideBarData((prev)=>{
          return {
            ...prev,
            bookType:[],
            subject:[]
          }
        })
      } else {
        queryStr += `&classId=${classId}`;
        let bookTypeId = queryString.get("bookTypeId");
        getBookTypeData(catId, classId)
        if(bookTypeId==null || bookTypeId==""){
          
          setSideBarData((prev)=>{
            return {
              ...prev,
              subject:[]
            }
          })
        } else {
          queryStr += `&bookTypeId=${bookTypeId}`;
          let subjectId = queryString.get("subjectId");
          getSubjectData(catId, classId, bookTypeId);
          if(subjectId!=null && subjectId!=""){
            queryStr += `&subjectId=${subjectId}`;
          }
        }
      }
      
      getCatalogueProduct(queryStr)

    }
  }
  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.catalogueSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
              
          },
          body:JSON.stringify({"seo":"catalogue"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }

  async function getCagaloguePdf(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.catalogue,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"Action":"catalogue"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setCatalogueData((prev)=>{
        
          // const list = result.Book_List; //.splice(0, 100)
          const pdfData = result.Catalogue_Download_List;
          return {...prev, downPDF:pdfData, isLoading:false}
        })
      } else {
        console.log(result.message)
      }

    } catch (error) {
      console.log(error)
    }
  }
  async function getCatalogueProduct(query=""){
    setCatalogueData((prev)=>{
      return {...prev, isLoading:true}
    });
    try {
      let response = await  fetch(apiBaseURL+apiURL.catalogueProduct+query,{
          method:'GET',
          headers:{
              'rskey':apiKey,
          },
          
        });
      let result = await response.json();
      console.log(result)
      if(result.status=="1"){
        setCatalogueData((prev)=>{
         
          const list = result.products; 
          const pagination = result.pagination;
          return {...prev, list:list, pagination:pagination, isLoading:false}
        })
      } else {
        
        setCatalogueData((prev)=>{
          return {...prev, isLoading:false, list:[], pagination:[]}
        });
      }

    } catch (error) {
      console.log(error);
      console.log("this is error!");
      setCatalogueData((prev)=>{
        return {...prev, isLoading:false}
      });
    }
  }

 
  async function getBoardData(){
      try {
        let res = await fetch(apiBaseURL+apiURL.catalogueCategory,{
          method:'POST',
          headers:{
            'rskey':apiKey,
          },
          body:JSON.stringify({"action":"category"})
        });
        let result = await res.json();
        if(result.status=="1"){
          setSideBarData((prev)=>{
            return {
              ...prev, 
              board:result.Category_List,
            }
          })
        } else {
          console.log(result.message)
        }
        
      } catch (error) {
        console.log("network Error!")
      }
  } 
  async function getClassData(catId){
      try {
        let res = await fetch(apiBaseURL+apiURL.catalogueClass,{
          method:'POST',
          headers:{
            'rskey':apiKey,
          },
          body:JSON.stringify({"action":"class", "categoryId": catId})
        });
        let result = await res.json();
        if(result.status=="1"){
          setSideBarData((prev)=>{
            return {
              ...prev, 
              class:result.Class_List,
            }
          })
        } else {
          console.log(result.message);
        }
        
      } catch (error) {
        console.log('Network Error!')
      }
  }

  async function getBookTypeData(catId, classId){
    try {
      let res = await fetch(apiBaseURL+apiURL.catalogueBookType,{
        method:'POST',
        headers:{
          'rskey':apiKey,
        },
        body:JSON.stringify({"action":"booktype", "categoryId": catId, "classId":classId})
      });
      let result = await res.json();
      if(result.status=="1"){
        setSideBarData((prev)=>{
          return {
            ...prev, 
            bookType:result.Booktype_List,
          }
        })
      } else {
        console.log(result.message);
      }
      
    } catch (error) {
      console.log('Network Error!')
    }
  }
  async function getSubjectData(catId, classId, bookTypeId){
    try {
      let res = await fetch(apiBaseURL+apiURL.catalogueSubject,{
        method:'POST',
        headers:{
          'rskey':apiKey,
        },
        body:JSON.stringify({"action":"subject", "categoryId": catId, "classId":classId, "booktypeId":bookTypeId})
      });
      let result = await res.json();
      if(result.status=="1"){
        setSideBarData((prev)=>{
          return {
            ...prev, 
            subject:result.Subject_List,
          }
        })
      } else {
        console.log(result.message);
      }
      
    } catch (error) {
      console.log('Network Error!')
    }
  }
  async function goToPage(url){
    setCatalogueData((prev)=>{
      return {...prev, isLoading:true}
    });
    try {
      let response = await  fetch(url,{
          method:'GET',
          headers:{
              'rskey':apiKey,
          },
        });
      let result = await response.json();
      if(result.status=="1"){ 
        setCatalogueData((prev)=>{
         
          const list = result.products; 
          const pagination = result.pagination;
          const pdfData = [];
          return {...prev, list:list, downPDF:pdfData, pagination:pagination, isLoading:false}
        })
      } else {
        console.log(result.message)
      }

    } catch (error) {
      console.log(error)
    }
  }
  async function addProduct(value, id, item){


    if(!userData.loginStatus){
      let wholeQueryParam = "";
      console.log(queryString.entries());
      let index =0; 
      for(let entry of queryString.entries()){
        console.log(entry)
        if(index==0){
          wholeQueryParam += `?${entry[0]}=${entry[1]}`
        } else {
          wholeQueryParam += `&${entry[0]}=${entry[1]}`
        }
        index++;
      }
      // console.log(pathname.substring(1)+wholeQueryParam)
      let url = btoa(pathname.substring(1)+wholeQueryParam); 
      // console.log(url);
      // return 
      navigate('/account/login/'+encodeURIComponent(url))
      return;
    } 
   
    let quantity = document.getElementById(id+'inp').value;
    
    const sendData = {
      "bookId" : item.productId?.toString(),  
      "product_type" : "p_book",
      "quantity" : quantity,
      "mode":value?'add':'remove'
    }
    try {
      let response = await  fetch(apiBaseURL+apiURL.addPrduct,{
          method:'POST',
          headers:{
              'rskey':apiKey,
              'utoken':userData.token
          },
          body:JSON.stringify(sendData)
        });
      let result = await response.json();
      console.log(result);
      if(result.status=="1"){

        setCartItemNum(result.Total_Items)
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }

  function toggleBoard(id, checked, name){
    let catId = queryString.get("categoryId");
    let classId = queryString.get("classId");
    let bookTypeId = queryString.get("bookTypeId");
    let subjectId = queryString.get("subjectId");
    let catQuery = "";
    if(catId==null || catId==""){
      if(checked)catQuery = id;
    } else {
      let catIdArray = catId.split(",");
      if(checked){
        catIdArray.push(id);
      } else {
        catIdArray = catIdArray.filter(item=>item!=id);


      }
      catQuery = catIdArray.join(",");
    }
    let queryStr = "";
    if(catQuery!=""){
      queryStr += `categoryId=${catQuery}`;
      if(classId!=null && classId!==""){
        queryStr += `&classId=${classId}`;
        if(bookTypeId!=null && bookTypeId!==""){
          queryStr += `&bookTypeId=${bookTypeId}`;
          if(subjectId!=null && subjectId!==""){
            queryStr += `&subjectId=${subjectId}`;
          }
        }
      }
    }
    
    let catNamesStr = pathname.split('/')[2];
    let catStr = "";
   
    if(catNamesStr!=undefined && catNamesStr!=""){
      let catArray = catNamesStr.split("-");
      console.log(catArray);
      let isExist = false;
      for(let i=0; i<catArray.length; i++){
        let catName = catArray[i];
        if(name==catName && !checked){
          console.log('exists');
          isExist = true;
          catArray.splice(i, 1);
          
        } 
      }
      if(!isExist && checked){
        catArray.push(name);
      }
      catStr = catArray.join('-');
    } else if(checked){
      catStr = name;
    }

    console.log("--------------")
    if(!checked){
     console.log(pathname.includes(encodeURIComponent(name))); 
    }
    //console.log("-------------"+encodeURIComponent(catStr))

    navigate(`/catalogue/${encodeURIComponent(catStr)}?${queryStr}`)
    // setQueryString(queryStr)
  }
  function toggleClass(id, checked){
    let catId = queryString.get("categoryId");
    let classId = queryString.get("classId");
    let bookTypeId = queryString.get("bookTypeId");
    let subjectId = queryString.get("subjectId");
    let clsQuery = "";
    if(classId==null || classId==""){
      if(checked)clsQuery = id;
    } else {
      let clsIdArray = classId.split(",");
      if(checked){
        clsIdArray.push(id);
      } else {
        clsIdArray = clsIdArray.filter(item=>item!=id);


      }
      clsQuery = clsIdArray.join(",");
    }
    let queryStr = "";
    if(catId!=null && catId!=""){
      queryStr += `categoryId=${catId}`;
      if(clsQuery!==""){
        queryStr += `&classId=${clsQuery}`;
        if(bookTypeId!=null && bookTypeId!==""){
          queryStr += `&bookTypeId=${bookTypeId}`;
          if(subjectId!=null && subjectId!==""){
            queryStr += `&subjectId=${subjectId}`;
          }
        }
      }
    }
    console.log(pathname)
    // setQueryString(queryStr)
    navigate(`${pathname}?${queryStr}`)
  }

  function toggleBookType(id, checked){
    let catId = queryString.get("categoryId");
    let classId = queryString.get("classId");
    let bookTypeId = queryString.get("bookTypeId");
    let subjectId = queryString.get("subjectId");
    let bookTypeQuery = "";
    if(bookTypeId==null || bookTypeId==""){
      if(checked)bookTypeQuery = id;
    } else {
      let bookTypeArray = bookTypeId.split(",");
      if(checked){
        bookTypeArray.push(id);
      } else {
        bookTypeArray = bookTypeArray.filter(item=>item!=id);


      }
      bookTypeQuery = bookTypeArray.join(",");
    }
    let queryStr = "";
    if(catId!=null && catId!=""){
      queryStr += `categoryId=${catId}`;
      if(classId!=null && classId!=""){
        queryStr += `&classId=${classId}`;
        if(bookTypeQuery!==""){
          queryStr += `&bookTypeId=${bookTypeQuery}`;
          if(subjectId!=null && subjectId!==""){
            queryStr += `&subjectId=${subjectId}`;
          }
        }
      }
    }
    // setQueryString(queryStr)
    navigate(`${pathname}?${queryStr}`)
  }
  function toggleSubject(id, checked){
    let catId = queryString.get("categoryId");
    let classId = queryString.get("classId");
    let bookTypeId = queryString.get("bookTypeId");
    let subjectId = queryString.get("subjectId");
    let subjectQuery = "";
    if(subjectId==null || subjectId==""){
      if(checked)subjectQuery = id;
    } else {
      let subjectArray = subjectId.split(",");
      if(checked){
        subjectArray.push(id);
      } else {
        subjectArray = subjectArray.filter(item=>item!=id);

      }
      subjectQuery = subjectArray.join(",");
    }
    let queryStr = "";
    if(catId!=null && catId!=""){
      queryStr += `categoryId=${catId}`;
      if(classId!=null && classId!=""){
        queryStr += `&classId=${classId}`;
        if(bookTypeId!==null && bookTypeId!=""){
          queryStr += `&bookTypeId=${bookTypeId}`;
          if(subjectQuery){
            queryStr += `&subjectId=${subjectQuery}`;
          }
        }
      }
    }
    // setQueryString(queryStr)
    navigate(`${pathname}?${queryStr}`)
  }
  const catalogueJSX =  useMemo(()=>{

    if(catalogueData.list.length==0){
      return <div style={{textAlign:"center"}}>No data found!</div>
    }
    return(
    <table className="table table-bordered">
      <thead className='onlyfontswith'>
        <tr>
          <th scope="col">Board</th>
          <th scope="col">Book Name</th>
          <th scope="col">Class</th>
          <th scope="col">MRP</th>
          <th scope="col">Price</th>
          <th scope="col">Quantity</th>
          <th scope="col">Add to cart</th>
        </tr>
      </thead>
      <tbody>
        {catalogueData.list.map((item, index)=>{
          return(
            <tr key={item.productId}>
              <td>{item.category_title}</td>
              <td style={{maxWidth:'500px', wordBreak:'break-all'}}>
                <Link to={`/${item.productUrl}`} style={{color:"#000000"}}>
                  {item.product_title}
                  <div className='font-small'>ISBN: {item.isbn}</div>
                </Link>
              </td>
              <td>{item.class_title}</td>
              <td className="colour_red text-center">
                  <i className="fa fa-rupee"></i> 
                  {item.book_perDiscount !="0" ?<span className="rs_line_throu">{item.book_mrp}</span> : <span>{item.book_mrp}</span>}
              </td>
              <td className='text-center'>
                <i className="fa fa-rupee"></i> 
                  <span> {item.book_price} </span> 
              </td>
              <td className='text-center'>
              <input type="number" className='cal-quantity' defaultValue="1" min="1" id={"sta_"+item.productId+index+'inp'} />
              </td>
              <td className="check_mark">
                <label className="checkbox-wrap">
                  <input type="checkbox" onChange={(e)=>{addProduct(e.target.checked, "sta_"+item.productId+index, item)}}/>
                  <div className="checkmark"></div>
                </label>
                {true&&
                  <div id={"sta_"+item.BookId+index} style={{display:"none"}} className="catlogMsg">added</div>
                }
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
    )
  }, [catalogueData.list]);
  const categoryArray = queryString.get("categoryId")?queryString.get("categoryId").split(","):[];
  const classArray = queryString.get("classId")?queryString.get("classId").split(","):[];
  const bookTypeArray = queryString.get("bookTypeId")?queryString.get("bookTypeId").split(","):[];
  const subjectArray = queryString.get("subjectId")?queryString.get("subjectId").split(","):[];
  return(
  <>
   {seoData.isDone &&
      <Helmet>
          <title>{seoData.title} </title>
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />
          <meta property="og:title" content={seoData.ogTags?.og_title} />
          <meta property="og:description" content={seoData.ogTags?.og_description} />
          <meta property="og:url" content={seoData.ogTags?.og_url} />
          <meta property="og:image" content={seoData.ogTags?.og_image_url} />
          <meta name="twitter:site" content="@rachnasagargrp"/> 
          <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
          <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
          <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
      </Helmet>
    }
  <Header/>
  <Navigation/>
    <div className='branch-bg'>
      <Container>
          <h1 className="invi-authors"> OUR CATALOGUE </h1>
            <div className='box-invi'>
              <Row>
                <Col md={12} data-aos="zoom-in">
                    <p>
                      <Link to="/"> Educational Publishers </Link> As pioneer in , <Link to="/book"> Rachna Sagar </Link> 
                        creates and publishes world’s best school books which are based on National and International standard study materials for 
                        <a href="http://www.cisce.org/" target="_blank"> CBSE </a>, 
                        <a href="http://www.cisce.org/" target="_blank"> ICSE </a>, 
                        <Link to="/contactus"> STATES (Meghalaya &amp; Tamil Nadu) and
                        INTERNATIONAL (Nigeria, Ghana, Uganda &amp; Rwanda) BOARDS </Link> that capture the spirits of students, to reflect the ethos
                        of our changing times. For more details you can download our catalogue.
                    </p>
                </Col>
              </Row>
            </div>
      </Container>
    </div>
  <Container>
    <div className='catalogue-box'>
        {catalogueData.downPDF.map((item)=>{
          return(
            <div className='catalogue_Box1'>
              <div className='cat-small'> {item.title} </div>
              <a href={item.pdfUrl} target="_blank"> <img src={item.image} alt={item.image_alt_title}/></a>
              <a href={item.pdfUrl} target="_blank"> <div className='cat-small1'> Free Download </div></a>
            </div>
          )
        })}
    </div>
    </Container>

    <Container fluid className='back_width'>
      {catalogueData.isLoading?
        <div style={{position:'relative', height:'100px'}}>
          <Loader width="80px" height="80px" backgroundColor="transparent"/>
        </div>:
      <Row> 
        <Col md={2} sm={4}>
            <Row>
              <div className="filter-bg">
                <Col md={12} sm={12}>
                {/* <div className="priceRange"> Price range </div>
                <input type="range" min="1" max="100" value="80" /> */}
                  <div className='' onClick={() => setOpen1(!open1)} aria-controls="example-collapse-text"aria-expanded={open1}>
                  <div className="search-bar-hedi"> SEARCH BY BOARD <i className="fa fa-chevron-down down-font" aria-hidden="true"></i> </div>  </div>
                  <Collapse in={open1}>
                    <div>
                      {sidebarData.board.map((item)=>{
                        return(
                          <div className="left-side-bar" key={item.categoryId}> 
                            <input 
                              type="checkbox" 
                              id={item.categoryId} name="" 
                              style={{cursor:"pointer"}} 
                              onChange={(e)=>{toggleBoard(item.categoryId, e.target.checked, item.slug)}} 
                              checked={categoryArray.includes(item.categoryId.toString())}
                            />
                            <label className="filtername" for={item.categoryId} style={{cursor:"pointer"}}> {item.category_title}</label>
                          </div>
                        )
                      })}
                    </div>
                  </Collapse>
                  
                </Col>
                {sidebarData.class.length>0&&
                  <Col md={12} sm={12}>
                    <div className='' onClick={() => setOpen2(!open2)} aria-controls="example-collapse-text"aria-expanded={open2}>
                    <div className="search-bar-hedi"> SELECT CLASS <i className="fa fa-chevron-down down-font" aria-hidden="true"></i> </div>  </div>
                    <Collapse in={open2}>
                      <div>
                        {sidebarData.class.map((item)=>{
                          return(
                            <div className="left-side-bar" key={item.classId}>
                              <input 
                                style={{cursor:"pointer"}}
                                type="checkbox" 
                                id={item.classId} 
                                onChange={(e)=>{toggleClass(item.classId, e.target.checked)}} 
                                checked={classArray.includes(item.classId.toString())} 
                              />
                              <label className="filtername" for={item.classId} style={{cursor:"pointer"}}> {item.class_title}</label>
                            </div>
                          )
                        })}
                      </div>
                    </Collapse>
                  </Col>
                }

                {sidebarData.bookType.length>0&&
                  <Col md={12} sm={12}>
                    <div className='' onClick={() => setOpen3(!open3)} aria-controls="example-collapse-text"aria-expanded={open3}>
                    <div className="search-bar-hedi"> SELECT BOOK TYPE <i className="fa fa-chevron-down down-font" aria-hidden="true"></i> </div>  </div>
                    <Collapse in={open3}>
                      <div>
                        {sidebarData.bookType.map((item)=>{
                            return(
                              <div className="left-side-bar" key={item.bookTypeId}>
                                <input 
                                  style={{cursor:"pointer"}}
                                  type="checkbox" 
                                  id={item.bookTypeId} 
                                  onChange={(e)=>{toggleBookType(item.bookTypeId, e.target.checked)}} 
                                  checked={bookTypeArray.includes(item.bookTypeId.toString())} 
                                />
                                <label className="filtername" for={item.bookTypeId} style={{cursor:"pointer"}}> {item.booktype_title}</label>
                              </div>
                            )
                          })}
                      </div>
                    </Collapse>
                  </Col>
                }

                {sidebarData.subject.length>0&&
                  <Col md={12} sm={12}>
                    <div className='' onClick={() => setOpen4(!open4)} aria-controls="example-collapse-text"aria-expanded={open4}>
                    <div className="search-bar-hedi"> SELECT SUBJECT <i className="fa fa-chevron-down down-font" aria-hidden="true"></i> </div>  </div>
                      <Collapse in={open4}>
                        <div>
                          {sidebarData.subject.map((item)=>{
                            return(
                              <div className="left-side-bar" key={item.subjectId}>
                                <input 
                                  style={{cursor:"pointer"}}
                                  type="checkbox" 
                                  id={item.subjectId} 
                                  onChange={(e)=>{toggleSubject(item.subjectId, e.target.checked)}} 
                                  checked={subjectArray.includes(item.subjectId.toString())} 
                                />
                                <label className="filtername" for={item.subjectId} style={{cursor:"pointer"}}> {item.subject_title}</label>
                              </div>
                            )
                          })}
                        
                        </div>
                      </Collapse>
                  </Col>               
                }
              </div>
            </Row>
          </Col>
        <Col className='mt-3' md={10} sm={8} > 
          <div className='table-responsive'>
            {catalogueJSX}
          </div>
          {console.log(catalogueData.pagination.links)}
          {catalogueData.pagination?.links && catalogueData.pagination.links.length>1 &&
            <nav className="pagina-outer" aria-label="Page navigation">
              <ul className="pagina">
                <li className="page-item" onClick={()=>{if(catalogueData.pagination.prevPage)goToPage(catalogueData.pagination.prevPage)}}>
                    <a className="page-link" aria-label="Previous">
                        <span aria-hidden="true">«</span>
                    </a>
                </li>
                {catalogueData.pagination.links.map((item)=>{
                  let activeCls = "";
                  if(item.active){
                    activeCls = 'active'
                  }
                  return (
                    <li key={item.url} className={`page-item ${activeCls}`} onClick={()=>{if(activeCls=="" && item.label!='...')goToPage(item.url)}}>
                      <a className="page-link">{item.label}</a>
                    </li>
                  )
                })}
              
                <li className="page-item" onClick={()=>{if(catalogueData.pagination.nextPage)goToPage(catalogueData.pagination.nextPage)}}>
                  <a className="page-link" aria-label="Next">
                    <span aria-hidden="true">»</span>
                  </a>
              </li> 
              </ul>
            </nav>
          }
        </Col>
      </Row>
      }
    </Container>
  <Footer/>
  </>
  );
};
export default Catalogue;
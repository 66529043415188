import React from "react";

function CartPopup({closePopup, title, children}){
    return (
      <div className="student_popup" onClick={closePopup}>
          <div className="cart_popup" onClick={(e)=>{e.preventDefault(); e.stopPropagation()}}>
          <div className="cart-header modal-header">
          <div className="modal-title">{title}</div>
            <button onClick={closePopup} className="close"> &#x2715; </button>
          </div>
            <div className="modal-body">
                {children}
            </div>
          </div>
      </div>
    )
}

export default CartPopup;
import React, { Children } from "react";

function Popup({closePopup, title, children}){
  
    return (
         <div className="student_popup" onClick={closePopup}>
            <div className="innperPopup" onClick={(e)=>{ e.stopPropagation()}}>
              <div className="innperPopup-content cos-model">
              <div className="modal-header">
              <div className="modal-title">{title}</div>
                <button onClick={closePopup} className="close"> &#x2715; </button>
              </div>
                <div className="modal-body body-box">
                  <div className="row">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}

export default Popup;